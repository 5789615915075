import React from 'react'

export default function Tab({ children, tabContent }) {
    return (
     <>
            <div className="nav nav-tabs mb-2" id="nav-tab" role="tablist">
                {children}
            </div>
            <div className="tab-content" id="nav-tabContent">
                {tabContent}
            </div>
            </>
    )
}
