import React from 'react';
import MaterialList from 'devextreme-react/list';
import styled from 'styled-components';
const ItemTemplate = (data) => {
    return <div>{data.Name}</div>;
};
export default function List({ dataSource, onSelectionChanged, height }) {
    return (
        <React.Fragment>
            <ListWrapper>
                <MaterialList
                    dataSource={dataSource}
                    height={height}
                    itemRender={ItemTemplate}
                    searchExpr="Name"
                    searchEnabled={true}
                    onItemClick={onSelectionChanged}
                />
            </ListWrapper>
        </React.Fragment>
    );
};
const ListWrapper = styled.div`
  margin: 10px 10px 10px 0;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px;
`;

