import React from 'react'
import styled from 'styled-components';
import TextInput from '../../controls/textinput';
import Button from '../../controls/button';
import Dropdown from '../../controls/drop-down';
export default function PersonAddressDetail({isDisabled}) {
    return (
        <div>
            <DivWrapper >
                <h6>Address Details</h6>
                <hr></hr>
                <div className="row g-3">
                    
                    <div className='col-lg-12'>
                        <TextInput label="House ID" id="houseID" variant="standard" disabled={isDisabled}></TextInput>
                    </div>
                    <div className='col-lg-6 col-md-6 col-6'>
                        <TextInput label="Street 1" id="street1" variant="standard" disabled={isDisabled}></TextInput>
                    </div>
                    <div className='col-lg-6 col-md-6 col-6'>
                        <TextInput label="Street 2" id="street2" variant="standard" disabled={isDisabled}></TextInput>
                    </div>
                    <div className='col-lg-12'>
                        <TextInput label="District" id="district" variant="standard" disabled={isDisabled}></TextInput>
                    </div>
                    <div className='col-lg-6 col-md-6 col-6'>
                        <TextInput label="Town" id="town" variant="standard" disabled={isDisabled}></TextInput>
                    </div>
                    <div className='col-lg-6 col-md-6 col-6'>
                        <Dropdown label="" disabled={isDisabled}></Dropdown>
                    </div>
                    <div className='col-lg-6 col-md-6 col-6'>
                        <TextInput label="Post Code" id="postCode" variant="standard" disabled={isDisabled}></TextInput>
                    </div>
                    <div className='col-lg-6 col-md-6 col-6'>
                        <Button size='medium' variant='contained' name='Lookup Address' fullWidth={true}  disabled={isDisabled}></Button>
                    </div>
                </div>
            </DivWrapper>
        </div>
    )
}
const DivWrapper = styled.div`
  margin: 10px ;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px 20px 10px 20px;
    `;
