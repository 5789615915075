import { useState, useEffect } from 'react';
import { getParameter } from '../services/setting-service';
import { getUserList } from '../services/user-service';
import DropDownFilter from '../components/controls/dropdownfilter';
import { BILLING_CATEGORY_1,BILLING_TYPE_OF_WORK, BILLING_CATEGORY_2 } from '../utils/constants/parmeterCode'
export function useHistoryParameter(caseType) {
  const [billingCategory1Option, setBillingCategory1Option] = useState([]);
  const [billingCategory2Option, setBillingCategory2Option] = useState([]);
  const [billingTypeOfWorkOption, setBillingTypeOfWorkOption] = useState([]);
  const [userListOption, setUserListOption] = useState([]);
  useEffect(() => {
    const fetchBillingCategory1List = async () => {
      try {
        const billingCategory1 = await CallGetParameterApi(BILLING_CATEGORY_1);
        const filteredBillingCategory1 = filterData(billingCategory1.data.results);
        setBillingCategory1Option(filteredBillingCategory1);
      } catch (error) {
        // Handle errors
      }
    };
    const fetchBillingCategory2List = async () => {
        try {
          const billingCategory2 = await CallGetParameterApi(BILLING_CATEGORY_2);
          setBillingCategory2Option(billingCategory2.data.results);
        } catch (error) {
          // Handle errors
        }
      };

    const fetchTypeOfWorkList = async () => {
      try {
        const typeOfWork = await CallGetParameterApi(BILLING_TYPE_OF_WORK);
        const filteredTypeOfWorkOption = filterData(typeOfWork.data.results);
        setBillingTypeOfWorkOption(filteredTypeOfWorkOption);
      } catch (error) {
        // Handle errors
      }
    };

    const fetchUserList = async () => {
      try {
        const userList = await getUserList(true);
        setUserListOption(userList.data.results);
      } catch (error) {
        // Handle errors
      }
    };

    fetchBillingCategory1List();
    fetchBillingCategory2List();
    fetchTypeOfWorkList();
    fetchUserList();
   
  }, [caseType]);

  const CallGetParameterApi = async (parameterCode) => {
    try {
      const parameterList = await getParameter(parameterCode);
      return parameterList;
    } catch (error) {
      // Handle errors
    }
  };

  const filterData = (standardTask) => {
    return DropDownFilter(standardTask, caseType);
  };

  return {
    billingCategory1Option,
    billingCategory2Option,
    billingTypeOfWorkOption,
    userListOption,
  };
}

