import React, { useState, createContext } from 'react'
export const CaseDetailContext = createContext();
export default function CaseDetailContextProvider({ children }) {
    const [toggled, setToggled] = useState(false);
    const [broken, setBroken] = useState(false);
    return (
        <div>
            <CaseDetailContext.Provider value={{
                toggled, setToggled,
                broken, setBroken,
            }}>
                {children}
            </CaseDetailContext.Provider>
        </div>
    )
}
