const subFolder = [
    "Att Notes",
    "Court Bundle"
  ];
  
  const feeEarner = [
    "Abdullah",
    "Ali",
    "Ahmed",
    "Khan",
    "Umer",
    "Umair",
    "R Razaq"
  ];
  
  export { subFolder, feeEarner };
  