import React, { useState, useEffect } from "react";
import { MyProSidebarProvider } from "../navigation/side-navbar/sidebar-context";
import DynamicTab from "../controls/dynamic tab/dynamic-tab";
import { getFromSS, setToSS } from "../../utils/helpers/storage";
import HandleModal from "../modals/handle-modal";
import { useParams } from "react-router-dom";
import { caseDetail } from "../../services/case-service";
import CaseDetailFooter from "../case-detail/case-detail-footer";
import { dynamicEntity } from "../../services/dynamic-data-service";
import { unlockCase } from "../../services/case-service";
import { openAndLockCase } from "../../services/case-service";
import ToastAlert from "../modals/alert-modals/toast-alert";
import MenuAppBar from "../case-detail/case-detail-topbar";
const CaseDetailLayout = ({ children }) => {
  const [tabs, setTabs] = useState([]);
  const [selectedTab, setSelectedTab] = useState(null);
  const [caseStatus, setCaseStatus] = useState("");
  const [caseType, setCaseType] = useState("");
  const [caseSubType, setCaseSubType] = useState("");
  const [dynamicMenuItems, setDynamicMenuItems] = useState([]);
  const { caseID } = useParams();
  useEffect(() => {
    document.title = `Case ${caseID}`;
    const openCase = getFromSS(`Open_CaseID_${caseID}`);
    if (openCase) {
    } else {
      CaseDetailTab();
    } 
    CallCaseDetailApi();
  }, []);
  useEffect(() => {
    getFromSS(caseID);
  }, [caseID]);
  useEffect(() => {
    if (caseType) {
      CallDynamicEntityApi(); // Call the Test function to fetch and store the data
    }
  }, [caseType, caseSubType]);
  useEffect(() => {
    const handleBeforeUnload = async (event) => {
      event.preventDefault();
      try {
        await unlockCase(caseID);
        window.open('', '_self').close();
      } catch (error) {
        console.error(error);
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [caseID]);
  const CaseDetailTab = async () => {
    let returnCode;
    if (caseID ) {
      const response = await openAndLockCase(caseID);
      returnCode = response.data.returnCode;
      try {
        if (returnCode === 1) {
          setToSS(`Open_CaseID_${caseID}`, true)
        }
        else if (returnCode === -9) {
        } else if (returnCode === -1) {
        } else if (returnCode === -8) {
        }
      }
      catch (error) {
        console.error(error);
      }
    }
  }
  const CallCaseDetailApi = async () => {
    try {
      const response = await caseDetail(caseID, true);
      const result = response.data.result;
      setCaseType(result.caseType);
      setCaseSubType(result.caseSubType);
      setCaseStatus(result.status);
      setToSS(`Case_Detail_${caseID}`, result);
    }
    catch (error) {
      console.error(error);
    }
  };
  const CallDynamicEntityApi = async () => {
    try {
      const response = await dynamicEntity(caseType, caseSubType, caseID, "1");
      const caseData = response.data.results;
      setToSS(caseID, response.data.results);
      setDynamicMenuItems(response.data.results); // Log the response data
      caseData?.map((item) => {
        if (item.openByDefault) {
          addTab(item);
        }
      });
    } catch (error) {
      console.error(error); // Log any errors
    }
  };
  const handleMenuItemClick = (menuItem) => {
    // Check if a tab with the same title (entityName) already exists
    const existingTabIndex = tabs.findIndex((tab) => tab.entityLabel === menuItem.entityLabel);
    if (existingTabIndex !== -1) {
      // Tab exists, select it
      setSelectedTab(tabs[existingTabIndex]);
    } else {
      addTab(menuItem);
    }
  };
  // Function to add a new tab
  const addTab = (menuItem) => {
    const newTab = menuItem;
    setTabs((prevTabs) => [...prevTabs, newTab]);
    setSelectedTab(newTab[0]);
  };
  // Function to close a tab
  const closeTab = (tab) => {
    const updatedTabs = tabs.filter((t) => t !== tab);
    setTabs(updatedTabs);
    // Optionally, clear the selected tab if it's closed
    if (selectedTab === tab) {
      setSelectedTab(null);
    }
  };
  return (
    <>
    <ToastAlert></ToastAlert>
      <MyProSidebarProvider onClickMenuItem={handleMenuItemClick} caseType={caseType} caseSubType={caseSubType} paramDynamicMenuItems={dynamicMenuItems}>
      <MenuAppBar></MenuAppBar>
      {children}
        <DynamicTab data={tabs} selectedTab={selectedTab} setSelectedTab={setSelectedTab} addTab={addTab} closeTab={closeTab} caseSubType={caseSubType} />
      </MyProSidebarProvider>
      <CaseDetailFooter caseStatus={caseStatus} caseType={caseType}></CaseDetailFooter>
      <HandleModal caseType={caseType}></HandleModal>
    </>
  );

};

export default CaseDetailLayout;
