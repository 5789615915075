import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Stopwatch from '../controls/stopwatch';
import VerticalToggleButton from '../controls/toggle-button';
import { getFromLS } from '../../utils/helpers/storage';
import { useDispatch } from 'react-redux';
import { getStartTime } from '../features/timer-slice';
export default function CaseDetailFooter({ caseStatus, caseType }) {
  const [hourlyRate, setHourlyRate] = useState(0);
  const [caseTypeDescription, setCaseTypeDescription] = useState("");
  const [minutesPerUnit, setMinutesPerUnit] = useState(0);
  const dispatch = useDispatch();
  useEffect(() => {
    const systemSettingData = getFromLS("SystemSettings");
    const filteredData = systemSettingData.filter(parameter => parameter.parameterID === "MinutesPerUnit");
    setMinutesPerUnit(filteredData[0].parmChar);
  }, []);
  useEffect(() => {
    dispatch(getStartTime({ startTime: new Date().toISOString() }))
    const storedData = getFromLS('User');
    const filteredData = filterData(storedData.authorisedCaseTypes);
    const authorisedCaseTypes = filteredData[0];
    setHourlyRate(authorisedCaseTypes.chargingRate);
    setCaseTypeDescription(authorisedCaseTypes.caseTypeDescription)
  }, []);
  const filterData = (data) => {
    return data.filter(item => item.caseType.includes(caseType));
  };
  return (
    <LoginFooterWrapper className="text-white text-center text-lg-start ">
      <div className="container-fluid p-1 ">
        <div className="row ">
          <div className="col-1 col-md-2 mb-md-0 text-center p-2">
            <Stopwatch chargingRate={hourlyRate} minutesPerUnit={minutesPerUnit} />
          </div>
          <div className="col-1 col-md-2 mb-md-0 text-center p-2">
            <VerticalToggleButton />
          </div>
          <div className="col-2 col-md-2 mb-md-0 text-center p-2">
            Case Hourly Rate: {hourlyRate.toFixed(2)}
          </div>
          <div className="col-2 col-md-2 mb-md-0 text-center p-2">
            {caseTypeDescription}
          </div>
          <div className="col-2 col-md-2 mb-md-0 text-center p-2">
            {caseStatus}
          </div>
        </div>
      </div>
    </LoginFooterWrapper>
  );
}
const LoginFooterWrapper = styled.footer`
  background: ${({ theme }) => theme.palette.nav.main};
`;
