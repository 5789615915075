import React, { useEffect, useState } from 'react';
import Dropdown from '../../../controls/drop-down';
import TextInput from '../../../controls/textinput';
import Datepicker from '../../../controls/datepicker';
import styled from 'styled-components';
import IconButton from '../../../controls/icon-button';
import AddCircleSharpIcon from '@mui/icons-material/AddCircleSharp';
import DeleteSharpIcon from '@mui/icons-material/DeleteSharp';
import { personSearch } from '../../../../services/entity-service';
import ExistingEntitiesGrid from '../../../data-grid/existing-entity-grid/existing-entities-grid';
import RadioButton from '../../../controls/radio-button';
import Casetype from '../../../json/Casetype'
export default function PersonalDetail({ entityName, detailIndex, entityType, caseID, entityID, onRowDoubleClick, apiTitle, apiForename, apiSurname,handleMenuItemClick }) {
  const [surname, setSurname] = useState(apiSurname);
  const [forename, setForename] = useState(apiForename);
  const [title, setTitle] = useState(apiTitle);
  const [textFieldsChanged, setTextFieldsChanged] = useState(false);
  const [showViewExistingEntities, setShowViewExistingEntities] = useState(false);
  const [apiData, setApiData] = useState([]);
  const [gender, setGender] = useState("M");
  const handleChange = (event) => {
    setGender(event.target.value);
  };
  const handleSurnameChange = (event) => {
    setSurname(event.target.value);
    setTextFieldsChanged(true);

  };
  useEffect(() => {
    setSurname(apiSurname);
    setForename(apiForename);
    setTitle(apiTitle);
  }, [apiSurname, apiForename,apiTitle]);

  const handleForenameChange = (event) => {
    setForename(event.target.value);
    setTextFieldsChanged(true);
  };

  const handleOtherFieldBlur = () => {
    if (textFieldsChanged) {
      // Make API call here
      CallApi();
    }
  };
  const CallApi = async () => {
    let person;
    try {
      const response = await personSearch(entityName, false, surname,forename);
      setTextFieldsChanged(false);;
      person = response.data.results;
      setApiData(person);
      // setShowViewExistingEntities(true);
      handleMenuItemClick(person ,true);
    } catch (error) {
     console.error(error);
    }
  };
  const handleTitle = (event) => {
    const title = event.target.value;
    setTitle(title);
};
  return (

    <div>
      {showViewExistingEntities && <ExistingEntitiesGrid data={apiData} onCloseClick={() => { setShowViewExistingEntities(!showViewExistingEntities) }} onRowDoubleClick={onRowDoubleClick}></ExistingEntitiesGrid>}
      <DivWrapper >
        <h6>Personal Details</h6>
        <hr></hr>
        <div className="row g-3">
          <div className='col-lg-4 col-md-4 col-4'>
            <Dropdown label="Title" id="title" value={title} options={Casetype} onChange={handleTitle}></Dropdown>
          </div>
          <div className='col-lg-4 col-md-4 col-4'>
          <RadioButton label="Male" gender="M" value={gender} handleChange={handleChange}></RadioButton>
          <RadioButton label="Female" gender="F" value={gender}  handleChange={handleChange}></RadioButton>
          </div>
          <div className='col-lg-2 col-md-2 col-2'>
            <IconButton label="Delete" id="delete" color="danger" size="large" >
              <DeleteSharpIcon fontSize="large" />
            </IconButton>
          </div>
          <div className='col-lg-2 col-md-2 col-2'>
            <IconButton label="Add" id="add" color="success" size="large" >
              <AddCircleSharpIcon fontSize="large" />
            </IconButton>
          </div>
          <div className='col-lg-12'>
            <TextInput label="Forename(s)" id="forename" variant="standard" onChange={handleForenameChange} value={forename}></TextInput>
          </div>
          <div className='col-lg-12'>
            <TextInput label="Surname" id="surname" variant="standard" onChange={handleSurnameChange} onBlur={handleOtherFieldBlur} value={surname}></TextInput>
          </div>
          <div className='col-lg-12'>
            <Datepicker label="D.O.B" id="dob" ></Datepicker>
          </div>
        </div>
      </DivWrapper>
    </div>
  )
}
const DivWrapper = styled.div`
  margin: 10px ;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px 20px 10px 20px;
    `;
