import React, { memo, useState, createContext, useContext } from "react";
import SideBar from "./sideBar";

const SidebarContext = createContext({});

export const MyProSidebarProvider = memo(({ children, caseSubType, onClickMenuItem, paramDynamicMenuItems, dashboardView }) => {
    const sidebarValue = {
        caseSubType,
        // Add other properties or functions you want to make available here
    };
    return (

        <SidebarContext.Provider value={sidebarValue}>
            <div>
                <SideBar onClickMenuItem={onClickMenuItem} caseSubType={caseSubType} paramDynamicMenuItems={paramDynamicMenuItems} dashboardView={dashboardView}>
                    {children}
                </SideBar>
            </div>
        </SidebarContext.Provider>

    );
});

export const useSidebarContext = () => useContext(SidebarContext);
