import * as React from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import dayjs from 'dayjs'; 
export default function TimePickerValue({label , value , onChange}) {
  let parsedValue = null;
  if (value) {
     parsedValue = dayjs(value); // Convert the string to a Dayjs object if value is not null
}

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      
        <TimePicker
          views={['hours', 'minutes', 'seconds']}// Include 'days' view
          label={label}
          value={parsedValue}
          onChange={onChange}
       
          slotProps={{ textField: { variant: 'standard', fullWidth: true, size: 'small' } }}
        />

    </LocalizationProvider>
  );
}
