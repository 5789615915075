import React, { useEffect, useState } from 'react'
import { recentList } from '../../services/case-service';
import CaseRecentGrid from '../../components/data-grid/case-recent-grid/case-recent-grid';
import { useAuth } from '../../hooks/useAuth';
import CardView from '../../components/controls/card-view';
import styled from 'styled-components';
import Box from '@mui/material/Box';
export default function RecentListView({ headerTitle }) {
    const [recentListData, setRecentListData] = useState([]);
    const { user } = useAuth();
    const userID = user.id;
    useEffect(() => {
        CallRecentListApi();
    }, []);
    const CallRecentListApi = async () => {
        try {
            const recentlist = await recentList(userID);
            setRecentListData(recentlist.data.results)
        }
        catch (error) {
            console.error(error);
        }
    };
    return (
        <ComponentWrapper>
            <CardView>
                <CaseRecentGrid recentListData={recentListData} headerTitle={headerTitle}></CaseRecentGrid>
            </CardView>
        </ComponentWrapper>
    )
}
const ComponentWrapper = styled(Box)`
 padding:5px;
`;