import React, { useEffect, useState } from 'react'
import HandleNote from '../../components/data-grid/case-note/handle-note'
import { caseNotes } from '../../services/case-service';
import { useParams } from 'react-router-dom';
export default function CaseNoteView() {
  const { caseID } = useParams();
  const [caseNote, setCaseNote] = useState("");
  useEffect(() => {
    CallCaseNotesApi();
  }, []);
  const CallCaseNotesApi = async () => {
    try {
      const casenotes = await caseNotes(caseID);
      setCaseNote(casenotes.data.result);
    }
    catch (error) {
      console.error(error);
    }
  };
  return (
    <HandleNote caseNote={caseNote}></HandleNote>
  )
}
