import { httpClient } from "./httpclient";
const url = "v1/case";

const caseDetail = async (id, completeexpiretaskonopen) => {
  try {
    const endpoint = `${url}/${id}/${completeexpiretaskonopen}`;
    return await httpClient.get(endpoint);
  } catch (error) {
    throw new Error(error.response.status);
  }
};
const activeList = async (userID) => {
  try {
    const endpoint = `${url}/activelist/${userID}`;
    return await httpClient.get(endpoint);
  } catch (error) {
    throw new Error(error.response.status);
  }
};
const recentList = async (userID) => {
  try {
    const endpoint = `${url}/recentlist/${userID}?withinDays=10`;
    return await httpClient.get(endpoint);
  } catch (error) {
    throw new Error(error.response.status);
  }
};
const caseSearch = async (foreName, surName, clientAddress, phoneNumber, emailAddress, otherPartyName, otherPartyRef, keyField1, keyValue1, keyField2, keyValue2, formattedStartDate, formattedEndDate) => {
  try {
    const endpoint = `${url}/search?ClientForename=${foreName}&ClientSurname=${surName}&ClientAddress=${clientAddress}&clientTelephone=${phoneNumber}&clientEmailAddress=${emailAddress}&organisationName=${otherPartyName}&organisationRef=${otherPartyRef}&searchKeyFieldId1=${keyField1}&searchKeyValue1=${keyValue1}&searchKeyFieldId2=${keyField2}&searchKeyValue2=${keyValue2}&startDate=${formattedStartDate}&endDate=${formattedEndDate}`;
    return await httpClient.get(endpoint);
  } catch (error) {
    throw new Error(error.response.status);
  }
};
const openAndLockCase = async (caseID) => {
  try {
    const endpoint = `${url}/openandlock/${caseID}`;
    return await httpClient.get(endpoint);
  } catch (error) {
    throw new Error(error.response.status);
  }
};
const unlockCase = async (caseID) => {
  try {
    const endpoint = `${url}/unlock/${caseID}`;
    return await httpClient.get(endpoint);
  } catch (error) {
    throw new Error(error.response.status);
  }
};
const caseNotes = async (caseID) => {
  try {
    const endpoint = `${url}/notes/${caseID}`;
    return await httpClient.get(endpoint);
  } catch (error) {
    throw new Error(error.response.status);
  }
};
const addCaseNotes = async (caseID, notes) => {
  try {
    var request = {
      caseID: caseID,
      notes: notes,
    };
    const endpoint = `${url}/notes/add`;
    return await httpClient.post(endpoint, request);
  } catch (error) {
    throw new Error(error.response.status);
  }
};
export {
  caseDetail,
  activeList,
  recentList,
  caseSearch,
  openAndLockCase,
  unlockCase,
  caseNotes,
  addCaseNotes,
};
