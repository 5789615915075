import React,{useState} from 'react'
import Dropdown from '../../controls/drop-down';
import styled from 'styled-components';
import TextInput from '../../controls/textinput';
import RadioButton from '../../controls/radio-button';
export default function PersonalDetail({ personData }) {
    const [gender, setGender] = useState(personData[0]?.gender ? personData[0].gender : 'M');
    const handleChange = (event) => {
        setGender(event.target.value);
      };
    return (
        <div>
            <Dropdown label="Contacts" id="contacts" ></Dropdown>
            <DivWrapper >
                <h6>Personal Details</h6>
                <hr></hr>
                <div className="row g-3">
                    <div className='col-lg-6 col-md-6 col-6'>
                        <Dropdown label="Title" id="title" ></Dropdown>
                    </div>
                    <div className='col-lg-3 col-md-3 col-3'>
                            <RadioButton label="Male" gender="M" value={gender} handleChange={handleChange}></RadioButton>
                    </div>
                    <div className='col-lg-3 col-md-3 col-3'>
                            <RadioButton label="Female" gender="F" value={gender}  handleChange={handleChange}></RadioButton>
                    </div>
                    <div className='col-lg-12'>
                        <TextInput label="Forenames(s)" id="forenames" variant="standard"></TextInput>
                    </div>
                    <div className='col-lg-12'>
                        <TextInput label="Surname" id="surname" variant="standard"></TextInput>
                    </div>
                </div>
            </DivWrapper>
        </div>
    )
}
const DivWrapper = styled.div`
  margin: 10px ;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px 20px 10px 20px;
    `;
