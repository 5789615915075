import React from "react";
import styled, { keyframes } from "styled-components";

// Define keyframes for animations
const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const fade = keyframes`
  0%, 100% {
    opacity: 0.3;
  }
  50% {
    opacity: 1;
  }
`;

// Styled components
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

const Spinner = styled.div`
  border: 4px solid #4285f4; /* Google blue color */
  border-top: 4px solid transparent;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  animation: ${spin} 1.5s linear infinite, ${fade} 1.5s ease-in-out infinite;
`;

const Text = styled.div`
  margin-top: 16px;
  font-size: 16px;
  color: #333;
`;

export default function Loader({ showLoader }) {
  if (showLoader) {
    return (
      <Container>
        <Spinner />
        <Text>Loading cManager...</Text>
      </Container>
    );
  }
}
