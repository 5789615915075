import React, { useState } from 'react'
import Letters from '../../components/data-grid/letters-data-grid/letters'
import List from '../../components/controls/list';
import { defendant, Case, client } from '../../components/json/listofDocument';
import { CASE , CLIENT , DEFENDANT } from '../../utils/constants/case-types';
export default function CaseLetterView() {
    const [selectedType, setSelectedType] = useState('');
    const onRowSingleClick = (e) => {
        const rowData = e.data;
        const Type = rowData?.Type;
        setSelectedType(Type);
    };
    let dataSource = Case;
    if (selectedType === DEFENDANT) {
        dataSource = defendant;
    } else if (selectedType === CASE) {
        dataSource = Case;
    } else if (selectedType === CLIENT) {
        dataSource = client;
    }
    return (
            <div className='row'>
                <div className='col-6'>
                    <Letters onRowClick={onRowSingleClick}></Letters>
                </div>
                <div className='col-6'>
                    <List dataSource={dataSource} height={400}></List>
                </div>
            </div>
    )
}
