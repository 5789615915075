import React from 'react'
import Button from '../controls/button'
import Modal from './custom-modal'
import TextInput from '../controls/textinput'
import TextArea from '../controls/textarea'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faNoteSticky } from '@fortawesome/free-solid-svg-icons'
export default function FileNoteModal({hide}) {
    return (
        <div>
            <Modal icon={<FontAwesomeIcon icon={faNoteSticky} />} hide={hide} title="File Note" className="modal-dialog  modal-lg " footer={
                <>
                    <Button size='medium' variant='outlined' name='Cancel' onClick={hide}></Button>
                    <Button type='submit' size='medium' variant='contained' name='OK'></Button>
                </>
            }>
                <div className='row g-3'>
                    <div className='col-6'>
                        <TextInput label="Case ID" id="fileCaseID" variant="standard" disabled="true"></TextInput>
                    </div>
                    <div className='col-6'>
                        <TextInput label="Fee Earner" id="fileFeeEarner" variant="standard" disabled="true"></TextInput>
                    </div>
                    <div className='col-12'>
                        <TextInput label="Summary" id="fileSummary" variant="standard" ></TextInput>
                    </div>
                    <div className='col-12'>
                        <TextArea label="Details" id="fileDetails" row="4"></TextArea>
                    </div>
                    <div className='col-12'>
                        <TextInput label="Save As" id="fileSaveAs" variant="standard" ></TextInput>
                    </div>
                </div>
            </Modal>
        </div>
    )
}
