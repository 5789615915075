import React, { useState, useEffect, useCallback } from 'react';
import CaseTaskGrid from '../../components/data-grid/case-task-grid/task-grid';
import { useAuth } from '../../hooks/useAuth';
import { mainCaseTask } from '../../utils/constants/application-areas';
import { caseTask } from '../../services/task-service';
import { CheckBox } from 'devextreme-react';
import { Toolbar, Item } from 'devextreme-react/data-grid';
import Button from 'devextreme-react/button';
import { useDispatch } from 'react-redux';
import { openModal } from '../../components/features/modal-slice';
import { ADD_TASK } from '../../utils/constants/modal-name';
import { ADD } from '../../utils/constants/mode'
export default function CaseTaskView({ caseID }) {
  const { user } = useAuth();
  const userID = user.id;
  const [caseTaskData, setCaseTaskData] = useState([]);
  const [includeDeletedCompleted, setIncludeDeletedCompleted] = useState(false);
  const [checkBoxDefaultValue, setCheckBoxDefaultValue] = useState(false);
  const key = userID + mainCaseTask;
  const dispatch = useDispatch()
  useEffect(() => {
    CallCaseTaskApi();
  }, [includeDeletedCompleted]);

  const CallCaseTaskApi = useCallback(async () => {
    try {
      const casetask = await caseTask(caseID, includeDeletedCompleted);
      setCaseTaskData(casetask.data.results);
    } catch (error) {
      console.error(error);
    }
  }, [caseID, includeDeletedCompleted]);

  const toggleCompletedTasks = useCallback((data) => {
    const checkBoxValue = data.value;
    setIncludeDeletedCompleted(checkBoxValue);
    setCheckBoxDefaultValue(checkBoxValue);
  }, []);

  return (
    <div className="container-fluid">
      <CaseTaskGrid taskData={caseTaskData} setTaskData={setCaseTaskData} storeKey={key}>
        <Toolbar>
          <Item location="before" locateInMenu="auto">
            <CheckBox
              defaultValue={checkBoxDefaultValue}
              onValueChanged={toggleCompletedTasks}
              text="Include Completed / Deleted Task"
            />
          </Item>
          <Item location="before" locateInMenu="auto">
            <div>{caseID}</div>
          </Item>
          <Item location="after" locateInMenu="auto">
            <Button
              icon="plus"
              text="Add Task"
              type="default"
              stylingMode="contained"
              onClick={() => dispatch(openModal({ modalName: ADD_TASK, mode: ADD, data: null, }))}
            />
          </Item>
          <Item name="columnChooserButton" locateInMenu="auto" />
          <Item name="searchPanel" locateInMenu="auto" />
        </Toolbar>
      </CaseTaskGrid>
    </div>
  );
}
