import React from 'react'
import TextInput from '../../controls/textinput';
import Button from '../../controls/button';
import Dropdown from '../../controls/drop-down';
import styled from 'styled-components';
export default function AddressDetail({organisationData}) {
    const street1=organisationData.street1;
    const street2=organisationData.street2;
    const houseID=organisationData.houseID;
    const district=organisationData.district;
    const town=organisationData.town;
    const postcode=organisationData.postcode;
    const fax=organisationData.fax;
    const phone=organisationData.phone;
    const emailAddress=organisationData.emailAddress;
    const homePage=organisationData.homePage;
    return (
        <div>
            <DivWrapper >
                <h6>Address Details</h6>
                <hr></hr>
                <div className="row g-3">
                    <div className='col-lg-8 col-md-8 col-8'>
                    <Dropdown label="Name" id="name" ></Dropdown>
                    </div>
                    <div className='col-lg-4 col-md-4 col-4'>
                        <Button size='medium' variant='contained' name='Change Name'  ></Button>
                    </div>
                    <div className='col-lg-12'>
                        <TextInput label="House ID" id="houseID" variant="standard" value={houseID}></TextInput>
                    </div>
                    <div className='col-lg-6 col-md-6 col-6'>
                        <TextInput label="Street 1" id="street1" variant="standard" value={street1}></TextInput>
                    </div>
                    <div className='col-lg-6 col-md-6 col-6'>
                        <TextInput label="Street 2" id="Street2" variant="standard" value={street2}></TextInput>
                    </div>
                    <div className='col-lg-12'>
                        <TextInput label="District" id="district" variant="standard" value={district}></TextInput>
                    </div>
                    <div className='col-lg-6 col-md-6 col-6'>
                        <TextInput label="Town" id="town" variant="standard" value={town}></TextInput>
                    </div>
                    <div className='col-lg-6 col-md-6 col-6'>
                        <Dropdown label="" ></Dropdown>
                    </div>
                    <div className='col-lg-6 col-md-6 col-6'>
                        <TextInput label="Post Code" id="postCode" variant="standard" value={postcode}></TextInput>
                    </div>
                    <div className='col-lg-6 col-md-6 col-6'>
                        <Dropdown label="" ></Dropdown>
                    </div>
                    <div className='col-lg-12'>
                        <Button size='medium' variant='contained' name='Lookup Address' fullWidth={true}  ></Button>
                    </div>
                    <div className='col-lg-6 col-md-6 col-6'>
                        <TextInput label="Phone" id="phone" variant="standard" value={phone}></TextInput>
                    </div>
                    <div className='col-lg-6 col-md-6 col-6'>
                        <TextInput label="Fax" id="fax" variant="standard" value={fax}></TextInput>
                    </div>
                    <div className='col-lg-12'>
                        <TextInput label="Email" id="email" variant="standard" value={emailAddress}></TextInput>
                    </div>
                    <div className='col-lg-12'>
                        <TextInput label="Website" id="website" variant="standard" value={homePage}></TextInput>
                    </div>
                </div>
            </DivWrapper>
        </div>
    )
}
const DivWrapper = styled.div`
  margin: 10px ;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px 20px 10px 20px;
    `;
