import React from "react";
import styled from "styled-components";
function Note({ note }) {
  return (
    <CardBody className="card">
      <div className="card-body">
        <StyledContent>{note}</StyledContent>
      </div>
    </CardBody>
  );
}
const CardBody = styled.div`
  background: ${({ theme }) => theme.palette.caseNote.main};
  border-radius: 7px;
  box-shadow: 0 2px 5px #ccc;

  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px;
  max-height:800px;
  overflow-y:auto;
  
  ::-webkit-scrollbar {
    width: 8px;
    background-color: #f5f5f5;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 4px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }
`;
const StyledContent = styled.pre`
  margin: 0;
`;

export default Note;
