import React, { useEffect, useState } from "react";
import {
  Button,
  TextField,
  Typography,
  Link,
  Container,
  CssBaseline,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import backgroundImg from "../../assets/images/login-bg.png";
import cManagerLogo from "../../assets/logos/LOGO CMANAGER.png";
import styled from "styled-components";
import useImage from "../../hooks/useImage";
import { authenticateUser } from "../../services/user-service";
import Loader from "../../components/shared/loader";
import { useAuth } from "../../hooks/useAuth";
import { Navigate } from "react-router";
import { setToLS } from "../../utils/helpers/storage";
import { getSystemSetting } from "../../services/setting-service";
import LoginFooter from "../../components/login/login-footer/login-footer";
import LoginForm from "../../components/login/login-form/login-form";
import ForgotPasword from "../../components/login/forgot-password/forgot-password";

const StyledContainer = styled(Container)`
  display: flex;
  padding: 0;
  min-height: 100vh;
`;

const LeftPane = styled.div`
  background-image: url(${backgroundImg});
  background-size: cover;
  background-repeat: no-repeat;
  width: 60%;
  @media (max-width: 1652px) {
    width: 60%;
  }

  @media (max-width: 1300px) {
    width: 50%;
  }

  @media (max-width: 1000px) {
    width: 40%;
  }

  @media (max-width: 700px) {
    display: none;
  }
  position: relative;
`;

const Overlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.2);
`;

const LeftPaneContent = styled.div`
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 10%;
`;

const LeftPaneFooter = styled.div`
  color: white;
  position: absolute;
  bottom: 12%;

  @media (max-width: 1000px) {
    display: none;
  }
`;

const RightPane = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40%;
  padding: theme.spacing(8);
  background-color: #fff;
  padding: 5%;
  justify-content: center;
  @media (max-width: 1652px) {
    width: 40%;
  }

  @media (max-width: 1300px) {
    width: 50%;
  }

  @media (max-width: 1000px) {
    width: 60%;
  }

  @media (max-width: 700px) {
    width: 100%;
  }
`;

const StyledTypography = styled(Typography)`
  padding-bottom: ${(props) => props.spacing};
  padding-top: ${(props) => props.spacing};
  color: ${(props) => props.colour};
`;

const ImgLogo = styled.img`
  max-width: 258px;
  position: absolute;
  top: 29px;
`;

const ProductLogo = styled.img`
  background: black;
  padding: 10px;
  border-radius: 6px;
  width: 51px;
  margin-right: 11px;
`;
const theme = createTheme();

const LoginView = ({ content }) => {
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [userId, setUserId] = useState("");
  const [incorrectAttempts, setIncorrectAttempts] = React.useState(0);
  const [showError, setShowError] = useState(false);
  const [showCaptcha, setShowCaptcha] = useState(false);
  const [recaptchavalue, setCaptchaValue] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const { onLogin, user } = useAuth();
  const image = useImage("logos/" + content.logo);
  const [clientName, setClientName] = useState(content.name);

  useEffect(() => {
    if (clientName) {
      document.title = `cManager | ${clientName}`;
      GetSystemSettings();
    }
  }, [clientName]);

  const GetSystemSettings = async () => {
    try {
      const systemSetting = await getSystemSetting();
      setToLS("SystemSettings", systemSetting.data.results);
    } catch (error) {
      console.error(error);
    }
  };

  const handleLogin = async (event) => {
    event.preventDefault();
    setShowLoading(true);
    try {
      var response = await authenticateUser(
        userId,
        password,
        "C",
        "192.168.0.1"
      );

      // Log the user in
      if (response.data.result) {
        onLogin(response.data.result);
      } else {
        //incorrect username and passwords.
        // Increment the number of incorrect attempts
        setIncorrectAttempts(incorrectAttempts + 1);
        setShowError(true);
        // Show the captcha if the number of incorrect attempts is three or more
        if (incorrectAttempts >= 2) {
          setShowCaptcha(true);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setShowLoading(false);
    }
  };

  const onCaptchaChange = (value) => {
    setCaptchaValue(true);
  };

  const handleForgotPassword = () => {
    setShowForgotPassword(!showForgotPassword);
  };

  if (user) {
    return <Navigate to="/home"></Navigate>;
  }

  return (
    <ThemeProvider theme={theme}>
      <StyledContainer
        component="main"
        maxWidth="xxxl"
        style={{ display: "flex", padding: 0, minHeight: "100vh" }}
      >
        <CssBaseline />

        <LeftPane>
          <Overlay />

          <LeftPaneContent>
            <ImgLogo src={image.image} alt="logo"></ImgLogo>
            <StyledTypography
              colour="#fff"
              spacing={theme.spacing(2)}
              variant="h4"
            >
              {clientName}
            </StyledTypography>
            <StyledTypography colour="#fff" spacing={theme.spacing(1)}>
              {content.welcomeText}
            </StyledTypography>
            <LeftPaneFooter>
              <LoginFooter content={content} />
            </LeftPaneFooter>
          </LeftPaneContent>
        </LeftPane>

        <RightPane>
          <Loader showLoader={showLoading}></Loader>
          <StyledTypography
            colour="#05445E"
            spacing={theme.spacing(4)}
            component="h1"
            variant="h5"
          >
            <ProductLogo src={cManagerLogo}></ProductLogo>
            cManager
          </StyledTypography>

          {showError && (
            <div className="alert alert-danger">
              Failed logon #{incorrectAttempts}: Invalid credentials
            </div>
          )}
          {showForgotPassword ? (
            <ForgotPasword
              onBackToLogin={handleForgotPassword}
              onUserIDChange={setUserId}
              userId={userId}
              onForgetPassword={handleForgotPassword}
            ></ForgotPasword>
          ) : (
            <LoginForm
              userId={userId}
              password={password}
              setUserId={setUserId}
              setPassword={setPassword}
              onCaptchaChange={onCaptchaChange}
              showError={showError}
              showCaptcha={showCaptcha}
              handleLogin={handleLogin}
              handleForgotPassword={handleForgotPassword}
            ></LoginForm>
          )}
        </RightPane>
      </StyledContainer>
    </ThemeProvider>
  );
};

export default LoginView;
