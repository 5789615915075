const ADD_TASK_ID = "addtask";
const ADD_CASE_ID = "addcase";
const CASES_ID = "usercaselist";
const Recent_Cases_ID = "recentcases"
const TASK_WEEK_ID = "tasksweek";
const TASK_LATER_ID = "taskslater";
const LIMITATION_ID = "limitations"
const CLAIM_PORTAL_ID = "portaldatelimits";
const NOTIFICATION_ID = "notifications";
const CASES_SEARCH_ID = "casesearch";
const TASK_COURT_AVIATION_ID = "shared|court";
const TASK_JUDGE_ID = "shared|judge";
export { ADD_TASK_ID, ADD_CASE_ID, CASES_ID, Recent_Cases_ID, TASK_WEEK_ID, TASK_LATER_ID, LIMITATION_ID, CLAIM_PORTAL_ID, NOTIFICATION_ID, CASES_SEARCH_ID, TASK_COURT_AVIATION_ID, TASK_JUDGE_ID };