import React, { useState, useEffect } from 'react';
import CaseTaskGrid from '../../components/data-grid/case-task-grid/task-grid'
import { Toolbar, Item } from 'devextreme-react/data-grid'
import Button from 'devextreme-react/button';
import { useDispatch } from 'react-redux';
import { userTask, limitation, portalDateLimit, notification, sharedCalendarTask } from '../../services/task-service';
import { openModal } from '../../components/features/modal-slice';
import { ADD_TASK } from '../../utils/constants/modal-name';
import { ADD } from '../../utils/constants/mode'
import CardView from '../../components/controls/card-view';
import Box from '@mui/material/Box';
import styled from 'styled-components';
import { useAuth } from '../../hooks/useAuth';
import { homeCaseTask } from '../../utils/constants/application-areas';
export default function HomeTaskView({ apiFunction, thisWeek, sharedCalendar, headerTitle, componentID }) {
    const dispatch = useDispatch()
    const { user } = useAuth();
    const userID = user.id;
    const tableKey = userID + homeCaseTask;
    const [taskData, setTaskData] = useState([]);

    useEffect(() => {
        const callApi = async () => {
            try {
                let responseData;
                switch (apiFunction) {
                    case limitation:
                    case portalDateLimit:
                    case notification:
                    case sharedCalendarTask:
                        responseData = await apiFunction(sharedCalendar);
                        break;
                    default:
                        responseData = await userTask(userID, thisWeek);
                        break;
                }

                setTaskData(responseData.data.results);
            } catch (error) {
                console.error(error);
            }
        };
        callApi();
    }, [apiFunction, thisWeek, userID, sharedCalendar]);
    return (
        <React.Fragment key={componentID}>
            <ComponentWrapper>
                <CardView>
                    <CaseTaskGrid taskData={taskData} storeKey={`${tableKey}_${componentID}`} homeView={true} setTaskData={setTaskData} headerTitle={headerTitle} homeViewHiddenColumn={false} >
                        <Toolbar>
                            <Item location='before'>
                                <div className='grid-header'>{headerTitle.toUpperCase()}</div>
                            </Item>
                            <Item location='after' locateInMenu='auto'>
                                <Button
                                    icon='plus'
                                    text={ADD_TASK}
                                    type='default'
                                    stylingMode='contained'
                                    onClick={() => dispatch(openModal({ modalName: ADD_TASK, mode: ADD, data: null, }))}
                                />
                            </Item>
                            <Item name='columnChooserButton' locateInMenu='auto' />
                            <Item name='searchPanel' locateInMenu='auto' />
                        </Toolbar>
                    </CaseTaskGrid>
                </CardView>
            </ComponentWrapper>
        </React.Fragment>
    )
}
const ComponentWrapper = styled(Box)`
 padding:5px;
`;