// Redux slice for managing the state related to the dashboard screen components, determining which component to render

import { createSlice } from "@reduxjs/toolkit";

// Initial state for the dashboard component slice
const initialState = {
  componentID: "", // Identifier for the currently selected/active component
  title: "",       // Title associated with the currently selected component
  data: "",        // Additional data related to the currently selected component
};

// Redux slice for dashboard component state management
export const dasboardComponentSlice = createSlice({
  name: "dasboardComponent",
  initialState,
  reducers: {
    // Action to set the component to render based on provided parameters
    componentToRender: (state, action) => {
      const { componentID, title, data } = action.payload;
      state.componentID = componentID;
      state.title = title;
      state.data = data;
    },
  },
});

// Exporting the action creators
export const { componentToRender } = dasboardComponentSlice.actions;

// Exporting the reducer
export default dasboardComponentSlice.reducer;
