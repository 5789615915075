import React from 'react';

const GoogleMaterialIcon = ({ index }) => {
    const iconMapping = {
        1: 'Home',
        2: 'note_stack',
        3: 'task',
        4: 'calendar_month',
        5: 'feed',
        // Add more mappings as needed
    };

    const iconName = iconMapping[index] || null;

    return (
        <>{iconName}</>
    );
};

export default GoogleMaterialIcon;