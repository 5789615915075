// Redux slice for managing alerts in the project

import { createSlice } from "@reduxjs/toolkit";

// Initial state for the alert controller slice
const initialState = {
    openAlert: false,   // Flag to control the visibility of the alert
    severity: "",       // Severity level of the alert (e.g., success, error)
    alertMessage: "",   // Message content of the alert
};

// Redux slice for alert state management
export const alertController = createSlice({
    name: "alert",
    initialState,
    reducers: {
        // Action to trigger an alert with specified properties
        triggerAlert: (state, action) => {
            const { openAlert, severity, alertMessage } = action.payload;
            state.openAlert = openAlert;
            state.severity = severity;
            state.alertMessage = alertMessage;
        },
        // Action to close the alert
        closeAlert: (state) => {
            state.openAlert = false;
        },
    },
});

// Exporting the action creators
export const { triggerAlert, closeAlert } = alertController.actions;

// Exporting the reducer
export default alertController.reducer;
