import React from 'react'
import TextField from "@mui/material/TextField";

export default function TextInput({ id, label, variant, value, onChange, helperText, error, onBlur, regexPattern, width, height, readOnly, type, onKeyUp, sx, required, disabled }) {

    return (
        <div>
            {required ?
                <TextField
                    fullWidth
                    id={id}
                    label={label}
                    variant={variant}
                    value={value || ''}
                    onChange={onChange}
                    type={type}
                    size="small"
                    required
                    helperText={helperText}
                    error={error}
                    onBlur={onBlur}
                    inputProps={{ pattern: regexPattern }}
                    sx={{ width, height }} // Add width and height styles
                    readOnly={readOnly}
                />
                : disabled ?
                    <TextField
                        fullWidth
                        id={id}
                        label={label}
                        variant={variant}
                        value={value || ''}
                        onChange={onChange}
                        type={type}
                        size="small"
                        disabled
                        onBlur={onBlur}
                        helperText={helperText}
                        error={error}
                        inputProps={{ pattern: regexPattern }}
                        sx={{ width, height }} // Add width and height styles
                        readOnly={readOnly}
                    /> :
                    <TextField
                        fullWidth
                        id={id}
                        label={label}
                        variant={variant}
                        value={value || ''}
                        onChange={onChange}
                        type={type}
                        helperText={helperText}
                        onKeyUp={onKeyUp}
                        error={error}
                        size="small"
                        onBlur={onBlur}
                        inputProps={{ pattern: regexPattern }}
                        sx={sx} // Add width and height styles
                        readOnly={readOnly}
                    />
            }
        </div>
    )
}
