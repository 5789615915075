import React from 'react'
import { Column, HeaderFilter } from 'devextreme-react/data-grid'
import CustomGrid from '../../controls/grid'
import letters from '../../json/letters'
import "./letters.css"
export default function Letters({ onRowClick }) {
    return (
        <div>
            <CustomGrid onRowClick={onRowClick} data={letters} id="letterGridContainer">
                <HeaderFilter visible={true} />
                <Column caption="Type" dataField="Type" dataType="string" ></Column>
                <Column caption="Contact ID" dataField="ContactID" dataType="string" ></Column>
                <Column caption="Contact Name" dataField="ContactName" dataType="string" ></Column>
                <Column caption="Refference" dataField="Refference" dataType="string"></Column>
            </CustomGrid>
        </div>
    )
}
