import {
  Button,
  IconButton,
  InputAdornment,
  Link,
  OutlinedInput,
  TextField,
  createTheme,
} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import ReCAPTCHA from "react-google-recaptcha";
import React from "react";
import FormControl from "@mui/material/FormControl";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import styled from "styled-components";
const Container = styled.form`
  width: 100%;
`;

const theme = createTheme();

export default function LoginForm({
  userId,
  password,
  setPassword,
  setUserId,
  handleLogin,
  handleForgotPassword,
  onCaptchaChange,
  showCaptcha,
}) {
  const [showPassword, setShowEye] = React.useState(false);

  const toggleEye = () => {
    setShowEye(!showPassword);
  };

  return (
    <Container onSubmit={handleLogin}>
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="username"
        label="Username"
        name="username"
        autoFocus
        value={userId}
        onChange={(e) => setUserId(e.target.value)}
      />

      <FormControl sx={{ mt: ``, mb: 2 }} fullWidth variant="outlined">
        <InputLabel htmlFor="password">Password *</InputLabel>
        <OutlinedInput
          id="password"
          type={showPassword ? "text" : "password"}
          name="password"
          required
          value={password}
          fullWidth
          onChange={(e) => setPassword(e.target.value)}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={toggleEye}
                onMouseDown={toggleEye}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
          label="Password"
        />
      </FormControl>

      <Link href="#" variant="body2" onClick={handleForgotPassword}>
        Forgot Password?
      </Link>

      {showCaptcha && (
        <ReCAPTCHA
          className="mt-2"
          sitekey="6LeepqIlAAAAAOA7d-nMvL-5kJkchCN4Tks4Mvdy"
          onChange={onCaptchaChange}
        />
      )}
      <Button
        fullWidth
        variant="contained"
        color="primary"
        type="submit"
        style={{ margin: theme.spacing(3, 0, 2) }}
      >
        Login
      </Button>
    </Container>
  );
}
