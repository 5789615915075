const FeeEarner = [
    {
        id : 1,
        name : "Abdullah"

    },
    {
        id : 2,
        name : "Dawood"

    },
    {
        id : 3,
        name : "Usman"

    }

]
export default FeeEarner;