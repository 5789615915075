import React from 'react'
import ActiveListGrid from '../../components/data-grid/active-list-grid/active-list-grid';
import { homeActiveList } from '../../utils/constants/application-areas';
import { useAuth } from '../../hooks/useAuth';
import Button from 'devextreme-react/button';
import { useDispatch } from 'react-redux';
import { openModal } from '../../components/features/modal-slice';
import { Toolbar, Item } from 'devextreme-react/data-grid';
import { ADD_CASE } from '../../utils/constants/modal-name';
import CardView from '../../components/controls/card-view';
import { ADD } from '../../utils/constants/mode';
import styled from 'styled-components';
import Box from '@mui/material/Box';
export default function ActiveListView({ headerTitle }) {
    const { user } = useAuth();
    const userID = user.id;
    const key = userID + homeActiveList;
    const dispatch = useDispatch();
    return (
        <ComponentWrapper>
            <CardView>
                <ActiveListGrid storeKey={key}>
                    <Toolbar>
                        <Item location='before'>
                            <div className='grid-header'>{headerTitle.toUpperCase()}</div>
                        </Item>
                        <Item location='before' name='groupPanel' locateInMenu='auto' />
                        <Item location="after" locateInMenu="auto">
                            <Button
                                icon="plus"
                                text="Add Case"
                                type="default"
                                stylingMode="contained"
                                onClick={() => dispatch(openModal({ modalName: ADD_CASE, mode: ADD, data: null, }))}
                            />
                        </Item>
                        <Item name='columnChooserButton' locateInMenu='auto' />
                        <Item name='searchPanel' locateInMenu='auto' />
                    </Toolbar>
                </ActiveListGrid>
            </CardView>
        </ComponentWrapper>
    )
}
const ComponentWrapper = styled(Box)`
 padding:5px;
`;