import React, { useCallback, useEffect } from 'react'
import CustomGrid from '../../controls/grid';
import { Column, Paging, ColumnChooser, Position, Toolbar, Item, SearchPanel, HeaderFilter, FilterPanel, LoadPanel, Scrolling, StateStoring } from 'devextreme-react/data-grid';
import "./cases.css";
import { tabSuffix, desktopSuffix } from '../../../utils/constants/storageKeys';
import { setToLS, getFromLS } from '../../../utils/helpers/storage';
import { Mobile, Desktop, Tab } from '../../../utils/constants/devices';
import { locale } from 'devextreme/localization';
import { useScreenSize } from '../../../hooks/useScreenSize';
export default function CasesGrid({ searchData, headerTitle, storeKey }) {
    const device = useScreenSize();
    const mergeForenameAndSurname = (rowData) => {
        return rowData.surname + ',' + ' ' + rowData.forename;
    };
    useEffect(() => {
        locale("en-GB");
    }, [searchData]);
    let storageKey;
    let mobile = false;
    if (device === Tab) {
        storageKey = storeKey + tabSuffix
        mobile = false;
    }
    else if (device === Desktop) {
        storageKey = storeKey + desktopSuffix
        mobile = false;
    }
    else if (device === Mobile) {
        mobile = true;
    }
    const loadState = useCallback(() => {
        return getFromLS(storageKey);
    }, []);

    const saveState = useCallback((state) => {
        setToLS(storageKey, state);
    }, []);
    return (
        <div>
            <CustomGrid data={searchData} id="caseSearchGridContainer" keyExpr="id" wordWrapEnabled={false} >
                <Paging enabled={false} />
                <SearchPanel visible placeholder='Task Search' />
                <Scrolling mode="virtual" rowRenderingMode="virtual" />
                <LoadPanel enabled={false} />
                <HeaderFilter visible={true} />
                <FilterPanel visible={true} />
                <Toolbar>
                    <Item location='before'>
                        <div className='grid-header'>{headerTitle.toUpperCase()}</div>
                    </Item>
                    <Item location='before' name='groupPanel' locateInMenu='auto' />
                    <Item name="columnChooserButton" locateInMenu="auto" />
                    <Item name='searchPanel' locateInMenu='auto' />
                </Toolbar>
                <StateStoring enabled={!mobile} type="custom" customLoad={loadState} customSave={saveState} />
                <Column dataField="id" caption="Case ID" dataType="number" />
                <Column dataField="secondaryID" caption="Secondary ID" dataType="string" />
                <Column caption="Client Name" calculateCellValue={mergeForenameAndSurname} dataType="string" />
                <Column dataField="status" caption="Status" dataType="string" />
                <Column dataField="caseType" caption="Case Type" dataType="string" />
                <Column dataField="caseSubType" caption="Case Sub Type" dataType="string" />
                <Column dataField="feeEarnerID" caption="Fee Earner" dataType="string" />
                <Column dataField="supervisorID" caption="Supervisor ID" dataType="string" />
                <Column dataField="open" caption="Open Date" dataType="date" />
                <Column dataField="incident" caption="Incident Date" dataType="date" />
                <Column dataField="close" caption="Close Date" dataType="date" />
                <Column dataField="lastUpdatedTime" caption="Last Update Time" dataType="string" />
                <Column dataField="" caption="Client Insurance" dataType="string" visible={false} />
                <Column dataField="" caption="Client Insurance Ref" dataType="string" visible={false} />
                <Column dataField="" caption="Other Party" dataType="string" visible={false} />
                <Column dataField="" caption="Other Party Ref" dataType="string" visible={false} />
                <Column dataField="" caption="Key Value 1" dataType="string" visible={false} />
                <Column dataField="" caption="Key Value 2" dataType="string" visible={false} />
                <ColumnChooser enabled={true}>
                    <Position
                        my="right top"
                        at="right bottom"
                        of=".dx-datagrid-column-chooser-button"
                    />
                </ColumnChooser>
            </CustomGrid>
        </div>
    )
}
