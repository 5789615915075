

const fieldData = [
  {
    "id": 55,
    "fieldType": "Date",
    "fieldName": "AppointmentDate",
    "fieldLabel": "Appointment Date",
    "fieldMinValue": null,
    "fieldMaxValue": null,
    "fieldDisplayWidth": 300,
    "fieldDisplayHeight": 30,
    "fieldParmCodeListID": 0,
    "fieldDisplayControlType": null,
    "fieldEditMask": null,
    "fieldMaskType": null,
    "fieldValue": null,
    "fieldValueID": 0,
    "fieldMaxLength": "10",
    "fieldUpdated": false,
    "fieldMandatoryCaseTypes": null,
    "fieldMandatoryMessage": null,
    "fieldKeyField": false,
    "fieldURLString": null,
    "detailsIndex": 1,
    "fieldCalculation": null,
    "hiddenField": false,
    "fieldReadOnly": false
  },
  {
    "id": 4,
    "fieldType": "Time",
    "fieldName": "AppointmentTime",
    "fieldLabel": "Appointment Time",
    "fieldMinValue": null,
    "fieldMaxValue": null,
    "fieldDisplayWidth": 300,
    "fieldDisplayHeight": 30,
    "fieldParmCodeListID": 0,
    "fieldDisplayControlType": null,
    "fieldEditMask": null,
    "fieldMaskType": null,
    "fieldValue": null,
    "fieldValueID": 0,
    "fieldMaxLength": null,
    "fieldUpdated": false,
    "fieldMandatoryCaseTypes": null,
    "fieldMandatoryMessage": null,
    "fieldKeyField": false,
    "fieldURLString": null,
    "detailsIndex": 1,
    "fieldCalculation": null,
    "hiddenField": false,
    "fieldReadOnly": false
  },
  {
    "id": 22,
    "fieldType": "Text",
    "fieldName": "InjuryDetails",
    "fieldLabel": "Injury Details",
    "fieldMinValue": null,
    "fieldMaxValue": null,
    "fieldDisplayWidth": 850,
    "fieldDisplayHeight": 1050,
    "fieldParmCodeListID": 0,
    "fieldDisplayControlType": null,
    "fieldEditMask": "^[A-Za-z]{1,2}[0-9Rr][0-9A-Za-z]? [0-9][ABD-HJLNP-UW-Zabd-hjlnp-uw-z]{2}$",
    "fieldMaskType": "RegEx",
    "fieldValue": null,
    "fieldValueID": 0,
    "fieldMaxLength": "2048",
    "fieldUpdated": false,
    "fieldMandatoryCaseTypes": null,
    "fieldMandatoryMessage": null,
    "fieldKeyField": false,
    "fieldURLString": null,
    "detailsIndex": 1,
    "fieldCalculation": null,
    "hiddenField": false,
    "fieldReadOnly": false
  },
   {
    "id": "1",
    "label": "Forename(s)",
    "variant": "standard",
    "type": "text",
    "fieldMinValue": "NULL",
    "fieldMaxValue": "NULL",
    "width": 200,
    "sequence": 1,
    "fieldReadOnly": false,
    "value": "abdullah",
    "fieldMaskType": "regex",
    "regexPattern": "^[A-Za-z]{1,2}[0-9Rr][0-9A-Za-z]? [0-9][ABD-HJLNP-UW-Zabd-hjlnp-uw-z]{2}$"
  },
  {
    "id": "2",
    "label": "Surname",
    "variant": "filled",
    "type": "text",
    "fieldMinValue": "NULL",
    "fieldMaxValue": "NULL",
    "sequence": 2,
    "fieldMaskType": "regex",
    "regexPattern": "^b.*"
  },
  {
    "id": "av",
    "label": "Surname",
    "variant": "filled",
    "type": "text",
    "fieldMinValue": "NULL",
    "fieldMaxValue": "NULL",
    "sequence": 3,
    "fieldMaskType": "regex",
    "regexPattern": "^c.*"
  },
  {
    "id": "3",
    "label": "Gender",
    "variant": "outlined",
    "type": "dropdown",
    "fieldMinValue": "NULL",
    "fieldMaxValue": "NULL",
    "options": ["Male", "Female", "Other"],
    "sequence": 4,
    "fieldReadOnly": true
  },
  {
    "id": "4",
    "label": "Comments",
    "variant": "standard",
    "type": "textarea",
    "fieldMinValue": "NULL",
    "fieldMaxValue": "NULL",
    "sequence": 3
  },
  {
    "id": "5",
    "label": "Abdullah",
    "type": "checkbox",
    "fieldMinValue": "NULL",
    "fieldMaxValue": "NULL",
    "sequence": 5
  }, {
    "id": "6",
    "label": "Date",
    "type": "datepicker",
    "fieldMinValue": "NULL",
    "fieldMaxValue": "NULL",
    "sequence": 7
  }, , {
    "id": "7",
    "label": "Number",
    "type": "numeric",
    "variant": "standard",
    "fieldMinValue": "NULL",
    "fieldMaxValue": "NULL",
    "sequence": 6,
    "fieldMaskType": "regex",
    "regexPattern": "n2"
  },
  // Add more field objects...
]
export default fieldData;