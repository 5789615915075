import React, { useState ,useEffect} from 'react'
import styled from 'styled-components';
import Modal from '../../components/modals/custom-modal';
import Button from '../../components/controls/button';
import Dropdown from '../../components/controls/drop-down';
import PersonPersonalDetail from '../../components/dynamic-entity/maintain-person.jsx/person-personal-detail';
import PersonAddressDetail from '../../components/dynamic-entity/maintain-person.jsx/person-address-detail';
import PersonContactDetail from '../../components/dynamic-entity/maintain-person.jsx/person-contact-detail';
import PersonNoteDetail from '../../components/dynamic-entity/maintain-person.jsx/person-note-detail';
export default function MaintainPersonView({hide,isNewPerson,entityName}) {
    const [isDisabled , setIsDisabled] = useState(false);
    const handleDisabled = () => {
        // Enable the components when the "Suffix" input field is blurred
        setIsDisabled(false);
      };
    
    useEffect(() => {
        // This code will run only once when the component is mounted
        if (isNewPerson) {
          setIsDisabled(true);
        }
      }, []); // Empty dependency array means it only runs once on mount
    return (
        <div>
            <Modal title="Amend Expert Details " className="modal-dialog  modal-xl " hide={hide} >
                <div className="row">
                    <div className='col-lg-6 col-md-6 col-12'>
                        <Button type='submit' size='medium' variant='contained' name='Save & Exit' fullWidth={false} onClick={hide}></Button>
                    </div>
                    <div className='col-lg-6 col-md-6 col-12'>
                        <Dropdown label="Status" id="status" disabled={isDisabled}></Dropdown>
                    </div>
                </div>
                <div className="row">
                    <div className='col-lg-6 col-md-6 col-12'>
                        <Container>
                            <PersonPersonalDetail onClick={handleDisabled} entityName={entityName} isNewPerson={isNewPerson}></PersonPersonalDetail>
                            <PersonAddressDetail isDisabled={isDisabled}></PersonAddressDetail>
                            <PersonContactDetail isDisabled={isDisabled}></PersonContactDetail>
                        </Container>
                    </div>
                    <div className='col-lg-6 col-md-6 col-12'>

                        <Container>
                            <PersonNoteDetail isDisabled={isDisabled}></PersonNoteDetail>
                        </Container>
                    </div>
                </div>
            </Modal>
        </div>
    )
}
const Container = styled.div`
margin: 10px;
border: 1px solid #ddd;
border-radius: 5px;
padding: 10px 10px 10px 10px;

height:600px;
overflow-y: auto;
::-webkit-scrollbar {
width: 8px;
background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
background-color: #888;
border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
background-color: #555;
}
`;