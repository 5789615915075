import React from 'react'
import styled from 'styled-components'
import AddressDetail from '../../components/dynamic-entity/maintain-organization/address-detail'
import OtherDetail from '../../components/dynamic-entity/maintain-organization/other-detail'
import Modal from '../../components/modals/custom-modal'
import Button from '../../components/controls/button'
import NoteDetail from '../../components/dynamic-entity/maintain-organization/note-detail'
import PersonalDetail from '../../components/dynamic-entity/maintain-organization/personal-detail'
import MaintainContactDetail from '../../components/dynamic-entity/maintain-organization/contact-detail'
export default function MaintainOrganizationView({hide ,organisationData,personData}) {
    return (
        <div>
            <Modal title="Amend Court Details " className="modal-dialog  modal-xl " hide={hide} >
            <Button type='submit' size='medium' variant='contained' name='Save & Exit' onClick={hide} fullWidth={false} ></Button>
                <div className="row">
                    <div className='col-lg-6 col-md-6 col-12'>
                        <Container>
                            <AddressDetail organisationData={organisationData} ></AddressDetail>
                            <OtherDetail organisationData={organisationData}></OtherDetail>
                            <NoteDetail organisationData={organisationData}></NoteDetail>
                        </Container>
                    </div>
                    <div className='col-lg-6 col-md-6 col-12'>
                        <Container>
                            <h6>Organization Contacts</h6>
                            <hr></hr>
                            <PersonalDetail personData={personData}></PersonalDetail>
                            <MaintainContactDetail></MaintainContactDetail>
                        </Container>
                    </div>
                </div>
            </Modal>
        </div>
    )
}
const Container = styled.div`
margin: 10px;
border: 1px solid #ddd;
border-radius: 5px;
padding: 10px 10px 10px 10px;

height:600px;
overflow-y: auto;
::-webkit-scrollbar {
width: 8px;
background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
background-color: #888;
border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
background-color: #555;
}
`;