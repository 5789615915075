import React,{useEffect,useState} from 'react'
import CaseDatesGrid from '../../components/data-grid/case-date-grid/case-dates-grid'
import { useAuth } from '../../hooks/useAuth'
import { mainCaseDate } from '../../utils/constants/application-areas';
import { caseDates } from '../../services/task-service';
export default function CaseDateView({caseID}) {
    const [caseDateData, setCaseDateData] = useState([]);
    const { user } = useAuth();
    const userID = user.id;
    const key = userID+ mainCaseDate;
    useEffect(() => {
        CallCaseDateApi();
      }, []);
      const CallCaseDateApi = async () => {
        try {
          const casedates = await caseDates(caseID);
          setCaseDateData(casedates.data.results)
        }
        catch (error) {
         console.error(error);
        }
      };
    return (
            <CaseDatesGrid caseDateData={caseDateData} storeKey={key}></CaseDatesGrid>
    )
}
