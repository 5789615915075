import React from 'react'
import MaterialButton from '@mui/material/Button';
import styled from 'styled-components';

export default function Button({ variant, size, type, name, onClick,fullWidth }) {
    return (
        <div>
            <MaterialButton variant={variant} size={size} fullWidth={fullWidth} type={type} onClick={onClick} ><Captilize>{name}</Captilize> </MaterialButton>
        </div>
    )
}
const Captilize = styled.div`
    text-transform: capitalize;
`;