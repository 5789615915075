// Redux slice for managing modal-related state, including the currently open modal, mode, and associated data

import { createSlice } from "@reduxjs/toolkit";

// Initial state for the modal slice
const initialState = {
  modalName: "", // Name of the currently open modal
  mode: "",      // Mode of the current modal (e.g., "add", "edit", "delete")
  data: null,    // Additional data associated with the modal
};

// Redux slice for modal state management
export const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    // Action to open a modal with the specified name, mode, and optional data
    openModal: (state, action) => {
      const { modalName, mode, data } = action.payload;
      state.modalName = modalName;
      state.mode = mode;
      state.data = data;
    },
    // Action to close the currently open modal
    closeModal: (state) => {
      state.modalName = "";
      state.mode = "";
      state.data = null;
    },
  },
});

// Exporting the action creators
export const { openModal, closeModal } = modalSlice.actions;

// Exporting the reducer
export default modalSlice.reducer;
