import React from 'react'
import LookUpList from '../../controls/lookuplist';
import { Column} from 'devextreme-react/data-grid';
export default function OrganizationGrid({ data }) {
    return (
        <div>
            <LookUpList data={data}>
                <Column dataField="id" caption="ID" dataType="string" visible={false} />
                <Column dataField="typeOfOrganisation" caption="Organization Type" dataType="string" visible={false} />
                <Column dataField="name" caption="Name" dataType="string"  />
                <Column dataField="houseID" caption="House ID" dataType="string"  />
                <Column dataField="street1" caption="Street 1" dataType="string"  />
                <Column dataField="street2" caption="Street 2" dataType="string" visible={false} />
                <Column dataField="district" caption="District" dataType="string" visible={false} />
                <Column dataField="town" caption="Town" dataType="string"  />
                <Column dataField="county" caption="Region" dataType="string"/>
                <Column dataField="country" caption="Country" dataType="string" />
                <Column dataField="postcode" caption="Postcode" dataType="string"  />
                <Column dataField="phone" caption="Phone" dataType="string" visible={false} />
                <Column dataField="fax" caption="Fax" dataType="string" visible={false} />
                <Column dataField="homePage" caption="Home Page" dataType="string" visible={false} />
                <Column dataField="vatNo" caption="VAT Number" dataType="string" visible={false}/>
                <Column dataField="notes" caption="Notes" dataType="string" visible={false}/>
                <Column dataField="subType" caption="Sub Type" dataType="string" />
                <Column dataField="alternativeID" caption="Alternative ID" dataType="string" />
                <Column dataField="alternativeID2" caption="Alternative ID 2" dataType="string" visible={false}/>
                <Column dataField="emailAddress" caption="Email Address" dataType="string" />
                <Column dataField="status" caption="Status" dataType="string" />
                <Column dataField="groupCode" caption="Group Code" dataType="string" />
                <Column dataField="groupDescription" caption="Group Description" dataType="string" visible={false}/>
                <Column dataField="dxNumber" caption="DX Number" dataType="string" />
                <Column dataField="dxExchange" caption="DX Exchange" dataType="string" />
                <Column dataField="secureID" caption="Secure ID" dataType="string" />
                <Column dataField="contactIDs" caption="Contact IDs" dataType="string" visible={false}/>
                <Column dataField="addressBlock" caption="Address Block" dataType="string" visible={false} />
                <Column dataField="entityLabel" caption="Entity Description" dataType="string"/>
            </LookUpList>
        </div>
    )
}
