import { httpClient } from "./httpclient";
const url = "v1/task";

const userTask = async (userID, withinDays) => {
    try {
        const endpoint = `${url}/user/${userID}?thisWeek=${withinDays}`;
        return await httpClient.get(endpoint);
    } catch (error) {
        throw new Error(error.response.status);
    }
};
const taskByID = async (id) => {
    try {
        const endpoint = `${url}/${id}`;
        return await httpClient.get(endpoint);
    } catch (error) {
        throw new Error(error.response.status);
    }
};
const taskAssigneeList = async () => {
    try {
        const endpoint = `${url}/assigneelist`;
        return await httpClient.get(endpoint);
    } catch (error) {
        throw new Error(error.response.status);
    }
};
const caseTask = async (caseID, includeDeletedCompleted) => {
    try {
        const endpoint = `${url}/case/${caseID}?includeDeletedCompleted=${includeDeletedCompleted}`;
        return await httpClient.get(endpoint);
    } catch (error) {
        throw new Error(error.response.status);
    }
};
const limitation = async () => {
    try {
        const endpoint = `${url}/limitation`;
        return await httpClient.get(endpoint);
    } catch (error) {
        throw new Error(error.response.status);
    }
};
const portalDateLimit = async (userID) => {
    try {
        const endpoint = `${url}/portaldatelimits`;
        return await httpClient.get(endpoint);
    } catch (error) {
        throw new Error(error.response.status);
    }
};
const notification = async () => {
    try {
        const endpoint = `${url}/portalnotifications`;
        return await httpClient.get(endpoint);
    } catch (error) {
        throw new Error(error.response.status);
    }
};
const postComplete = async (id) => {
    try {
        const endpoint = `${url}/complete/${id}`;
        return await httpClient.post(endpoint);
    } catch (error) {
        throw new Error(error.response.status);
    }
};
const postUnComplete = async (id) => {
    try {
        const endpoint = `${url}/uncomplete/${id}`;
        return await httpClient.post(endpoint);
    } catch (error) {
        throw new Error(error.response.status);
    }


};
const updateTask = async (id, status, type, userID, caseID, task, priority, startDate, dueDate, completedDate, completedUserID, isOverdue, isAutoReminder, reminderDate, clientName, expireOnOpen, deleted, deletedTime, deletedUserID, appointmentID, customDateID, sharedCalendars, appointmentNotes, addedTime, addedUserID, teamID, linkedReference, linkedDescription) => {
    var request = {
        id: id,
        status: status,
        type: type,
        userID: userID,
        caseID: caseID,
        task: task,
        priority: priority,
        startDate: startDate,
        dueDate: dueDate,
        completedDate: completedDate,
        completedUserID: completedUserID,
        isOverdue: isOverdue,
        isAutoReminder: isAutoReminder,
        reminderDate: reminderDate,
        clientName: clientName,
        expireOnOpen: expireOnOpen,
        deleted: deleted,
        deletedTime: deletedTime,
        deletedUserID: deletedUserID,
        appointmentID: appointmentID,
        customDateID: customDateID,
        sharedCalendars: sharedCalendars,
        appointmentNotes: appointmentNotes,
        addedTime: addedTime,
        addedUserID: addedUserID,
        teamID: teamID,
        linkedReference: linkedReference,
        linkedDescription: linkedDescription
    };
    const endpoint = `${url}/update`;
    return await httpClient.post(endpoint, request);

};
const caseDates = async (caseID) => {
    try {
        const endpoint = `${url}/casedates/${caseID}`;
        return await httpClient.get(endpoint);
    } catch (error) {
        throw new Error(error.response.status);
    }
};
const sharedCalendarTask = async (sharedCalendar) => {
    try {
        const endpoint = `${url}/shared/${sharedCalendar}`;
        return await httpClient.get(endpoint);
    } catch (error) {
        throw new Error(error.response.status);
    }
};
export { userTask, limitation, portalDateLimit, notification, caseTask, postComplete, postUnComplete, taskByID, taskAssigneeList, updateTask, caseDates ,sharedCalendarTask};