const Casetype = [
    {
        id : 1,
        name : "Conveyancing"

    },
    {
        id : 2,
        name : "Crime"

    },
    {
        id : 3,
        name : "Personal Injury"

    }

]
export default Casetype;