export const defendant = [{
    ID: 1,
    Name: 'defendant letters',
    Price: 330,
    Current_Inventory: 225,
    Backorder: 0,
    Manufacturing: 10,
    Category: 'Video Players',
    ImageSrc: 'images/products/1.png',
  }, {
    ID: 3,
    Name: 'defendant close letter',
    Price: 2400,
    Current_Inventory: 0,
    Backorder: 0,
    Manufacturing: 0,
    Category: 'Televisions',
    ImageSrc: 'images/products/3.png',
  }
    
  ];
  export const client = [{
    ID: 1,
    Name: 'client letters',
    Price: 330,
    Current_Inventory: 225,
    Backorder: 0,
    Manufacturing: 10,
    Category: 'Video Players',
    ImageSrc: 'images/products/1.png',
  }, {
    ID: 3,
    Name: 'client close letter',
    Price: 2400,
    Current_Inventory: 0,
    Backorder: 0,
    Manufacturing: 0,
    Category: 'Televisions',
    ImageSrc: 'images/products/3.png',
  }
    
  ];
  export const Case = [{
    ID: 1,
    Name: 'Case letters',
    Price: 330,
    Current_Inventory: 225,
    Backorder: 0,
    Manufacturing: 10,
    Category: 'Video Players',
    ImageSrc: 'images/products/1.png',
  }, {
    ID: 3,
    Name: 'Case close letter',
    Price: 2400,
    Current_Inventory: 0,
    Backorder: 0,
    Manufacturing: 0,
    Category: 'Televisions',
    ImageSrc: 'images/products/3.png',
  }
    
  ];
  
  

  