import React, { useState, useEffect } from 'react'
import Modal from './custom-modal'
import TextInput from '../controls/textinput'
import CheckBox from '../controls/checkbox'
import Dropdown from '../controls/drop-down'
import Button from '../controls/button'
import TextArea from '../controls/textarea'
import { deleteHistory } from '../../services/history-service'
import styled from 'styled-components'
import TaskForm from './task-form-modal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { useAuth } from '../../hooks/useAuth'
import TimeFieldValue from '../controls/time-field'
import DateTimePickerView from '../controls/datetime-picker-view'
import TimePickerValue from '../controls/timepicker'
import { ADD, DELETE } from '../../utils/constants/mode'
import { updateHistory } from '../../services/history-service'
import DropDownFilter from '../controls/dropdownfilter'
import CaseTaskView from '../../views/case-detail/case-task-view'
import { unlockCase } from '../../services/case-service'
import { ModalAnimation } from '../../utils/styles/animation-styles'
import { SAVE_HISTORY } from '../../utils/constants/modal-name'
import { useDispatch } from 'react-redux'
import { closeModal } from '../features/modal-slice'
import { triggerAlert } from '../features/alert-slice'
import { resetTimer } from '../features/timer-slice'
export default function HistoryForm({ paramcaseStatus, paramCaseID, paramUserID, paramNoOfUnit, paramCost, paramTotalSeconds, mode, title, editHistoryData, billingCategory1Option, billingCategory2Option, billingTypeOfWorkOption, userListOption, icon, taskAssigneeOption, standardTaskOption, taskStatusOption, priorityOption, paramStartTime, paramTypeOfEntry }) {
    const [id, setId] = useState(0);
    const [caseID, setCaseID] = useState(paramCaseID);
    const [feeEarnerID, setFeeEarnerID] = useState(paramUserID);
    const [timeStamp, setTimeStamp] = useState(new Date().toISOString());
    const [noOfUnits, setNoOfUnits] = useState(paramNoOfUnit);
    const [startTime, setStartTime] = useState(paramStartTime);
    const [endTime, setEndTime] = useState(new Date().toISOString());
    const [narrative, setNarrative] = useState("");
    const [typeofEntry, setTypeOfEntry] = useState(paramTypeOfEntry);
    const [cost, setCost] = useState(paramCost || 0.0000);
    const [fileName, setFileName] = useState(null);
    const [branchID, setBranchID] = useState(0);
    const [branch, setBranch] = useState("");
    const [quantity, setQuantity] = useState(0.0000);
    const [unitCost, setUnitCost] = useState(0.0000);
    const [caseStatusAtTime, setCaseStatusAtTime] = useState(paramcaseStatus);
    const [subFolder, setSubFolder] = useState(null);
    const [activeTimeSeconds, setActiveTimeSeconds] = useState(paramTotalSeconds);
    const [elapsedTimeSeconds, setElapsedTimeSeconds] = useState(paramTotalSeconds);
    const [fixedFeeEntry, setFixedFeeEntry] = useState(true);
    const [typeOfWork, setTypeOfWork] = useState(null);
    const [category1, setCategory1] = useState(null);
    const [category2, setCategory2] = useState(null);
    const [parmCharcategory1, setParmCharcategory1] = useState("");
    const [beAMail, setBeAMail] = useState(false);
    const [filteredBillingCategory2Option, setFilteredBillingCategory2Option] = useState(billingCategory2Option);
    const [disabledCategory2, setDisabledCategory2] = useState(true);
    const [showAddTask, setAddTask] = useState(false)
    const { user } = useAuth();
    const userID = user.id;
    const key = userID + "FrmEditScreenHistory";
    const dispatch = useDispatch()
    useEffect(() => {
        if (editHistoryData) {
            setId(editHistoryData.id);
            setCaseID(editHistoryData.caseID);
            setFeeEarnerID(editHistoryData.feeEarnerID);
            setTimeStamp(editHistoryData.timeStamp);
            setNoOfUnits(editHistoryData.noOfUnits);
            setStartTime(editHistoryData.startTime);
            setEndTime(editHistoryData.endTime);
            setNarrative(editHistoryData.narrative);
            setTypeOfEntry(editHistoryData.typeofEntry);
            setCost(editHistoryData.cost);
            setFileName(editHistoryData.fileName);
            setBranchID(editHistoryData.branchID);
            setBranch(editHistoryData.branch);
            setQuantity(editHistoryData.quantity);
            setUnitCost(editHistoryData.unitCost);
            setCaseStatusAtTime(editHistoryData.caseStatusAtTime);
            setSubFolder(editHistoryData.subFolder);
            setActiveTimeSeconds(editHistoryData.activeTimeSeconds);
            setElapsedTimeSeconds(editHistoryData.elapsedTimeSeconds);
            setFixedFeeEntry(editHistoryData.fixedFeeEntry);
            setTypeOfWork(editHistoryData.typeOfWork);
            setCategory1(editHistoryData.category1);
            setCategory2(editHistoryData.category2);
            setBeAMail(editHistoryData.beAMail);
        }
    }, []);
    const handleInputChange = (event, setStateFunction) => {
        const { value } = event.target;
        setStateFunction(value);
    };
    const handleDropDownChange = (data, setStateFunction) => {
        if (data.id) {
            setStateFunction(data.id);
        } else if (data.parameterID) {
            setStateFunction(data.parameterID);
        }
    };
    const handleCategory1Change = (data, setStateFunction) => {
        if (data.parameterID) {
            setDisabledCategory2(false);
            setStateFunction(data.parameterID);
            setCategory2("");
            setFilteredBillingCategory2Option(DropDownFilter(billingCategory2Option, data.parameterID))
            setParmCharcategory1(data.parmChar);
        }
    };
    const handleCategory2Change = (data, setStateFunction) => {
        if (data.parameterID) {
            setStateFunction(data.parameterID);
            setNarrative(`${parmCharcategory1}-${data.parmChar}`);
        }
    };
    const handleChecboxChange = (event, setStateFunction) => {
        const { checked } = event.target;
        setStateFunction(checked);
    };
    const handleDateChange = (event, setStateFunction) => {
        const parsedDate = new Date(event);
        const formattedDate = parsedDate.toISOString();
        setStateFunction(formattedDate);
    };
    function getMessageForMode(mode) {
        if (mode === ADD) {
            return "Inserted Successfully";
        } else if (mode === DELETE) {
            return "Deleted Successfully";
        } else {
            // Assuming any other case should be treated as an update
            return "Updated Successfully";
        }
    }
    const CallUpdateHistoryAPI = async () => {
        try {
            const updateHistoryResponce = await updateHistory(
                id,
                caseID,
                feeEarnerID,
                timeStamp,
                noOfUnits,
                startTime,
                endTime,
                narrative,
                typeofEntry,
                cost,
                fileName,
                branchID,
                quantity,
                unitCost,
                caseStatusAtTime,
                subFolder,
                activeTimeSeconds,
                elapsedTimeSeconds,
                fixedFeeEntry,
                typeOfWork,
                category1,
                category2,
                beAMail
            );
            if (updateHistoryResponce.data.returnCode == 1) {
                closeHistoryModal();
                let message = `History ${getMessageForMode(mode)}`;
                dispatch(triggerAlert({ openAlert: true, severity: "success", alertMessage: message }))
                if (mode === ADD) {
                    if (title === SAVE_HISTORY) {
                        CallUnlockCaseApi();
                    }
                    else {
                        dispatch(resetTimer({ resetTime: true }))
                    }
                }
            }
        }
        catch (error) {
            console.error(error);
        }
    };
    const CallUnlockCaseApi = async () => {
        try {
            await unlockCase(caseID);
            window.open('', '_self').close();
        }
        catch (error) {
            console.error(error);
        }
    };
    const CallDeleteHistoryAPI = async () => {
        try {
            const deleteHistoryResponce = await deleteHistory(id);
            if (deleteHistoryResponce.data.returnCode == 1) {
                closeHistoryModal();
                let message = `History ${getMessageForMode(mode)}`;
                dispatch(triggerAlert({ openAlert: true, severity: "success", alertMessage: message }))
            }
        }
        catch (error) {
            console.error(error);
        }
    };
    const handleFormSubmit = (event) => {
        if (mode === DELETE) {
            CallDeleteHistoryAPI();
        } else {
            CallUpdateHistoryAPI();
        }
        event.preventDefault();
    };
    const closeHistoryModal = () => {
        dispatch(closeModal())
    };
    return (
        <ContainerWrapper>
            <Modal icon={icon} handleFormSubmit={handleFormSubmit} className="modal-dialog modal-xl" hide={closeHistoryModal} title={title} footer={
                <>
                    <Button size='medium' variant='outlined' name='Close' onClick={closeHistoryModal} ></Button>
                    <Button type='submit' size='medium' variant='contained' name={mode} ></Button>
                    <div className='container'>
                        {title === SAVE_HISTORY ?
                            <>
                                <Button size='medium' variant='contained' name="Add Case Task" onClick={() => setAddTask(true)}></Button>
                                <NavWrapper >
                                    <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                        <button className="nav-link active" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">Task</button>
                                        <button className="nav-link" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">History</button>
                                    </div>
                                </NavWrapper>
                                <div className="tab-content" id="nav-tabContent">
                                    <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab"><CaseTaskView caseID={caseID} storeKey={key}></CaseTaskView></div>
                                    <div className="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab"></div>
                                </div>
                            </>
                            : null}
                    </div>
                </>
            }>
                <div className='container'>
                    <div className='row g-3'>
                        <div className='col-lg-4 col-4'>
                            <TextInput label="Case ID" variant="standard" readOnly={true} value={caseID}></TextInput>
                        </div>
                        <div className='col-lg-4 col-4'>
                            <Dropdown label="User" options={userListOption} value={feeEarnerID} dropDownType="user" customPropertyName="id" displayPropertyName="forename" displayPropertyName2="surname" onChange={(event) => handleDropDownChange(event, setFeeEarnerID)}></Dropdown>
                        </div>
                        <div className='col-lg-4 col-4'>
                            <DateTimePickerView label="Time Stamp" id="time-stamp" value={timeStamp} onChange={(event) => handleDateChange(event, setTimeStamp)}></DateTimePickerView>
                        </div>
                        <div className='col-lg-3 col-3'>
                            <TimeFieldValue label="Start Time" variant="standard" value={startTime} readOnly={true}></TimeFieldValue>
                        </div>
                        <div className='col-lg-3 col-3'>
                            <TimeFieldValue label="End Time" variant="standard" value={endTime} readOnly={true}></TimeFieldValue>
                        </div>
                        <div className='col-lg-3 col-3'>
                            <TextInput label="Number of Units" variant="standard" readOnly={true} value={noOfUnits}></TextInput>
                        </div>
                        <div className='col-lg-3 col-3'>
                            <CheckBox label="Fixed Fee Entry" checked={fixedFeeEntry} onChange={(event) => handleChecboxChange(event, setFixedFeeEntry)}></CheckBox>
                        </div>
                        <div className='col-lg-3 col-3'>
                            <TimePickerValue label="Time" variant="standard" value={elapsedTimeSeconds}></TimePickerValue>
                        </div>
                        <div className='col-lg-3 col-3'>
                            <TextInput label="Quantity" variant="standard" value={quantity} type="number" onChange={(event) => handleInputChange(event, setQuantity)}></TextInput>
                        </div>
                        <div className='col-lg-3 col-3'>
                            <Dropdown label="Type Of Work" value={typeOfWork} customPropertyName="parameterID" displayPropertyName="parmChar" options={billingTypeOfWorkOption} onChange={(event) => handleDropDownChange(event, setTypeOfWork)} ></Dropdown>
                        </div>
                        <div className='col-lg-3 col-3'>
                            <TextInput label="Cost" variant="standard" value={cost.toFixed(2)} readOnly={true}></TextInput>
                        </div>
                        <div className='col-lg-6 col-6'>
                            <Dropdown label="Category 1" value={category1} customPropertyName="parameterID" displayPropertyName="parmChar" options={billingCategory1Option} onChange={(event) => handleCategory1Change(event, setCategory1)} ></Dropdown>
                        </div>
                        <div className='col-lg-6 col-6'>
                            <Dropdown label="Category 2" value={category2} disabled={disabledCategory2} options={filteredBillingCategory2Option} customPropertyName="parameterID" displayPropertyName="parmChar" onChange={(event) => handleCategory2Change(event, setCategory2)} ></Dropdown>
                        </div>
                        <div className='col-lg-12 col-12'>
                            <TextArea label="Narrative" row="3" value={narrative} required={true} onChange={(event) => handleInputChange(event, setNarrative)}></TextArea>
                        </div>
                    </div>
                </div>
            </Modal>
            {showAddTask ? <TaskForm icon={<FontAwesomeIcon icon={faPlusCircle} />} taskStatusOption={taskStatusOption} priorityOption={priorityOption} taskAssigneeOption={taskAssigneeOption} standardTaskOption={standardTaskOption} name="Add Task" mode="addTask" hide={() => setAddTask(false)} user={userID} /> : null}
        </ContainerWrapper>
    )
} const NavWrapper = styled.div`
margin-top: 10px;
`;
const ContainerWrapper = styled.div`
    ${ModalAnimation}
`;