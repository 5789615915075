import React from 'react'
import styled from 'styled-components';
import TextInput from '../../../controls/textinput';
export default function CaseReference() {
    return (
        <div>
            <DivWrapper >
                <h6>Case Reference</h6>
                <hr></hr>
                <div className="row g-3">
                    <div className='col-lg-12'>
                        <TextInput  id="caseReference" variant="standard"></TextInput>
                    </div>
                </div>
            </DivWrapper>
        </div>
    )
}
const DivWrapper = styled.div`
  margin: 10px ;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px 20px 10px 20px;
    `;
