import React ,{useState}from 'react'
import TextArea from '../components/controls/textarea';
import TextInput from '../components/controls/textinput';
import Dropdown from '../components/controls/drop-down';
import CheckBox from '../components/controls/checkbox';
import Datepicker from '../components/controls/datepicker';
import TimeInput from '../components/controls/timeinput';
import LookUpList from '../components/controls/lookuplist';
import {textInput, dropDown, textArea, checkBox, datePicker, numeric , time ,lookUpList} from '../utils/constants/field-type';
export default function useDynamicField({ apiData, handleValidation, handleDecimal, fieldValidity, decimalValue }) {
  const [inputValue, setInputValue] = useState(apiData.fieldValue|| "");
   const idAsString = apiData.id.toString()
  const handleChange = (event) => {
    setInputValue(event.target.value);
  };
  const  handleValidationAndChange = (e) => {
     handleValidation(e, apiData)
    handleChange(e); // Call handleChange function

  
};
  const { fieldType } = apiData;
    switch (fieldType) {
      case textInput:

        return (
          <TextInput
            label={apiData.fieldLabel}
            id={idAsString}
            variant="standard"
            value= {inputValue}
            disabled={apiData.fieldReadOnly}
            width={apiData.width + 'px'}
            onChange={handleValidationAndChange }
            error={!fieldValidity} // Use the specific valid state for the field
            helperText={!fieldValidity && "Invalid input"} // Show error message based on valid state
            regexPattern={apiData.fieldEditMask}

          />
        );
      case dropDown:
        return (
          <Dropdown
            label={apiData.fieldLabel}
            id={idAsString}
            variant="standard"
            disabled={apiData.fieldReadOnly}
          />
        );
      case textArea:
        return (
          <TextArea
            label={apiData.fieldLabel}
            id={idAsString}
            value= {inputValue}
            onChange={handleChange }
            variant="standard"
            row="6"
          />
        );
      case checkBox:
        return (
          <CheckBox
            label={apiData.fieldLabel}
          />
        );
      case datePicker:
        return (
          <Datepicker
            label={apiData.fieldLabel}
            id={idAsString}
           

          />
        );
      case numeric:
        return (
          <TextInput
            label={apiData.fieldLabel}
            id={idAsString}
            type="number"
            variant="standard"
            onChange={(e) => handleDecimal(e, apiData)}
            value={decimalValue}
          />
        );
        case time:
        return (
          <TimeInput
            label={apiData.fieldLabel}
            variant="standard"
  
          />
        );
        case lookUpList:
          return (
            <LookUpList></LookUpList>
          );

      default:
        return null;
    }
}
