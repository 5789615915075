import React from "react";
import styled from "styled-components";

var date = new Date();
var currentYear = date.getFullYear();
function LoginFooter({ content }) {
  return (
    <>
      <p>{content.termsText}</p>
      {content.displayEmailInHeader && (
        <p>
          <i className="fas fa-envelope me-3"></i>
          {content.email}
        </p>
      )}
      {content.displayContactNumberInHeader && (
        <p>
          <i className="fas fa-phone me-3"></i>
          {content.contactNumber}
        </p>
      )}
      {content.displayURLInHeader && (
        <p>
          <i className="fas fa-globe me-3"></i>
          {content.url}
        </p>
      )}
      © {currentYear} Copyright:
      <a
        className="text-text-reset fw-bold ms-2"
        href="http://www.derbydatabases.com/"
      >
        Derby Databases
      </a>
    </>
  );
}
export default LoginFooter;
