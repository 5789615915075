import React from 'react'
import CustomGrid from '../controls/grid';
import { STDRates } from '../json/std-rates';
import { Column, FilterRow, HeaderFilter, Editing } from 'devextreme-react/data-grid';
import { useScreenSize } from '../../hooks/useScreenSize';
import { Mobile } from '../../utils/constants/devices';
export default function NonSTDRates() {
  const device = useScreenSize();
let mobile;
if (device === Mobile) {
  mobile = true;
} else {
  mobile = false
}
  return (
    <div>
      <CustomGrid data={STDRates} id="gridContainer">
        <HeaderFilter visible={true} />
        <Editing mode="cell" allowAdding={true} allowUpdating={true} />
        <FilterRow visible={!mobile} />
        <Column caption="User ID" dataType="string" />
        <Column caption="Charging Rates" dataType="string" />
      </CustomGrid>
    </div>
  )
}
