
import { createSlice } from "@reduxjs/toolkit";
const initialState = {
    openWarning: false,
    data:null
};

// Redux slice for warning state management
export const warningController = createSlice({
    name: "warning",
    initialState,
    reducers: {
        // Action to trigger an warnimg with specified properties
        triggerWarning: (state, action) => {
            const { openWarning, data } = action.payload;
            state.openWarning = openWarning;
            state.data = data;
        },
        // Action to close the warning
        closeWarning: (state) => {
            state.openWarning = false;
            state.data = null;
        },
    },
});

// Exporting the action creators
export const { triggerWarning, closeWarning } = warningController.actions;

// Exporting the reducer
export default warningController.reducer;
