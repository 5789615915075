import * as React from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimeField } from '@mui/x-date-pickers/TimeField';
export default function TimeFieldValue({value ,label, variant,disabled ,readOnly}) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
    
        
        <TimeField
          label={label}
          value={value}
          variant={variant}
          format="HH:mm:ss a"
          disabled={disabled}
          readOnly={readOnly}
          fullWidth
        />

    </LocalizationProvider>
  );
}
