import React from 'react'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs from 'dayjs'; // Import the dayjs librar

export default function DateTimePickerView({ id, label, value, onChange, readOnly, required, use12HourFormat }) {
  let parsedValue = null;
  if (value) {
    parsedValue = dayjs(value); // Convert the string to a Dayjs object if value is not null
  }
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateTimePicker
        id={id}
        label={label}
        viewRenderers={{
          hours: null,
          minutes: null,
          seconds: null,
        }}
        slotProps={{ textField: { variant: 'standard', fullWidth: true, size: 'small', required: required } }}   // 
        value={parsedValue}
        onChange={onChange}
        readOnly={readOnly}
        ampm={use12HourFormat}

      />
    </LocalizationProvider>

  )
}
