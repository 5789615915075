import React, { useEffect, useState } from 'react'
import Modal from '../custom-modal'
import Button from '../../controls/button'
import { useAuth } from '../../../hooks/useAuth';
import { useSelector, useDispatch } from 'react-redux';
import { closeWarning } from '../../features/warning-modal-slice';
import { ModalAnimation } from '../../../utils/styles/animation-styles';
import styled from 'styled-components';
export default function CaseDetailAlert() {
  const [alertMessage, setAlertMessage] = useState("");
  const [title, setTitle] = useState("");
  const [handleLimitedModeClick, setHandleLimitedModeClick] = useState(false);
  const [handleLockUser, setHandleLockUser] = useState(false);
  const { user } = useAuth();
  const userID = user.id;
  const { openWarning, data } = useSelector((state) => state.warning);
  const dispatch = useDispatch();
  useEffect(() => {
    const returnCode = data?.returnCode;
    const lockUserID = data?.lockUser;

    switch (returnCode) {
      case -9:
        if (userID === lockUserID) {
          setAlertMessage(`You already have this case open. Please switch to the case window to resume working on it`)
          setHandleLockUser(true);
        } else {
          setAlertMessage(`This case is currently being used by user ${lockUserID} Would you like to open the case in LIMITED UPDATE MODE?`)
          setTitle("Case being edited by another user.Open in LIMITED UPDATE MODE?");
          setHandleLimitedModeClick(true);
        }
        break;

      case -1:
        setAlertMessage("This Case ID is not on the database. It may have been recently deleted. Refresh List?")
        setTitle("Case File Not Found");
        break;

      case -8:
        setAlertMessage(`Case Not Authorized`)
        setTitle("Case Not Authorized");
        break;

      default:
        // handle other cases if needed
        break;
    }
  }, [data]);

  const handleLockUserID = () => {
    closeWarningModal();
    setHandleLockUser(true);
  };

  const handleLimitedMode = () => {
    let limitedMode = true;
    const newTabUrl = `/casedetail/${data.caseID}?limitedMode=${limitedMode}`;
    window.open(newTabUrl, '_blank');
    closeWarningModal();
  };
  const closeWarningModal = () => {
    dispatch(closeWarning())
  };

  return (
    openWarning && (
      <ContainerWrapper>
        <Modal title={title} hide={closeWarningModal} className="modal-dialog  modal-lg " footer={
          <>
            <Button  size='medium' variant='contained' name={handleLockUser ? "OK" : "Yes"} onClick={handleLimitedModeClick ? handleLimitedMode : handleLockUserID}></Button>
            {!handleLockUser && <Button size='medium' variant='outlined' name='No' onClick={closeWarningModal}></Button>}
          </>
        }>
          <h6>{alertMessage}</h6>
        </Modal>
      </ContainerWrapper>
    )
  );


}
const ContainerWrapper = styled.div`
    ${ModalAnimation}
  
`;
