import React from "react";

export const useSubDomain = () => {
  // fetch the subdomain from the url.
  const host = window.location.host;
  const arr = host.split(".").slice(0, host.includes("localhost") ? -1 : -2);

  if (arr.length > 0) {
    return {
      domain: arr[0],
    };
  }

  return {
    domain: "rmrlaw",
  };
};
