import { useState, useContext } from "react";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { CaseDetailContext } from "../../shared/case-detail-context";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import styled from "styled-components";
import { IconButton, Box, Typography } from "@mui/material";
import CaseDetailNavigation from "../../case-detail/case-details-navigation";
import { DYNAMIC_FIXEDDATA } from "../../../utils/constants/entity-types";
import GoogleMaterialIcon from "../../home/google-material-icon";
import { Link } from "react-router-dom";

export default function SideBar({ children, onClickMenuItem, paramDynamicMenuItems, dashboardView }) {
  const [showSubNavHome, setShowSubNavHome] = useState(false);
  const { setToggled, toggled, broken } = useContext(CaseDetailContext);
  const [collapsed, setCollapsed] = useState(true);
  const specialCaseIds = ["addcase", "addtask"];
  const uniqueParents = Array.from(
    new Set(paramDynamicMenuItems.map((menuItem) => menuItem.parent))
  );
  const sortedDynamicMenuItems = paramDynamicMenuItems.slice().sort((a, b) => {
    // Sort "F" entity types to the top
    if (a.entityType === DYNAMIC_FIXEDDATA && b.entityType !== DYNAMIC_FIXEDDATA) {
      return -1;
    } else if (a.entityType !== DYNAMIC_FIXEDDATA && b.entityType === DYNAMIC_FIXEDDATA) {
      return 1;
    }
    return 0;
  });
  const handleSidebarCollapse = () => {
    setCollapsed(!collapsed);
  };
  const handleSidebarToggle = () => {
    setToggled(!toggled);
  };
  const renderLink = (submenuItem) => {
    if (!specialCaseIds.includes(submenuItem.id)) {
      // Render the Link for special cases
      return (
        <Link to={`/home/${submenuItem.id}`} className="link" />
      );
    }
  }
  return (
    <>
      {/* {!dashboardView && <MainCaseDetailNavigation homeClick={() => { setShowSubNavHome(!showSubNavHome); }} // for now working on case detail screen when its finalized this comment removed
      />} */}
      <DivWrapper id="app">
        <StyledSidebar collapsed={!collapsed} breakPoint="md" onBackdropClick={() => setToggled(false)} toggled={toggled} >
          <MenuWrapper>
            <MenuItemWrapper icon={(!collapsed && <MenuOutlinedIcon onClick={handleSidebarCollapse} />)}>
              {collapsed && <StyledHeader>
                <StyledTypography variant="h6">cManager</StyledTypography>
                <IconButton onClick={broken ? handleSidebarToggle : handleSidebarCollapse}>
                  <CloseIcon />
                </IconButton>
              </StyledHeader>
              }
            </MenuItemWrapper>
            {dashboardView
              ? paramDynamicMenuItems
                .filter((item) => item.parentID === null)
                .map((parent, index) => (
                  <SubMenuWrapper key={index} icon={<span className="material-symbols-outlined"><GoogleMaterialIcon index={parent.imageIndex} /></span>} label={parent.title}>
                    {paramDynamicMenuItems
                      .filter((submenuItem) => submenuItem.parentID === parent.id)
                      .map((submenuItem, subIndex) => (
                        <MenuItemWrapper key={subIndex} onClick={() => onClickMenuItem(submenuItem)} component={renderLink(submenuItem)}>
                          {submenuItem.title}
                        </MenuItemWrapper>
                      ))}
                  </SubMenuWrapper>
                ))
              : uniqueParents.map((parent, index) => (
                <SubMenuWrapper active key={index} label={parent} icon={<HomeOutlinedIcon />}>
                  {sortedDynamicMenuItems
                    .filter((submenuItem) => submenuItem.parent === parent)
                    .map((submenuItem, subIndex) => (
                      <MenuItemWrapper key={subIndex} onClick={() => onClickMenuItem(submenuItem)}>
                        {submenuItem.detailsIndex > 1 && submenuItem.orgID !== null
                          ? `${submenuItem.entityLabel}(${submenuItem.orgID})(${submenuItem.detailsIndex})`
                          : submenuItem.detailsIndex > 1 && submenuItem.orgID === null
                            ? `${submenuItem.entityLabel}(${submenuItem.detailsIndex})`
                            : submenuItem.entityLabel}
                      </MenuItemWrapper>
                    ))}
                </SubMenuWrapper>
              ))}
          </MenuWrapper>
        </StyledSidebar>
        <WorkArea >
          {showSubNavHome ? (
            <div className="header">
              <CaseDetailNavigation
                hide={() => {
                  setShowSubNavHome(!showSubNavHome);
                }}
              ></CaseDetailNavigation>
            </div>
          ) : null}
          {children}
        </WorkArea>
      </DivWrapper>
    </>
  );
}
const WorkArea = styled.main`
 width: 100%;
  max-width: 100%;
`;
const StyledSidebar = styled(Sidebar)`
  .css-dip3t8 {
    background: ${({ theme }) => theme.palette.nav.main};
    position: sticky;
    height: 100vh;
    top: 0;
    bottom: 0;
    z-index: 10;
  }
`;
const DivWrapper = styled.div`
  height: 100%;
  display: flex;
`;
const MenuItemWrapper = styled(MenuItem)`
font-size: 0.8rem;
text-indent: 27px;
  color: ${({ theme }) => theme.palette.nav.textIcon};
  background: ${({ theme }) => theme.palette.nav.main};
`;
const SubMenuWrapper = styled(SubMenu)`
  color: ${({ theme }) => theme.palette.nav.textIcon};
  &:hover {
    color: ${({ theme }) => theme.palette.nav.onActiveTextIcon};
  }
`;
const MenuWrapper = styled(Menu)`
  .ps-menu-button:hover {
    background: ${({ theme }) => theme.palette.nav.onHoverBackground} !important;
  }
  .ps-open{
    color: ${({ theme }) => theme.palette.nav.onActiveTextIcon} !important;
  }
`;
const CloseIcon = styled(CloseOutlinedIcon)`
  color: ${({ theme }) => theme.palette.nav.textIcon};
`;
const StyledHeader = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 50px;
  text-indent: 0px;
  
`;
const StyledTypography = styled(Typography)`
  color: ${({ theme }) => theme.palette.nav.textIcon};
`;
