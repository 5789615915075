import { httpClient } from "./httpclient";
const url = "v1/entity";

const organisation = async (id) => {
    try {
        const endpoint = `${url}/organisation/${id}`;
        return await httpClient.get(endpoint);
    } catch (error) {
        throw new Error(error.response.status);
    }
};
const organisationSearch = async (typeOfOrg, exactMatch, onlyClaimsPortalOrg) => {

    try {
        const endpoint = `${url}/organisation/search/${typeOfOrg}/${exactMatch}/${onlyClaimsPortalOrg}`;
        return await httpClient.get(endpoint);
    } catch (error) {
        throw new Error(error.response.status);
    }
};
const person = async (id) => {
    try {
        const endpoint = `${url}/person/${id}`;
        return await httpClient.get(endpoint);
    } catch (error) {
        throw new Error(error.response.status);
    }
};
const personSearch = async (typeOfPeron, exactMatch, surname,forename, selectFromList) => {

    try {
        let endpoint;
        if (selectFromList) {
             endpoint = `${url}/person/search/${typeOfPeron}/${exactMatch}`;
        } else {
             endpoint = `${url}/person/search/${typeOfPeron}/${exactMatch}?surname=${surname}&forename=${forename}`;
        }
        return await httpClient.get(endpoint);
    } catch (error) {
        throw new Error(error.response.status);
    }
};


export { organisation, organisationSearch, personSearch, person };
