import React from 'react'
import Modal from './custom-modal'
import TextInput from '../controls/textinput'
import Button from '../controls/button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMessage } from '@fortawesome/free-solid-svg-icons'
import RichEditDocument from '../controls/rich-edit'
export default function SendSMSModal({hide}) {
  return (
    <div>
       <Modal icon={<FontAwesomeIcon icon={faMessage} />} hide={hide} title="Send SMS Message" className="modal-dialog  modal-lg " footer={
                <>
                    <Button size='medium' variant='outlined' name='Cancel' onClick={hide}></Button>
                    <Button type='submit' size='medium' variant='contained' name='Send'></Button>
                </>
            }>
                <div className='row g-3'>
                    <div className='col-6'>
                        <TextInput label="To" id="smsTo" variant="standard" ></TextInput>
                    </div>
                    <div className='col-6'>
                        <TextInput label="SMS Balance" id="smsBalnce" variant="standard" disabled="true"></TextInput>
                    </div>
                    <div className='col-12'>
                        <TextInput label="Client Name" id="smsClientName" variant="standard" disabled="true" ></TextInput>
                    </div>
                    
                    <div className='col-12'>
                        <TextInput label="Characters Remaining" id="smsCharactersRemaing" variant="standard" disabled="true" ></TextInput>
                    </div>
                    <div className='col-12'>
                        <RichEditDocument></RichEditDocument>
                    </div>
                </div>
            </Modal>
    </div>
  )
}
