import React, { useEffect, useState } from 'react'
import fieldData from '../json/field-data';
import styled from 'styled-components';
import UseDynamicField from '../../hooks/useDynamicField';
import { REGEX } from '../../utils/constants/field-mask-type';
import { dynamicField } from '../../services/dynamic-data-service';
import { setToSS ,getFromSS } from '../../utils/helpers/storage';
export default function AdditionalInformation({caseType, caseSubType, entityType,entityName, detailIndex, caseID, orgID, entityID,casePersonID }) {
  const [apiData, setApiData] = useState([]);
  const [fieldValidity, setFieldValidity] = useState({}); // Object to store valid state for each field
  const sortedFields = apiData.slice().sort((a, b) => a.sequence - b.sequence);
  const [decimalValue, setDecimalValue] = useState("0");

  const CallApi = async () => {
    try {
      const response = await dynamicField(caseType, caseSubType,entityName, entityID, caseID, detailIndex, orgID,entityType,casePersonID);
      setToSS(entityName+"_"+detailIndex+"_"+caseID, response.data.results);
      setApiData(response.data.results); // Log the response data
    } catch (error) {
       console.error(error);
    }
  };
  useEffect(() => {
    const storedData = getFromSS(entityName+"_"+detailIndex+"_"+caseID);
    if (storedData) {
      setApiData(storedData);
    } else {
      CallApi(); // Call the Test function to fetch and store the data
    }
    const initialValidity = {};
    fieldData.forEach((field) => {
      initialValidity[field.id] = true;
    });
    setFieldValidity(initialValidity);
  }, []);
  const handleValidation = (e, apiData) => {
    if (apiData.fieldMaskType === REGEX) {
      const reg = new RegExp(apiData.fieldEditMask);
      setFieldValidity((prevState) => ({
        ...prevState,
        [apiData.id]: reg.test(e.target.value), // Store the valid state for the specific field
      }));
    } else {
      setFieldValidity((prevState) => ({
        ...prevState,
        [apiData.id]: true, // For non-regex fields, set valid to true
      }));
    }
  };
  const handleDecimal = (e, apiData) => {
    const regex = apiData.fieldEditMask;
    const decimal = regex.charAt(regex.length - 1);
    setDecimalValue(parseFloat(e.target.value).toFixed(decimal))
  };
  return (
    <DivWrapper>
      <h6>Additional Information</h6>
      <hr />
      <div className="row g-3">
        {sortedFields.map((apiData) => (
          <div className="col-lg-12" key={apiData.id}>
            <UseDynamicField
              apiData={apiData}
              handleValidation={handleValidation}
              handleDecimal={handleDecimal}
              fieldValidity={fieldValidity}
              decimalValue={decimalValue}
            />
          </div>
        ))}
      </div>
    </DivWrapper>
  );
}
const DivWrapper = styled.div`
  margin: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px 20px 10px 20px;
    `;
