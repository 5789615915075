import React, { useState, useEffect } from 'react'
import Dropdown from '../controls/drop-down'
import Datepicker from '../controls/datepicker'
import branch from '../json/branch'
import FeeEarner from '../json/Fee-Earner'
import Supervisor from '../json/supervisor'
import Button from '../controls/button'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import Modal from './custom-modal'
import { PERSONAL_INJURY } from '../../utils/constants/case-types'
import DropDownFilter from '../controls/dropdownfilter'
import { ModalAnimation } from '../../utils/styles/animation-styles'
import { useDispatch } from 'react-redux'
import { closeModal } from '../features/modal-slice'
export default function AddCases({ paramCaseType, paramCaseSubType, defaultCaseType }) {
    const [showDropDownPersonalInjuries, setShowDropDownPersonalInjuries] = useState(false)
    const [showClientStaus, setClientStatus] = useState(true)
    const [showDateOfAccident, setDateOfAccident] = useState(true)
    const [showOICPortal, setOICPortal] = useState(true)
    const [showClaimValue, setClaimValue] = useState(true)
    const [showReferalSource, setReferalSource] = useState(true)
    const [CaseTypes, setCaseTypes] = useState(defaultCaseType)
    const [CaseSubTypes, setCaseSubTypes] = useState("")
    const [filterCaseSubTypeOption, setFilterCaseSubTypeOption] = useState(paramCaseSubType)
    const dispatch = useDispatch();
    useEffect(() => {
        setFilterCaseSubTypeOption(DropDownFilter(paramCaseSubType, CaseTypes));
        if (CaseTypes === PERSONAL_INJURY) {
            setShowDropDownPersonalInjuries(true);
        }
        else {
            setShowDropDownPersonalInjuries(false);
        }
    }, [CaseTypes]);
    const handleCaseType = (event) => {
        const CaseTypes = event.parameterID;
        setCaseTypes(CaseTypes);
    };
    const handleCaseSubType = (event) => {
        const CaseSubTypes = event.parameterID;
        const parameterCharacter = event.parmChar;
        setCaseSubTypes(CaseSubTypes);
        if (parameterCharacter === "Pedistrian Accident" || parameterCharacter === "Holiday (Accident)" || parameterCharacter === "Holiday (Illness)" || parameterCharacter === "Medical Negligence" || parameterCharacter === "Criminal Injuries" || parameterCharacter === "Other") {
            setClientStatus(false)
            setDateOfAccident(false)
            setOICPortal(false)
            setReferalSource(false)
        } else if (parameterCharacter === "Public Liability" || parameterCharacter === "Employer's Liability (Accident)") {
            setClientStatus(false)
            setReferalSource(false)
            setDateOfAccident(true)
            setOICPortal(true)
            setClaimValue(true)
        } else if (parameterCharacter === "Employer's Liability (Disease)") {
            setClientStatus(false)
            setReferalSource(false)
            setDateOfAccident(false)
            setOICPortal(true)
            setClaimValue(true)
        }
        else {
            setClientStatus(true)
            setDateOfAccident(true)
            setOICPortal(true)
            setReferalSource(true)
            setClaimValue(true)
        }
    };
    const closeCaseModal = () => {
        dispatch(closeModal());
    };
    return (
        <ContainerWrapper>
            <Modal icon={<FontAwesomeIcon icon={faPlusCircle} />} hide={closeCaseModal} title="Add Case" className="modal-dialog  modal-lg " footer={
                <>
                    <Button size='medium' variant='outlined' name='Close' onClick={closeCaseModal}></Button>
                    <Button type='submit' size='medium' variant='contained' name='Add Case'></Button>
                </>
            }>
                <div className="row g-4">
                    <div className='col-lg-12'>
                        <Dropdown label="Branch" id="Branch" options={branch} ></Dropdown>
                    </div>
                    <div className='col-lg-6 col-md-6 col-6'>
                        <Dropdown label="Case Type" id="Case-Type" options={paramCaseType} customPropertyName="parameterID" displayPropertyName="parmChar" onChange={handleCaseType} value={CaseTypes}></Dropdown>
                    </div>
                    <div className='col-lg-6 col-md-6 col-6'>
                        <Dropdown label="Case Sub Type" id="Case-Sub-Type" options={filterCaseSubTypeOption} customPropertyName="parameterID" displayPropertyName="parmChar" onChange={handleCaseSubType} value={CaseSubTypes}></Dropdown>
                    </div>
                    <div className='col-lg-6 col-md-6 col-6'>
                        <Dropdown label="Fee Earner  " id="Fee-Earner" options={FeeEarner}></Dropdown>
                    </div>
                    <div className='col-lg-6 col-md-6 col-6'>
                        <Dropdown label="Supervisor" id="Supervisor" options={Supervisor} ></Dropdown>
                    </div>
                    {showDropDownPersonalInjuries ?
                        <>
                            {showClientStaus ?
                                <div className='col-lg-12'>
                                    <Dropdown label="Client Status" id="Client-Status" ></Dropdown>
                                </div>
                                : null}
                            {showDateOfAccident ?
                                <div className='col-lg-6 col-md-6 col-6'>
                                    <Datepicker label="Date Of Accident" id="Accident-Date" ></Datepicker>
                                </div>
                                : null}
                            {showOICPortal ?
                                <div className='col-lg-6 col-md-6 col-6'>
                                    <Dropdown label="Claims Or OIC Portal?" id="OIC-Portal" ></Dropdown>
                                </div>
                                : null}
                            {showClaimValue ?
                                <div className='col-lg-12'>
                                    <Dropdown label="Approx Overall Claim Value" id="Claim-value" ></Dropdown>
                                </div>
                                : null}
                            {showReferalSource ?
                                <div className='col-lg-12'>
                                    <Dropdown label="Referral Source" id="Referral-Source" ></Dropdown>
                                </div>
                                : null}
                        </>
                        : null}
                    <div className='col-lg-12'>
                        <Dropdown label="Case Source" id="Case-Source" ></Dropdown>
                    </div>
                </div>
            </Modal>
        </ContainerWrapper>
    )
}
const ContainerWrapper = styled.div`
    ${ModalAnimation}
`;