import  { css ,keyframes } from "styled-components";
const fadeInAndSlideIn = keyframes`
  from {
    opacity: 0;
    transform: translateX(-100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;
export const ModalAnimation = ({ theme, myProp }) => css`
  animation: ${fadeInAndSlideIn} 0.6s forwards;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
`;