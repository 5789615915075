import React, { useState } from 'react'
import TextInput from '../controls/textinput'
import { useAuth } from '../../hooks/useAuth'
import { changePassword } from '../../services/user-service'
import PasswordStrengthIndicator from '../password-strength-meter/password-strength-indicator'
import Button from '../controls/button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faKey } from '@fortawesome/free-solid-svg-icons'
import styled from 'styled-components'
import { ModalAnimation } from '../../utils/styles/animation-styles'
import Modal from './custom-modal'
import { triggerAlert } from '../features/alert-slice'
import { useDispatch } from 'react-redux'
import PasswordInputField from '../controls/password-input-field'
export default function ChangePassword({hide}) {
    const { user } = useAuth();
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const dispatch = useDispatch()
    const handleChangePassword = async (event) => {
        event.preventDefault();
        if (!oldPassword || !newPassword) {
            return;
        }
        else {
            try {
                var response = await changePassword(
                    user.userID,
                    oldPassword,
                    newPassword,
                );
                if (response.data.returnCode == 1) {
                    hide();
                    let message ="Password has been successfully updated";
                    dispatch(triggerAlert({ openAlert: true, severity: "success", alertMessage: message }))
                }
            } catch (error) {
                console.error(error);
            }
        }
    }
    const handlePasswordChange = (event) => {
        const newPassword = event.target.value;
        setNewPassword(newPassword);
        if (confirmPassword && newPassword !== confirmPassword) {
            setPasswordError('Passwords do not match');
        } else {
            setPasswordError('');
        }
    };
    const handleConfirmPasswordChange = (event) => {
        const confirmPassword = event.target.value;
        setConfirmPassword(confirmPassword);

        if (newPassword && confirmPassword !== newPassword) {
            setPasswordError('Passwords do not match');
        } else {
            setPasswordError('');
        }
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        // You can perform additional validation or checks here if needed
        // Call the handleChangePassword function to handle the form submission
        handleChangePassword(event);
    };
    return (
        <ContainerWrapper>
            <Modal icon={<FontAwesomeIcon icon={faKey} />} hide={hide} title="Change Password" handleFormSubmit={handleSubmit} className="modal-dialog  modal-lg " footer={
                <>
                    <Button size='medium' variant='outlined' name='Close' onClick={hide}></Button>
                    <Button type='submit' size='medium' variant='contained' name='Submit New Password'></Button>
                </>
            }>
                <div className="row ">
                    <div className='col-lg-12 mb-4'>
                        <PasswordInputField label="Old Password" id="Old-Password" variant="standard" fullWidth={true} required={true} onChange={e => setOldPassword(e.target.value)} value={oldPassword} ></PasswordInputField>
                    </div>
                    <div className='col-lg-12 mb-1'>
                        <PasswordInputField label="New-Password" id="New-Password" variant="standard" fullWidth={true} required={true} onChange={handlePasswordChange} value={newPassword} helperText={passwordError} error={!!passwordError}></PasswordInputField>
                        <div className='form-group mt-1 '>
                            <PasswordStrengthIndicator Password={newPassword}></PasswordStrengthIndicator>
                        </div>
                    </div>
                    <div className='col-lg-12 mb-2'>
                        <PasswordInputField label="Confirm Password" id="Confirm-Password" variant="standard" fullWidth={true} required={true} onChange={handleConfirmPasswordChange} value={confirmPassword} helperText={passwordError} error={!!passwordError}></PasswordInputField>
                        <div className='form-group mt-1 '>
                            <PasswordStrengthIndicator Password={confirmPassword}></PasswordStrengthIndicator>
                        </div>
                    </div>
                </div>
            </Modal>
        </ContainerWrapper>
    )
}


const ContainerWrapper = styled.div`
    ${ModalAnimation}
`;