// Redux slice for calculating and managing unit cost based on usage time

import { createSlice } from '@reduxjs/toolkit';

// Function to calculate the number of units based on total seconds and minutes per unit
const calculateUnits = (totalSeconds, minutesPerUnit) => {
  return Math.ceil(totalSeconds / (minutesPerUnit * 60));
};

// Function to calculate the cost based on charging rate and units
const calculateCost = (chargingRate, units) => {
  return chargingRate / 10 * units;
};

// Redux slice for unit cost state management
const unitCostSlice = createSlice({
  name: 'unitCost',
  initialState: {
    totalSeconds: 0,
    cost: 0,
    noOfUnit: 0,
  },
  reducers: {
    // Action to update unit cost based on the provided data
    updateUnitCost: (state, action) => {
      const { totalSeconds, minutesPerUnit, chargingRate } = action.payload;
      state.totalSeconds = totalSeconds;
      const units = calculateUnits(totalSeconds, minutesPerUnit);
      const cost = calculateCost(chargingRate, units);
      state.noOfUnit = units;
      state.cost = cost;
    },
  },
});

// Exporting the action creator
export const { updateUnitCost } = unitCostSlice.actions;

// Exporting the reducer
export default unitCostSlice.reducer;
