const Supervisor = [
    {
        id : 1,
        name : "Razak"

    },
    {
        id : 2,
        name : "Ali"

    },
    {
        id : 3,
        name : "Saad"

    },
    {
        id : 4,
        name : "Mansoor"

    },
    {
        id : 5,
        name : "Ahmed"

    }

]
export default Supervisor;