import React, { useState } from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
export default function VerticalToggleButton() {
  const [view, setView] = useState('Pause');
  const handleChange = (nextView) => {
    setView(nextView);
  };

  return (
    <ToggleButtonGroup orientation="vertical" value={view} exclusive onChange={handleChange}>
      <ToggleButton size="small" value="start" aria-label="start">
        {view === 'start' ? 'Start Timer' : 'Pause Timer'}
      </ToggleButton>
    </ToggleButtonGroup>
  );
}
