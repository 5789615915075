import React from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
export default function CheckBox({label,checked,onChange}) {
  return (
    <div>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={checked || false}
              onChange={onChange}
              color="primary" // You can set the color to 'primary' or 'secondary'
            />
          }
          label={label}
        />
      </FormGroup>
    </div>
  );
}
