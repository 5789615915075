
import { openAndLockCase } from '../../services/case-service';
export async function CaseDetailTab(caseID) {
    let returnCode;
    let lockUser;
    let result = {};
    if (caseID) {
        const response = await openAndLockCase(caseID);
        returnCode = response.data.returnCode;
        lockUser = response.data.result?.lockUser;
        try {
            if (returnCode === 1) {
                const newTabUrl = `/casedetail/${caseID}`;
                window.open(newTabUrl, '_blank');
                result = { success: true };
            }
            else if (returnCode === -9) {
                result = { success: false, returnCode: returnCode, lockUser: lockUser, caseID: caseID };
            } else if (returnCode === -1) {
                result = { success: false, returnCode: returnCode };
            } else if (returnCode === -8) {
                result = { success: false, returnCode: returnCode };
            }
        }
        catch (error) {
            console.error(error);
        }
    }


    return result;
}
