import React, { useState, useContext } from "react";
import { Box, IconButton, InputBase } from "@mui/material";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import { CaseDetailContext } from "../shared/case-detail-context";
import styled from "styled-components";
import { useScreenSize } from "../../hooks/useScreenSize";
import { useEffect } from "react";
import AdvanceSearchForm from "../navigation/advance-search-form";
import { Desktop, Mobile } from "../../utils/constants/devices";
import UserProfile from "../navigation/user-profile";
import Divider from '@mui/material/Divider';
const HomeTopBar = () => {
  const [caseID, setCaseID] = useState(0);
  const [showAdvanceSearch, setShowAdvanceSearch] = useState(false);
  const { toggled, setToggled, broken, setBroken } = useContext(CaseDetailContext);
  const device = useScreenSize();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  useEffect(() => {
    if (device === Mobile) {
      setBroken(true);
    } else if (device === Desktop) {
      setBroken(false);
    }
  }, [device]);
  const openCaseDetail = () => {
    if (caseID) {
      const newTabUrl = `/casedetail/${caseID}`;
      window.open(newTabUrl, '_blank');
    }
  };
  const handleCaseIDChange = (event) => {
    setCaseID(event.target.value);
  };
  const handleSidebarToggle = () => {
    setToggled(!toggled);
  };
  const handleButtonClick = (event) => {
    setOpen(!open);
  };
  const handleHelpButtonClick = () => {
    // Open the link in a new tab
    window.open("https://derbydatabases.atlassian.net/wiki/spaces/CMANAGER/overview", "_blank");
  };
  return (
    <>
      <TopbarWrapper>
        <BoxWrapper>
          {broken && <IconButton
            sx={{ margin: "0 6 0 2" }}
            onClick={handleSidebarToggle}
          >
            <MenuOutlinedIcon />
          </IconButton>}

          <SearchBar>
            <StyledInputBase type="text" placeholder="Case ID" name="Case ID" onChange={handleCaseIDChange} onKeyUp={(event) => {
              if (event.key === "Enter") {
                openCaseDetail();
              }
            }} />
            <IconButton onClick={openCaseDetail} >
              <span className="material-symbols-outlined">search</span>
            </IconButton>
            <ThickDivider orientation="vertical" variant="middle" flexItem />
            <IconButton onClick={() => setShowAdvanceSearch(!showAdvanceSearch)} id="FilterSearchButton" >
              <span className="material-symbols-outlined" id="FilterSearchButton">quick_reference_all</span>
            </IconButton>
          </SearchBar>
        </BoxWrapper>
        <BoxWrapper>
          <IconButtonWrapper onClick={handleHelpButtonClick}>
            <span className="material-symbols-outlined">help</span>
          </IconButtonWrapper>
          <IconButtonWrapper ref={anchorRef} onClick={handleButtonClick} >
            <span className="material-symbols-outlined">account_circle</span>
          </IconButtonWrapper>
        </BoxWrapper>
      </TopbarWrapper>
      <Box >
        {showAdvanceSearch && <AdvanceSearchForm hide={() => setShowAdvanceSearch(!showAdvanceSearch)}></AdvanceSearchForm>}
      </Box>
      <UserProfile anchorRef={anchorRef} setOpen={setOpen} open={open} />
    </>
  );
};
export default HomeTopBar;
const TopbarWrapper = styled(Box)`
  display: flex;
  justify-content: space-between;
  padding: 16px;
  margin:5px;
`;
const SearchBar = styled(Box)`
 display: flex;
  padding: 0.2rem;
  border-radius: 6px;
  border: 0.1px solid ${({ theme }) => theme.palette.nav.borderColor};
`;
const BoxWrapper = styled(Box)`
  display: flex;
`;
const StyledInputBase = styled(InputBase)`
  margin-left: 1rem;
  flex: 1;
  
`;
const IconButtonWrapper = styled(IconButton)`

  color: ${({ theme }) => theme.palette.topBar.icon};
`;
const ThickDivider = styled(Divider)`
&& {
  border-left: 2px solid #000; /* Adjust thickness and color as needed */
}
`;