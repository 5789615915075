import React, { useState, useEffect } from "react";
import { Sortable } from 'devextreme-react/sortable';
import TabPanel from 'devextreme-react/tab-panel';
import 'devextreme/data/odata/store';
import DynamicCaseNavigation from "../../dynamic-entity/dynamic-case-navigation";
import "./dynamic-tab.css"
const DynamicTab = ({ data, closeTab, selectedTab, caseType, caseSubType }) => {
  const [selectedItem, setSelectedItem] = useState(selectedTab);

  useEffect(() => {
    setSelectedItem(selectedTab);
  }, [selectedTab]);

  const closeButtonHandler = (item) => {
    // Implement your logic to handle closing a tab
    closeTab(item);
  };
  const renderTitle = (data) => (
    <div>
      <span>{data.entityLabel}</span>
      {!data.openByDefault &&<i className="dx-icon dx-icon-close" onClick={() => closeButtonHandler(data)} />}
    </div>
  );
  const onTabDragStart = (e) => {
    e.itemData = e.fromData[e.fromIndex];
  };

  const onTabDrop = (e) => {
    // Implement your logic to handle tab reordering
  };

  return (
    <div>
      <Sortable
        filter=".dx-tab"
        data={data}
        itemOrientation="horizontal"
        dragDirection="horizontal"
        onDragStart={onTabDragStart}
        onReorder={onTabDrop}
      >
        <TabPanel
          dataSource={data}
          itemTitleRender={renderTitle}
          deferRendering={false}
          showNavButtons={true}
          selectedItem={selectedItem}
          repaintChangesOnly={true}
          itemComponent={(itemData) => (
            <div>

              <DynamicCaseNavigation
                itemData={itemData}
                caseType={caseType}
                caseSubType={caseSubType}
              />
            </div>
          )}
        />
      </Sortable>
    </div>
  );
};

export default DynamicTab;
