import React from 'react'
import { DataGrid } from 'devextreme-react'
export default function CustomGrid({ children, data, onSelectionChanged, columnWidth, onRowDblClick, dataRow, mobileView, id, onExporting, contextMenu, onRowClick, selectedRowKey, height, keyExpr, style, columnAutoWidth, wordWrapEnabled, remoteOperations, onRowValidating}) {
  return (
    <div>
      <DataGrid
        dataSource={data}
        showBorders={true}
        allowColumnReordering={true}
        allowColumnResizing={true}
        columnAutoWidth={columnAutoWidth}
        wordWrapEnabled={wordWrapEnabled}
        remoteOperations={remoteOperations}
        showRowLines={true}
        showColumnLines={true}
        hoverStateEnabled={true}
        onRowDblClick={onRowDblClick}
        onSelectionChanged={onSelectionChanged}
        columnWidth={columnWidth}
        id={id}
        onRowPrepared={dataRow}
        dataRowRender={mobileView}
        onExporting={onExporting}
        onContextMenuPreparing={contextMenu}
        onRowClick={onRowClick}
        selectedRowKeys={selectedRowKey}
        height={height}
        keyExpr={keyExpr}
        onRowValidating={onRowValidating}
        style={style}

       
      >
        {children}
      </DataGrid>
    </div>
  )
}
