import React, { useEffect, useState } from 'react'
import Dropdown from '../controls/drop-down'
import TextArea from '../controls/textarea'
import TextInput from '../controls/textinput'
import styled from 'styled-components'
import IconButton from '../controls/icon-button'
import AddCircleSharpIcon from '@mui/icons-material/AddCircleSharp';
import { nextDetailIndex, dynamicField } from '../../services/dynamic-data-service'
import { getFromSS, setToSS } from '../../utils/helpers/storage'
import DeleteSharpIcon from '@mui/icons-material/DeleteSharp';
import { organisation, organisationSearch, person } from '../../services/entity-service'
import FileOpenSharpIcon from '@mui/icons-material/FileOpenSharp';
import OrganizationGrid from '../data-grid/organization-grid/organization-grid';
import MaintainOrganizationView from '../../views/case-detail/maintain-organization-view';
export default function OrganizationForm({ caseType, caseSubType, entityName, detailIndex, entityType, caseID, entityID, orgID }) {
    const [apiData, setApiData] = useState([]);
    const [name, setname] = useState("");
    const [details, setDetails] = useState("");
    const [organisationData, setOrganisationData] = useState({});
    const [personData, setPersonData] = useState({});
    const [contactIDs, setContactIDs] = useState([]);
    const [caseContact, setCaseContact] = useState("");
    const [showMaintainOrganization, setShowMaintainOrganization] = useState(false);
    const CallApi = async () => {
        if (orgID !== null) {
            try {
                const response = await organisation(orgID);
                const organisationData = response.data.result;
                if (organisationData) {
                    setOrganisationData(organisationData);
                    const details = organisationData.addressBlock;
                    const name = organisationData.name;
                    setname(name);
                    setDetails(details);
                    const contactDataArray = [...contactIDs];
                    for (const contactID of organisationData.contactIDs) {
                        const personData = await person(contactID);
                        const people = personData.data.result;
                        setPersonData(people);
                        const name = people.forename + " " + people.surname;
                        const id = people.id;
                        const contactData = {
                            name: name,
                            id: id,
                        };
                        contactDataArray.push(contactData);
                        setContactIDs(contactDataArray);
                        setToSS(`${entityName}_${contactID}`, people); // Store in session storage
                    }
                }
            } catch (error) {
                 console.error(error);
            }
        }
        try {
            const response = await organisationSearch(entityName, true, true);
            setApiData(response.data.results)
        } catch (error) {
             console.error(error);
        }
    };
    useEffect(() => {

        CallApi(); // Call the Test function to fetch and store the data

    }, []);
    const handleCaseContact = (event) => {
        const caseContact = event.target.value;
        setCaseContact(caseContact)
        const selectedContact = contactIDs.find(contact => contact.name === caseContact);
        if (selectedContact) {
            // Here, you can access selectedContact.id for the selected contact's ID
        }
    };
    const handleAddClick = async () => {
        try {
            const response = await nextDetailIndex(entityID, caseID, entityType);
            var nextdetailindex = response.data.result;
            detailIndex = nextdetailindex;
            const newField = await dynamicField(caseType,caseSubType, entityName, entityID, caseID, detailIndex, entityType);
            var newData = newField.data.results;
            const updatedData = newData.map(field => {
                return {
                    ...field,
                    detailsIndex: nextdetailindex
                };
            });
            setToSS(entityName + "_" + nextdetailindex + "_" + caseID, updatedData);
            const storedData = getFromSS(caseID);
            let firstDifferentNameIndex = storedData.findIndex(entity => entity.name !== entityName);

            if (firstDifferentNameIndex === -1) {
                firstDifferentNameIndex = storedData.length;
            }
            // Insert the new entity data before the first entity with a different name
            storedData.splice(firstDifferentNameIndex, 0, {
                "id": 7,
                "entityType": "O",
                "entityName": "Court",
                "entityLabel": "Court",
                "parent": "Other",
                "multiplePerCase": true,
                "letterFolder": "",
                "selectFromList": false,
                "nameOnlyMandatory": false,
                "openByDefault": false,
                "displayEntityName": null,
                "detailsIndex": 10,
                "caseID": 15041,
                "ref": null,
                "caseOrgID": null,
                "orgID": null,
                "orgContactID": null,
                "cachedOrg": null,
                "casePersonID": 0,
                "thirdPartyID": null,
                "cachedThirdParty": null,
                "entityUpdated": false,
                "entityDeleted": false,
                "dynamicFieldList": null
            });
            setToSS(caseID, storedData);
        } catch (error) {
             console.error(error);
        }
    };
    const handleFileClick = () => {
        setShowMaintainOrganization(!showMaintainOrganization);
    }
    return (
        <>
            {showMaintainOrganization && <MaintainOrganizationView  organisationData={organisationData} personData={personData} hide={() => { setShowMaintainOrganization(!showMaintainOrganization) }}></MaintainOrganizationView>}
            <DivWrapper>
                <div className="row g-4">
                    <div className='col-lg-8 col-md-8 col-8'>
                    <OrganizationGrid data={apiData} value={name}></OrganizationGrid>
                        {/* <LookUpList data={apiData} value={name}></LookUpList> */}
                    </div>
                    <div className='col-lg-1 col-md-1 col-1'>
                        <IconButton label="File" id={`file-${entityName}-${entityID}`} color="white" size="small" onClick={handleFileClick}>
                            <FileOpenSharpIcon fontSize="large" />
                        </IconButton>
                    </div>
                    <div className='col-lg-1 col-md-1 col-1'>
                        <IconButton label="Delete" id={`delete-${entityName}-${entityID}`} color="danger" size="small" >
                            <DeleteSharpIcon fontSize="large" />
                        </IconButton>
                    </div>

                    <div className='col-lg-1 col-md-1 col-1'>
                        <IconButton label="Add" id={`add-${entityName}-${entityID}`}  color="success" size="small" onClick={handleAddClick}>
                            <AddCircleSharpIcon fontSize="large" />
                        </IconButton>
                    </div>
                    <div className='col-lg-12'>
                        <TextArea label="Details"  id={`details-1-${entityName}-${entityID}`}  row="3" value={details} ></TextArea>
                    </div>

                    <div className='col-lg-12'>
                        <Dropdown label="Case Contact"  id={`case-contact-${entityName}-${entityID}`} value={caseContact} options={contactIDs} onChange={handleCaseContact}></Dropdown>
                    </div>
                    <div className='col-lg-12'>
                        <TextArea label="Details" id={`details-2-${entityName}-${entityID}`} row="3" ></TextArea>
                    </div>
                    <div className='col-lg-12'>
                        <TextInput label="Refference" id={`refference-1-${entityName}-${entityID}`} variant="standard" value={name}></TextInput>
                    </div>
                </div>
            </DivWrapper>
        </>
    )
}
const DivWrapper = styled.div`
  margin: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px 10px 10px 10px;
  

  
    `;