import React from 'react'
import TextInput from '../controls/textinput'
import Datepicker from '../controls/datepicker'
import Dropdown from '../controls/drop-down'
import CheckBox from '../controls/checkbox'
export default function StatusForm() {
  return (
    <div className='container-fluid'>
      <div className='row g-4'>
        <div className='col-lg-6 col-6'>
          <Dropdown label="Case Status" id="caseStatus" ></Dropdown>
        </div>
        <div className='col-lg-6 col-6'>
          <Datepicker label="Open Date" id="openDate"></Datepicker>
        </div>
        <div className='col-lg-6 col-6'>
          <TextInput label="Case Tags" id="caseTags" variant="standard"></TextInput>
        </div>
        <div className='col-lg-6 col-6'>
          <Datepicker label="Conflict Check" id="conflictCheck"></Datepicker>
        </div>
        <div className='col-lg-4 col-6'>
          <TextInput label="Internal Info" id="internalInfo" variant="standard"></TextInput>
        </div>
        <div className='col-lg-4 col-6'>
          <CheckBox label="Fixed Fee Case"></CheckBox>
        </div>
        <div className='col-lg-4 col-6'>
          <Datepicker label="Conflict Check" id="conflictCheck"></Datepicker>
        </div>
        <div className='col-lg-6 col-6'>
          <TextInput label="RTA Used Field 1" id="RTA-field1" variant="standard"></TextInput>
        </div>
        <div className='col-lg-6 col-6'>
          <TextInput label="RTA Used Field 2" id="RTA-field2" variant="standard"></TextInput>
        </div>
      </div>
    </div>
  )
}
