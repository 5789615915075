// Redux slice for managing timer-related state, including start time and reset functionality

import { createSlice } from "@reduxjs/toolkit";

// Initial state for the timer slice
const initialState = {
  startTime: null,
  resetTime: false,
};

// Redux slice for timer state management
export const timerSlice = createSlice({
  name: "timer",
  initialState,
  reducers: {
    // Action to set the start time based on the provided payload
    getStartTime: (state, action) => {
      const { startTime } = action.payload;
      state.startTime = startTime;
    },
    // Action to trigger the timer reset based on the provided payload
    resetTimer: (state, action) => {
      const { resetTime } = action.payload;
      state.resetTime = resetTime;
    },
  },
});

// Exporting the action creators
export const { getStartTime, resetTimer } = timerSlice.actions;

// Exporting the reducer
export default timerSlice.reducer;
