import React from 'react'
import styled from 'styled-components'
export default function Modal({ children, hide, title, footer, icon ,className ,handleFormSubmit}) {
    return (
        <>
            <ModalWrapper className="modal fade show">
                <div className={className}>
                    <div className="modal-content">
                        <div className="modal-header ">
                            <h3 className="modal-title" >{icon} {title}</h3>
                            <button type="button" className="btn-close" onClick={hide}></button>
                        </div>
                        <form onSubmit={handleFormSubmit}>
                        <div className="modal-body">
                            <div className="container-fluid">
                                {children}
                            </div>
                        </div>
                        <div className="modal-footer">
                            {footer}
                        </div>
                        </form>
                    </div>
                </div>
            </ModalWrapper>
        </>
    )
}
const ModalWrapper = styled.div`
    display: block;
    background: rgba(0,0,0,0.8);

`;