import React, { useState, useCallback, useEffect } from 'react'
import CustomGrid from '../../controls/grid';
import { Column, HeaderFilter, FilterRow, Paging, SearchPanel, Export, Editing, Lookup, ColumnChooser, Position, StateStoring, Sorting, Scrolling } from 'devextreme-react/data-grid'
import { CheckBox } from 'devextreme-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile } from '@fortawesome/free-solid-svg-icons';
import Modal from '../../modals/custom-modal';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import Button from '../../controls/button';
import { useScreenSize } from '../../../hooks/useScreenSize';
import { subFolder, feeEarner } from '../../json/sub-folder';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver-es';
import { exportDataGrid } from 'devextreme/excel_exporter';
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { setToLS, getFromLS } from '../../../utils/helpers/storage';
import { tabSuffix, desktopSuffix } from '../../../utils/constants/storageKeys';
import { Mobile, Desktop, Tab } from '../../../utils/constants/devices';
import "./case-history.css"
import { locale } from 'devextreme/localization';
import { historyByID } from '../../../services/history-service';
import { useDispatch } from 'react-redux';
import { openModal } from '../../features/modal-slice';
import { EDIT_HISTORY, DELETE_HISTORY } from '../../../utils/constants/modal-name';
import { DELETE, UPDATE } from '../../../utils/constants/mode';
export default function CaseHistoryGrid({ storeKey, historyData }) {
  const [showViewCaseHistory, setViewCaseHistory] = useState(false);
  const dispatch = useDispatch();
  const device = useScreenSize();
  let storageKey;
  let mobile = false;
  if (device === Tab) {
    storageKey = storeKey + tabSuffix
    mobile = false;
  }
  else if (device === Desktop) {
    storageKey = storeKey + desktopSuffix
    mobile = false;
  }
  else if (device === Mobile) {
    mobile = true;
  }
  useEffect(() => {
    locale("en-GB");

  }, []);

  const onExporting = useCallback((e) => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Main sheet');

    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'DataGrid.xlsx');
      });
    });
    e.cancel = true;
  }, []);
  function DataRow(e) {
    return (
      <React.Fragment>
        <tr className="main-row">
          <td>{e.data.Date}</td>
          <td>{e.data.Time}</td>
          <td>{e.data.FeeEarnerID}</td>
          <td>{e.data.activeTimeSeconds}</td>
        </tr>
        <tr className="notes-row">
          <td colSpan="4
          "><div>{e.data.Narrative}</div></td>
        </tr>
      </React.Fragment>
    );
  }
  const FixedFeeEntyCheckboxCell = (props) => {
    const { data } = props;
    return (
      <>
        <div className="checkbox-cell">
          <CheckBox defaultValue={data.fixedFeeEntry} />
        </div>
      </>
    );
  };
  const FileCell = (props) => {
    const { data } = props;

    const hasLinkedDocument = data.fileName; // Assuming you have a property named "LinkedDocument" that indicates the presence of a linked document
    const handleFileClick = () => {
      if (hasLinkedDocument) {
        const docs = [
          { uri: "https://calibre-ebook.com/downloads/demos/demo.docx", fileType: 'docx', fileName: "demo.docx" }, // Local File
        ];

        return <DocViewer documents={docs} pluginRenderers={DocViewerRenderers} style={{ height: 1000 }} />;
      }
    };
    return (
      <div className="File-logo" onClick={handleFileClick}>
        {hasLinkedDocument && <FontAwesomeIcon icon={faFile} />}
      </div>
    );
  };
  const onRowDblClick = (e) => {
    // Perform any additional actions you want on row double-click
    setViewCaseHistory(true);
  };
  const loadState = useCallback(() => {
    return getFromLS(storageKey);
  }, []);

  const saveState = useCallback((state) => {
    setToLS(storageKey, state);
  }, []);
  const addMenuItems = (e) => {
    let id, modalName, mode;
    if (e.target == 'content') {
      // e.items can be undefined
      if (!e.items) e.items = [];
      // Add a custom menu item
      e.items.push({
        text: ' Edit History Record',
        onItemClick: () => {
          id = e.row.data.id;
          modalName = EDIT_HISTORY;
          mode = UPDATE
          CallHistoryByIDApi(id, modalName, mode);

        }
      }, {
        text: 'Delete History Record',
        onItemClick: () => {
          id = e.row.data.id;

          modalName = DELETE_HISTORY;
          mode = DELETE
          CallHistoryByIDApi(id, modalName, mode);
        }
      }
        , {
          text: 'Send File via beA Mail',
          onItemClick: () => {
            // Perform custom action 1
          }
        }
        , {
          text: 'Email File to',
          items: [
            { text: 'Facebook' },
            { text: 'Twitter' }],
          onItemClick: () => {
            // Perform custom action 1
          }
        }
        , {
          text: 'Delete History Record',
          onItemClick: () => {
            // Perform custom action 1
          }
        }
        , {
          text: 'Delete History Record',
          onItemClick: () => {
            // Perform custom action 1
          }
        }
      );
    }
  };
  const CallHistoryByIDApi = async (id, modalName, mode) => {
    try {
      const rowdata = await historyByID(id);
      dispatch(openModal({ modalName: modalName, mode: mode, data: rowdata.data.result, }))
    }
    catch (error) {
      console.error(error);
    }
  };
  const getDate = (rowData) => {
    return new Date(rowData.timeStamp);
  };
  function formatSecondsToMinutesAndSeconds(seconds) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  }
  function formatToDecimal(value) {
    // Format to two decimal places
    return Number(value).toFixed(2);
  }
  return (
    <div>
      {showViewCaseHistory && <Modal icon={<FontAwesomeIcon icon={faEye} />} hide={() => setViewCaseHistory(false)} title="View Task" className="modal-dialog modal-dialog-centered" footer={
        <>
          <Button size='medium' variant='outlined' name='Close' onClick={() => setViewCaseHistory(false)}></Button>
          <Button type='submit' size='medium' variant='contained' name='Edit Case History'  ></Button>
        </>
      }></Modal>}
      <CustomGrid data={historyData} onRowDblClick={mobile ? onRowDblClick : null} mobileView={mobile ? DataRow : null} id="gridContainer" onExporting={onExporting} contextMenu={addMenuItems} columnAutoWidth={true} wordWrapEnabled={true}>
        <Editing mode="cell" allowUpdating={true} />
        <FilterRow visible={!mobile} />
        <HeaderFilter visible={true} />
        <SearchPanel visible={true} />
        <Scrolling columnRenderingMode="virtual" />
        <Sorting mode="none" />
        <Paging enabled={false} />
        <StateStoring enabled={!mobile} type="custom" customLoad={loadState} customSave={saveState} />
        <Column caption="Branch" dataField="branch" dataType="string" allowEditing={false} visible={!mobile}></Column>
        <Column caption="Date" calculateCellValue={getDate} dataType="date" ></Column>
        <Column caption="Time" dataField="timeStamp" dataType="datetime" format="HH:mm: a"></Column>
        <Column caption="Fee Earner ID" dataField="feeEarnerID" dataType="string" >
          <Lookup dataSource={feeEarner} /></Column>
        <Column caption="Narrative" dataField="narrative" dataType="string" visible={!mobile}></Column>
        <Column dataField="subFolder" caption="Sub Folder" visible={!mobile} ><Lookup dataSource={subFolder} /></Column>
        <Column caption="Quantity" dataField="quantity" calculateCellValue={(rowData) => formatToDecimal(rowData.quantity)} visible={!mobile}  ></Column>
        <Column caption="No Of Units" dataField="noOfUnits" visible={!mobile} ></Column>
        <Column caption="Fixed Fee Entry" cellRender={FixedFeeEntyCheckboxCell} visible={!mobile}></Column>
        <Column caption="Cost" dataField="cost" allowEditing={false} calculateCellValue={(rowData) => formatToDecimal(rowData.cost)} visible={!mobile}></Column>
        <Column caption="File" cellRender={FileCell} visible={!mobile}></Column>
        <Column caption="Minutes" dataField="activeTimeSeconds" calculateCellValue={(rowData) => formatSecondsToMinutesAndSeconds(rowData.activeTimeSeconds)} allowEditing={false}  ></Column>
        <Column caption="Elapsed Minutes" dataField="elapsedTimeSeconds" dataType="time" calculateCellValue={(rowData) => formatSecondsToMinutesAndSeconds(rowData.elapsedTimeSeconds)} allowEditing={false} visible={!mobile}></Column>
        <Export enabled={true} />
        <ColumnChooser enabled={true}>
          <Position
            my="right top"
            at="right bottom"
            of=".dx-datagrid-column-chooser-button"
          />
        </ColumnChooser>

      </CustomGrid>
    </div>
  )
}
