import React, { useState, useCallback, useEffect, useMemo, memo } from "react";
import "./case-task.css";
import { CheckBox } from "devextreme-react";
import CustomGrid from "../../controls/grid";
import { useScreenSize } from "../../../hooks/useScreenSize";
import Modal from "../../modals/custom-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import Button from 'devextreme-react/button';
import { setToLS } from "../../../utils/helpers/storage";
import { getFromLS } from "../../../utils/helpers/storage";
import { tabSuffix, desktopSuffix } from "../../../utils/constants/storageKeys";
import { Mobile, Desktop, Tab } from "../../../utils/constants/devices";
import { CaseDetailTab } from "../../case-detail/case-detail-tab";
import { locale } from "devextreme/localization";
import { useDispatch } from "react-redux";
import { openModal } from "../../features/modal-slice";
import { EDIT_TASK } from "../../../utils/constants/modal-name";
import { UPDATE } from "../../../utils/constants/mode";
import { triggerWarning } from "../../features/warning-modal-slice";
import {
  TASKTYPE_COMPLETEDHISTORICALCASEDATES,
  TASKTYPE_CASECLOSURE,
} from "../../../utils/constants/task-type";
import { TASKSTATUS_COMPLETED, TASKSTATUS_NOTCOMPLETED } from "../../../utils/constants/task-status";
import {
  postComplete,
  postUnComplete,
  taskByID,
} from "../../../services/task-service";
import { useAuth } from "../../../hooks/useAuth";
import {
  Column,
  Paging,
  HeaderFilter,
  ColumnChooser,
  Position,
  StateStoring,
  SearchPanel,
  Scrolling,
  TotalItem,
  Summary,
  LoadPanel,
  FilterPanel
} from "devextreme-react/data-grid";
const CaseTaskGrid = ({
  storeKey,
  homeView,
  taskData,
  homeViewHiddenColumn,
  children,
  setTaskData
}) => {
  const [showViewTask, setShowViewTask] = useState(false);
  const [caseId, setCaseId] = useState(null);
  const [userName, setUserName] = useState(null);
  const [clientName, setClientName] = useState(null);
  const [priority, setPriority] = useState(null);
  const [task, setTask] = useState(null);
  const [appointmentNotes, setAppointmentNotes] = useState(null);
  const [dueDate, setDueDate] = useState(null);
  const [completedDate, setCompletedDate] = useState(null);
  const [useColoursForTasks, setUseColoursForTasks] = useState("");
  const { user } = useAuth();
  const UserID = user.id;
  const device = useScreenSize();
  const dispatch = useDispatch();
  useEffect(() => {
    const systemSettingData = getFromLS("SystemSettings");
    if (systemSettingData) {
      const filteredData = systemSettingData.filter(
        (parameter) => parameter.parameterID === "UseColoursForTasks"
      );
      if (filteredData.length > 0) {
        setUseColoursForTasks(filteredData[0].parmChar);
      }
    }
    locale("en-GB");
  }, [storeKey, taskData]);
  let storageKey;
  let mobile = false;
  if (device === Tab) {
    storageKey = storeKey + tabSuffix;
    mobile = false;
  } else if (device === Desktop) {
    storageKey = storeKey + desktopSuffix;
    mobile = false;
  } else if (device === Mobile) {
    mobile = true;
  }
  const onRowDblClick = async (e) => {
    const rowData = e.data;
    // Perform any additional actions you want on row double-click
    setCaseId(rowData.CaseId);
    setUserName(rowData.User);
    setClientName(rowData.Client);
    setPriority(rowData.Priority);
    setTask(rowData.Task);
    setAppointmentNotes(rowData.AppointmentNotes);
    setDueDate(rowData.DueDate);
    setCompletedDate(rowData.CompletedDate);
    if (rowData.caseID !== null) {
      if (homeView) {
        const result = await CaseDetailTab(rowData.caseID);
        if (!result.success) {
          dispatch(triggerWarning({ openWarning: true, data: result }))
        }
      } else {
        if (!mobile) {
          CallTaskByIDApi(rowData.id);
        } else {
          setShowViewTask(true);
        }
      }
    }
    else {
      dispatch(openModal({ modalName: EDIT_TASK, mode: UPDATE, data: rowData }))
    }
  };
  const renderCell = (e) => {
    const currentDate = new Date(); // Get the current date
    const dueDate = new Date(e.data?.dueDate); // Get the due date from the cell value
    const teamID = e.data?.TeamID;
    const ID = e.data?.id;
    const userID = e.data?.userID;
    const caseID = e.data?.CaseId;
    const taskType = e.data?.type;
    const loggedInUserID = userID; // Replace with the actual logged-in user ID
    const loggedInUserTeams = [1, 2, 3]; // Replace with the list of team IDs from the logged-in user's profile
    const loggedInUserDelegateUserIDs = ["AMR", "789"]; // Replace with the list of delegated user IDs from the logged-in user's profile
    const gAllowCompleteCaseTasksFromDashboard = true; // Replace with the actual value
    const Supervisor = true;
    e.rowElement.setAttribute("data-custom-key", ID);
    if ( useColoursForTasks.toLowerCase() === "true") {
      if (!dueDate || dueDate < currentDate) {
        e.rowElement.classList.add("red-cell");
      } else if (dueDate && dueDate < getFutureDate(7)) {
        e.rowElement.classList.add("yellow-cell");
      } else if (dueDate && dueDate > getFutureDate(7)) {
        e.rowElement.classList.add("green-cell");
      }
    }
    if (e.data && e.data.status === TASKSTATUS_COMPLETED) {
      e.rowElement.classList.add("strike-through");
    } else {
      e.rowElement.classList.remove("strike-through");
    }
    // Check if it's a team task and the user is not a member of this team
    if (teamID !== null && teamID > 0) {
      if (!loggedInUserTeams.includes(teamID)) {
        e.rowElement.classList.add("strike-through");
        console.log("Task allocated to a team which you are not a member of");
      }
    }
    // Check if the task doesn't belong to the logged-in user, supervisor, or delegate
    else {
      const delegateForUser = loggedInUserDelegateUserIDs.includes(userID);
      if (!(loggedInUserID === userID || Supervisor || delegateForUser)) {
        console.log(
          "Task not allocated to you and you are not a delegate of the task assignee"
        );
      }
    }
    // Don't allow checking a task if it's linked to a case
    if (!gAllowCompleteCaseTasksFromDashboard && caseID !== null) {
      console.log(
        "Task linked to a case and must be completed by opening the case"
      );
    }
    // Don't allow changing check status of historical case date tasks
    if (taskType === TASKTYPE_COMPLETEDHISTORICALCASEDATES) {
      console.log("Can't change status of a historical case date task");
    }
    // Don't allow checking of a case closure task. These are auto-completed when the case is closed
    if (taskType === TASKTYPE_CASECLOSURE) {
      console.log(
        "Status of Case Closure Task cannot be manually changed. It will be automatically completed when the case is closed"
      );
    }
    if (
      e.data &&
      e.data.userID !== null &&
      e.data.userID !== UserID.toUpperCase()
    ) {
      e.rowElement.classList.add("italic-text"); // Add a CSS class for italic style
    }
  };
  const getFutureDate = useCallback((days) => {
    const currentDate = new Date();
    const futureDate = new Date(currentDate.getTime() + days * 24 * 60 * 60 * 1000);
    return futureDate;
  }, []);
  const handleCheckboxChange = (value, data) => { };

  const strikeCheckboxChange = useCallback((value, data) => {
    const strikeRowData = data;
    const rowIndex = document?.querySelector(`[data-custom-key="${data.id}"]`);

    if (rowIndex) {
      if (value.value) {
        CallPostCompleteApi(strikeRowData.id);
        rowIndex.classList.add('strike-through');
      } else {
        CallPostUnCompleteApi(strikeRowData.id);
        rowIndex.classList.remove('strike-through');
      }
    }
    const updateData = (prevData) => {
      const newData = [...prevData];
      const itemIndex = newData.findIndex(item => item.id === data.id);
      if (itemIndex !== -1) {
        newData[itemIndex] = {
          ...newData[itemIndex], status: value.value ? TASKSTATUS_COMPLETED : TASKSTATUS_NOTCOMPLETED,
          completedDate: value.value ? new Date() : null,
        };
      }

      return newData;
    };
    setTaskData(updateData);
  }, []);

  const CallTaskByIDApi = async (id) => {
    try {
      const editTask = await taskByID(id);
      const editTaskData = editTask.data.result;
      dispatch(openModal({ modalName: EDIT_TASK, mode: UPDATE, data: editTaskData }))
    } catch (error) {
      console.error(error);
    }
  };
  const CallPostCompleteApi = async (id) => {
    try {
      await postComplete(id);
    } catch (error) {
      console.error(error);
    }
  };
  const CallPostUnCompleteApi = async (id) => {
    try {
      await postUnComplete(id);
    } catch (error) {
      console.error(error);
    }
  };
  const deleteCheckboxCell = useCallback((props) => {
    const { data } = props;
    return (
      <>
        <div className="checkbox-cell">
          <CheckBox
            onValueChanged={(value) => handleCheckboxChange(value, data)}
          />
        </div>
      </>
    );
  }, [handleCheckboxChange]);
  const strikeCheckboxCell = useCallback((props) => {
    const { data } = props;
    return (
      <div className="checkbox-cell">
        <CheckBox
          defaultValue={data.status === TASKSTATUS_COMPLETED ? true : false}
          onValueChanged={(value) => strikeCheckboxChange(value, data)}
        />
      </div>
    );
  }, [strikeCheckboxChange]);
  function DataRow(e) {
    return (
      <React.Fragment>
        <tr className="main-row">
          <td>{strikeCheckboxCell(e)}</td>
          <td>{e.data.linkedDescription}</td>
          <td>{e.data.clientName}</td>
          <td>{e.data.caseID}</td>
          <td>{e.data.dueDate}</td>
          <td>{deleteCheckboxCell(e)}</td>
        </tr>
        <tr className="notes-row">
          <td colSpan="6">
            <div>{e.data.task}</div>
          </td>
        </tr>
      </React.Fragment>
    );
  }
  const data = useMemo(() => [
    { label: "Case ID:", value: caseId },
    { label: "User:", value: userName },
    { label: "Client:", value: clientName },
    { label: "Priority:", value: priority },
    { label: "Task:", value: task },
    { label: "Appointment Notes:", value: appointmentNotes },
    { label: "Due Date:", value: dueDate },
    { label: "Completed Date:", value: completedDate ? completedDate.toString() : null },
  ], [caseId, userName, clientName, priority, task, appointmentNotes, dueDate, completedDate]);
  const loadState = useCallback(() => {
    return getFromLS(storageKey);
  }, [storageKey]);
  const saveState = useCallback((state) => {
    setToLS(storageKey, state);
  }, [storageKey, storeKey]);
  return (
    <>
      {showViewTask && (
        <Modal
          icon={<FontAwesomeIcon icon={faEye} />}
          hide={() => setShowViewTask(false)}
          title="View Task"
          className="modal-dialog modal-dialog-centered"
          footer={
            <>
              <Button
                size="medium"
                variant="outlined"
                name="Close"
                onClick={() => setShowViewTask(false)}
              ></Button>
              <Button
                type="submit"
                size="medium"
                variant="contained"
                name="Edit Task"
                onClick={() => {
                  setShowViewTask(false);
                }}
              ></Button>
            </>
          }
        >
          <div>
            {data.map((item, index) => (
              <div className="data-field" key={index}>
                <h6 className="heading">{item.label}</h6>
                <p className="paragraph">{item.value}</p>
              </div>
            ))}
          </div>
        </Modal>
      )}
      <CustomGrid
        data={taskData}
        onRowDblClick={onRowDblClick}
        dataRow={renderCell}
        mobileView={mobile ? DataRow : null}
        id="taskgridContainer"
        keyExpr="id"
        columnAutoWidth={true}
        wordWrapEnabled={true}
        height="90vh"

      >

        <Paging defaultPageSize={100} />
        <Paging enabled={false} />
        <Scrolling mode="infinite" />
        <LoadPanel enabled={false} />
        <HeaderFilter visible={true} />
        <FilterPanel visible={true} />
        <SearchPanel visible placeholder='Task Search' />

        <StateStoring
          enabled={!mobile}
          type="custom"
          customLoad={loadState}
          customSave={saveState}
        />
        {children}
        <Column caption="" cellRender={strikeCheckboxCell} />
        <Column
          dataField="linkedDescription"
          caption="Reference"
          dataType="string"
          visible={homeViewHiddenColumn}
        />
        <Column
          dataField="userID"
          caption="User"
          dataType="string"
          width={mobile ? 60 : null}
          visible={homeViewHiddenColumn}
        />
        <Column
          dataField="clientName"
          caption="Client"
          dataType="string"
          width={mobile ? 70 : null}
          visible={homeViewHiddenColumn}
        />
        <Column
          dataField="caseID"
          width={mobile ? 70 : null}
          visible={mobile}
        />
        <Column
          dataField="priority"
          caption="Priority"
          dataType="string"
          width={mobile ? 72 : null}
          visible={!mobile && !homeViewHiddenColumn}
        />
        <Column
          dataField="task"
          caption="Task"
          dataType="string"
          visible={!mobile}
        />
        <Column
          dataField="AppointementNotes"
          dataType="string"
          visible={!mobile}
        />
        <Column
          dataField="dueDate"
          dataType="date"
          width={mobile ? 65 : null}
        />
        <Column
          dataField="completedDate"
          dataType="date"
          width={mobile ? 100 : null}
          visible={!mobile}
        />
        <Column
          caption="Deleted"
          cellRender={deleteCheckboxCell}
          visible={homeViewHiddenColumn}
        />
        <ColumnChooser enabled={true}>
          <Position
            my="right top"
            at="right bottom"
            of=".dx-datagrid-column-chooser-button"
          />
        </ColumnChooser>
        <Summary>
          <TotalItem
            column="task"
            summaryType="count"
            displayFormat={"Number of Tasks:{0} "} // You can customize the display format as needed
          />
        </Summary>

      </CustomGrid>
    </>
  );
};

export default memo(CaseTaskGrid);
