import { httpClient } from "./httpclient";
const url = "v1/history";

const caseHistory = async (caseID, taskType,fromTime) => {
    try {
        const endpoint = `${url}/case/${caseID}`;
        return await httpClient.get(endpoint);
    } catch (error) {
        throw new Error(error.response.status);
    }
};
const historyByID = async (id) => {
    try {
        const endpoint = `${url}/${id}`;
        return await httpClient.get(endpoint);
    } catch (error) {
        throw new Error(error.response.status);
    }
};
const deleteHistory = async (id) => {
    try {
        const endpoint = `${url}/delete/${id}`;
        return await httpClient.post(endpoint);
    } catch (error) {
        throw new Error(error.response.status);
    }
};
const updateHistory = async (id, caseID, feeEarnerID, timeStamp, noOfUnits, startTime, endTime, narrative, typeofEntry, cost, fileName, branchID, quantity, unitCost, caseStatusAtTime, subFolder, activeTimeSeconds, elapsedTimeSeconds, fixedFeeEntry, typeOfWork, category1, category2, beAMail) => {
    var request = {
        id: id,
        caseID: caseID,
        feeEarnerID: feeEarnerID,
        timeStamp: timeStamp,
        noOfUnits: noOfUnits,
        startTime: startTime,
        endTime: endTime,
        narrative: narrative,
        typeofEntry: typeofEntry,
        cost: cost,
        fileName: fileName,
        branchID: branchID,
        quantity: quantity,
        unitCost: unitCost,
        caseStatusAtTime: caseStatusAtTime,
        subFolder: subFolder,
        activeTimeSeconds: activeTimeSeconds,
        elapsedTimeSeconds: elapsedTimeSeconds,
        fixedFeeEntry: fixedFeeEntry,
        typeOfWork: typeOfWork,
        category1: category1,
        category2: category2,
        beAMail: beAMail,
    };
    const endpoint = `${url}/update`;
    return await httpClient.post(endpoint, request);
};

export { caseHistory, historyByID, deleteHistory,updateHistory};