const branch = [
    {
        id : 1,
        name : "Birmingham"

    },
    {
        id : 2,
        name : "Derby"

    },
    {
        id : 3,
        name : "Notingham"

    }

]
export default branch;