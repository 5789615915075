import React from 'react';
import styled from 'styled-components';
import zxcvbn from 'zxcvbn';

export default function PasswordStrengthIndicator({ Password }) {
    const result = zxcvbn(Password);
    const score = result.score * 100 / 4;
    const ProgressColour = () => {
        switch (result.score) {
            case 0:
                return '#828282'
            case 1:
                return '#EA1111'
            case 2:
                return '#FFAD00'
            case 3:
                return '#9B1158'
            case 4:
                return '#00B500'
            default:
                return 'none'
        }
    }
    const CreatePassLabel = () => {
        switch (result.score) {
            case 0:
                return 'Very Weak'
            case 1:
                return 'Weak'
            case 2:
                return 'Fear'
            case 3:
                return 'Good'
            case 4:
                return 'Strong'
            default:
                return 'none'
        }
    }
    const ChangePasswordColor = () => ({
        width: `${score}%`,
        background: ProgressColour(),
        height: '7px'
    })
    return (
        <>
            <ProgressWrapper className='progress'>
                <div className='progress-bar' style={ChangePasswordColor()}>
                </div>
            </ProgressWrapper>
            <Paragraph style={{ color: ProgressColour() }}>{CreatePassLabel()}</Paragraph>
        </>
    )
}

const ProgressWrapper = styled.div`
  height:7px;
`;
const Paragraph = styled.p`
 text-align:right;
 margin:0px;
 
`;