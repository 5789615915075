import React, { useState } from 'react';
import EntityTypeTFormBView from '../../views/case-detail/entity-type-t-form-b-view';
import EntityTypeOFormView from '../../views/case-detail/entity-type-o-form-view';
import EntityTypetFormAView from '../../views/case-detail/entity-type-t-form-a-view';
import EntityTypeDFormView from '../../views/case-detail/entity-type-d-form-view';
import { DYNAMIC_ENTITYORG, DYNAMIC_ENTITYDETAILS, DYNAMIC_ENTITYPERSON, DYNAMIC_FIXEDDATA } from '../../utils/constants/entity-types';
import { ENTITYNAME_CASEHISTORY, ENTITYNAME_CASENOTE, ENTITYNAME_CASETASK, ENTITYNAME_CASEDATE, ENTITYNAME_CASELETTER, ENTITYNAME_CASESTATUS } from '../../utils/constants/entity-name';
import CaseHistorView from '../../views/case-detail/case-history-view';
import CaseDateView from '../../views/case-detail/case-date-view';
import CaseLetterView from '../../views/case-detail/case-letter-view';
import CaseNoteView from '../../views/case-detail/case-note-view';
import CaseStatusView from '../../views/case-detail/case-status-view';
import CaseTaskView from '../../views/case-detail/case-task-view';
import CardView from '../controls/card-view';
const DynamicCaseNavigation = ({itemData,caseType,caseSubType}) => {
  const [entityType] = useState(itemData.data.entityType);
  const [entityName] = useState(itemData.data.entityName);
  const [detailIndex] = useState(itemData.data.detailsIndex);
  const [caseID] = useState(itemData.data.caseID);
  const [orgID] = useState(itemData.data.orgID);
  const [entityID] = useState(itemData.data.id);
  const [selectFromList] = useState(itemData.data.selectFromList);
  const [casePersonID] = useState(itemData.data.casePersonID);
  const handleMenuItemClick = () => {
  }
  let componentToRender = null;
  switch (entityType) {
    case DYNAMIC_FIXEDDATA:
      switch (entityName) {
        case ENTITYNAME_CASEHISTORY:
          componentToRender = <CaseHistorView caseID={caseID} ></CaseHistorView>;
          break;
        case ENTITYNAME_CASEDATE:
          componentToRender =  <CaseDateView caseID={caseID}></CaseDateView>;
          break;
        case ENTITYNAME_CASELETTER:
          componentToRender = <CaseLetterView></CaseLetterView>;
          break;
        case ENTITYNAME_CASENOTE:
          componentToRender = <CaseNoteView></CaseNoteView>;
          break;
        case ENTITYNAME_CASESTATUS:
          componentToRender = <CaseStatusView ></CaseStatusView>;
          break;
        case ENTITYNAME_CASETASK:
          componentToRender = <CaseTaskView caseID={caseID} ></CaseTaskView>;
          break;
        default:
          // Handle any other cases for DYNAMIC_FIXEDDATA here
          break;
      }
      break;
    case DYNAMIC_ENTITYORG:
      componentToRender = <EntityTypeOFormView  caseType={caseType} caseSubType={caseSubType} key={entityName + detailIndex + caseID} entityType={entityType} entityName={entityName} detailIndex={detailIndex} caseID={caseID} orgID={orgID} entityID={entityID} casePersonID={casePersonID} ></EntityTypeOFormView>;
      break
    case DYNAMIC_ENTITYPERSON:
      if (selectFromList) {
        componentToRender = <EntityTypetFormAView key={entityName + detailIndex + caseID} entityType={entityType} entityName={entityName} detailIndex={detailIndex} caseID={caseID} orgID={orgID} entityID={entityID} casePersonID={casePersonID} selectFromList={selectFromList} ></EntityTypetFormAView>;
      }
      else {
        componentToRender = <EntityTypeTFormBView handleMenuItemClick={handleMenuItemClick} key={entityName + detailIndex + caseID} entityType={entityType} entityName={entityName} detailIndex={detailIndex} caseID={caseID} orgID={orgID} entityID={entityID} casePersonID={casePersonID} />;
      }
      break;
    case DYNAMIC_ENTITYDETAILS:
      componentToRender = <EntityTypeDFormView key={entityName + detailIndex + caseID} entityType={entityType} entityName={entityName} detailIndex={detailIndex} caseID={caseID} orgID={orgID} entityID={entityID} casePersonID={casePersonID} />;
      break;
    default:
      // Render a default component if none of the above cases match
      break;
  }
  return (
<CardView>{componentToRender}</CardView>
      
   
  );
};

export default DynamicCaseNavigation;
