import React, { useEffect, useRef } from 'react';
import 'devexpress-richedit/dist/dx.richedit.css';
import { create, createOptions, RichEdit, DocumentFormat,Interval,Size  } from 'devexpress-richedit';
export default function RichEditDocument({ fieldName, showRibbon, height }) {
  const richEditRef = useRef(null);
  useEffect(() => {
    if (!richEditRef.current) {
      const options = createOptions();
      options.fields.updateFieldsBeforePrint = true;
      options.fields.updateFieldsOnPaste = true;
      options.mailMerge.activeRecord = 2;
      options.mailMerge.viewMergedData = true;
      options.mailMerge.dataSource = [
        { case_BranchName: 'Derby', case_AccidentDate: '20/7/2023' },
        // Add more data objects as needed
      ];
      options.height = height;
      options.ribbon.visible = showRibbon;
      richEditRef.current = create(document.getElementById('richEdit'), options);
      const documentAsBase64 =
        'e1xydGYxXGRlZmYwe1xmb250dGJse1xmMCBDYWxpYnJpO319e1xjb2xvcnRibCA7XHJlZDB' +
        'cZ3JlZW4wXGJsdWUyNTUgO1xyZWQyNTVcZ3JlZW4yNTVcYmx1ZTI1NSA7fXtcKlxkZWZjaHAgXGZzMjJ9e1' +
        'xzdHlsZXNoZWV0IHtccWxcZnMyMiBOb3JtYWw7fXtcKlxjczFcZnMyMiBEZWZhdWx0IFBhcmFncmFwaCBG' +
        'b250O317XCpcY3MyXGZzMjJcY2YxIEh5cGVybGluazt9e1wqXHRzM1x0c3Jvd2RcZnMyMlxxbFx0c3Zl' +
        'cnRhbHRcdHNjZWxsY2JwYXQyXHRzY2VsbHBjdDBcY2x0eGxydGIgTm9ybWFsIFRhYmxlO319e1wqXGxp' +
        'c3RvdmVycmlkZXRhYmxlfXtcaW5mb31cbm91aWNvbXBhdFxzcGx5dHduaW5lXGh0bWF1dHNwXGV4cHNocn' +
        'RuXHNwbHRwZ3BhclxkZWZ0YWI3MjBcc2VjdGRcbWFyZ2xzeG4xNDQwXG1hcmdyc3huMTQ0MFxtYXJndHN4' +
        'bjE0NDBcbWFyZ2JzeG4xNDQwXGhlYWRlcnk3MjBcZm9vdGVyeTcyMFxwZ3dzeG4xMjI0MFxwZ2h' +
        'zeG4xNTg0MFxjb2xzMVxjb2xzeDcyMFxwYXJkXHBsYWluXHFse1x' +
        'mczIyXGNmMFxjczEgRG9jdW1lbnQgdGV4dH1cZnMyMlxjZjBccGFyfQ==';
      richEditRef.current.openDocument(documentAsBase64, 'DocumentName', DocumentFormat.Rtf);
      
    }
    handleCreateMergeField();
  }, [fieldName]);

  const handleCreateMergeField = () => {
    if (fieldName != null) {
      const mergeFieldName = fieldName; // Replace with the desired field name
      const fieldCode = `MERGEFIELD ${mergeFieldName}`;
      const currentPosition = richEditRef.current.selection.active;
      richEditRef.current.document.fields.create(currentPosition, fieldCode);
      console.log('Current Position:', currentPosition);
    }
  };
  return (
    <div>
      <div id="richEdit"></div>
    </div>
  );
}
