import React, { useState, useEffect } from 'react'
import TextInput from '../controls/textinput'
import TextArea from '../controls/textarea'
import Dropdown from '../controls/drop-down'
import Datepicker from '../controls/datepicker'
import CheckBox from '../controls/checkbox'
import styled from 'styled-components';
import Button from '../controls/button'
import DateTimePickerView from '../controls/datetime-picker-view'
import { updateTask } from '../../services/task-service'
import { ModalAnimation } from '../../utils/styles/animation-styles'
import { ADD, DELETE, UPDATE } from '../../utils/constants/mode'
import { useDispatch } from 'react-redux'
import { closeModal } from '../features/modal-slice'
import { triggerAlert } from '../features/alert-slice'
import Modal from './custom-modal'
export default function TaskForm({ editTaskData, mode, taskAssigneeOption, standardTaskOption, taskStatusOption, priorityOption, sharedCalendarOption, hide, name, paramUserID, icon }) {
    const [task, setTask] = useState(null);
    const [caseID, setCaseID] = useState(0);
    const [id, setID] = useState(0);
    const [type, setType] = useState('C');
    const [userID, setUserID] = useState(paramUserID);
    const [status, setStatus] = useState("N");
    const [priority, setPriority] = useState("H");
    const [addedTime, setAddedTime] = useState(null);
    const [addedUserID, setAddedUserID] = useState(null);
    const [dueDate, setDueDate] = useState(null);
    const [standardItems, setStandardItems] = useState("");
    const [startDate, setStartDate] = useState(null);
    const [showAdditionalInformation, setAdditionalInformation] = useState(false);
    const [expireOnOpen, setExpireOnOpen] = useState(false);
    const [isAutoReminder, setIsAutoReminder] = useState(true);
    const [completedDate, setCompletedDate] = useState(null);
    const [deletedTime, setDeletedTime] = useState(null);
    const [deletedUserID, setDeletedUserID] = useState(null);
    const [completedUserID, setCompletedUserID] = useState(null);
    const [appointmentNotes, setAppointmentNotes] = useState(null);
    const [isOverdue, setIsOverdue] = useState(true);
    const [reminderDate, setReminderDate] = useState(null);
    const [deleted, setDeleted] = useState(false);
    const [clientName, setClientName] = useState(null);
    const [appointmentID, setAppointmentID] = useState(0);
    const [customDateID, setCustomDateID] = useState(null);
    const [sharedCalendars, setSharedCalendars] = useState([]);
    const [teamID, setTeamID] = useState(0);
    const [linkedReference, setLinkedReference] = useState(null);
    const [linkedDescription, setLinkedDescription] = useState(null);
    const [shiftToTeamID, setShiftToTeamID] = useState(false);
    const dispatch = useDispatch()
    useEffect(() => {
        if (editTaskData) {
            setID(editTaskData.id)
            setStatus(editTaskData.status);
            setType(editTaskData.type);
            setUserID(editTaskData.userID);
            setCaseID(editTaskData.caseID);
            setTask(editTaskData.task);
            setPriority(editTaskData.priority);
            setStartDate(editTaskData.startDate);
            setDueDate(editTaskData.dueDate);
            setCompletedDate(editTaskData.completedDate);
            setCompletedUserID(editTaskData.completedUserID)
            setIsOverdue(editTaskData.isOverdue);
            setIsAutoReminder(editTaskData.isAutoReminder)
            setReminderDate(editTaskData.reminderDate)
            setClientName(editTaskData.clientName)
            setExpireOnOpen(editTaskData.expireOnOpen);
            setDeleted(editTaskData.deleted);
            setDeletedTime(editTaskData.deletedTime)
            setDeletedUserID(editTaskData.deletedUserID);
            setAppointmentID(editTaskData.appointmentID);
            setCustomDateID(editTaskData.customDateID);
            if (editTaskData.sharedCalendars === null) {
                setSharedCalendars([]);
            } else {
                setSharedCalendars(editTaskData.sharedCalendars.split('|').map(str => str.trim()));
            }
            setAppointmentNotes(editTaskData.appointmentNotes)
            setAddedTime(editTaskData.addedTime);
            setAddedUserID(editTaskData.addedUserID);
            setTeamID(editTaskData.teamID)
            setLinkedReference(editTaskData.linkedReference);
            setLinkedDescription(editTaskData?.linkedDescription);
        }
        if (teamID) {
            setShiftToTeamID(true);
        }
        else {
            setShiftToTeamID(false);
        }
    }, [editTaskData]);
    const handleInputChange = (event, setStateFunction) => {
        const { value } = event.target;
        setStateFunction(value);
    };
    const handleDropDownChange = (data, setStateFunction) => {
        if (data.id) {
            if (data.isATeam) {
                setTeamID(data.id);
                setShiftToTeamID(true);
                setStateFunction(null);
            }
            else {
                setTeamID(null);
                setShiftToTeamID(false);
                setStateFunction(data.id);
            }
        } else if (data.parameterID) {
            setStateFunction(data.parameterID);
        }
    };
    const handleSharedCalendarDropDown = (selectedValue) => {
        setSharedCalendars(selectedValue);
    };
    const handleChecboxChange = (event, setStateFunction) => {
        const { checked } = event.target;
        setStateFunction(checked);
    };
    const handleDateChange = (event, setStateFunction) => {
        const parsedDate = new Date(event);
        const formattedDate = parsedDate.toISOString();
        setStateFunction(formattedDate);
    };
    const handleStandardItemChange = (event, setStateFunction) => {
        const parameterID = event.parameterID;
        const parameterCharacter = event.parmChar;
        setStateFunction(parameterID);
        setTask(parameterCharacter);
    };
    function getMessageForTaskMode(mode) {
        if (mode === ADD) {
            return "Created Successfully";
        } else if (mode === DELETE) {
            return "Deleted Successfully";
        } else if (mode === UPDATE) {
            return "Updated Successfully";
        }
    }
    const CallUpdateTaskAPI = async () => {
        let filtersharedCalendar;
        if (sharedCalendars === null) {
            filtersharedCalendar = editTaskData.sharedCalendars;
        }
        else {
            filtersharedCalendar = sharedCalendars.join('|');
        }
        try {
            const updatetask = await updateTask(
                id,
                status,
                type,
                userID,
                caseID,
                task,
                priority,
                startDate,
                dueDate,
                completedDate,
                completedUserID,
                isOverdue,
                isAutoReminder,
                reminderDate,
                clientName,
                expireOnOpen,
                deleted,
                deletedTime,
                deletedUserID,
                appointmentID,
                customDateID,
                filtersharedCalendar,
                appointmentNotes,
                addedTime,
                addedUserID,
                teamID,
                linkedReference,
                linkedDescription
            );
            if (updatetask.data.returnCode == 1) {
                closeTaskModal();
                let message = `Task ${getMessageForTaskMode(mode)}`;
                dispatch(triggerAlert({ openAlert: true, severity: "success", alertMessage: message }))
            }
        }
        catch (error) {
            console.error(error);
        }
    };
    const handleFormSubmit = (event) => {
        CallUpdateTaskAPI();
        event.preventDefault();
    };
    const closeTaskModal = () => {
        dispatch(closeModal())
    };
    return (
        <ContainerWrapper>

            <Modal handleFormSubmit={handleFormSubmit} icon={icon} hide={closeTaskModal} title={name} className="modal-dialog  modal-lg " footer={
                <>
                    <Button size='medium' variant='outlined' name='Close' onClick={closeTaskModal}></Button>
                    <Button type='submit' size='medium' variant='contained' name={name}></Button>
                </>
            }>
                <div className="row g-3">
                    <div className='col-lg-6 col-md-6 col-6'>
                        <Dropdown label="Assign to" id="Assign-to" value={shiftToTeamID ? teamID : userID} customPropertyName="id" displayPropertyName="name" options={taskAssigneeOption} onChange={(event) => handleDropDownChange(event, setUserID)}></Dropdown>
                    </div>
                    <div className='col-lg-6 col-md-6 col-6'>
                        <TextInput label="Case ID" id="Task-case-Id" variant="standard" value={caseID} onChange={(event) => handleInputChange(event, setCaseID)}></TextInput>
                    </div>
                    {mode === ADD ?
                        <div className='col-lg-12'>
                            <Dropdown label="Standard items" id="Standard-items" customPropertyName="parameterID" displayPropertyName="parmChar" options={standardTaskOption} value={standardItems} onChange={(event) => handleStandardItemChange(event, setStandardItems)}></Dropdown>
                        </div>
                        : null}
                    <div className='col-lg-12'>
                        <TextArea label="Task" id="Task" row="6" value={task} required={true} onChange={(event) => handleInputChange(event, setTask)}></TextArea>
                    </div>
                    <div className='col-lg-6 col-md-6 col-6'>
                        <Dropdown label="Status" id="Status" customPropertyName="parameterID" displayPropertyName="parmChar" options={taskStatusOption} value={status} onChange={(event) => handleDropDownChange(event, setStatus)}></Dropdown>
                    </div>
                    <div className='col-lg-6 col-md-6 col-6'>
                        <Dropdown label="Priority" id="Priority" customPropertyName="parameterID" displayPropertyName="parmChar" options={priorityOption} value={priority} onChange={(event) => handleDropDownChange(event, setPriority)} ></Dropdown>
                    </div>
                    <div className='col-lg-6 col-md-6 col-6'>
                        <Datepicker label="Start Date" id="Start-Date" value={startDate} onChange={(event) => handleDateChange(event, setStartDate)} ></Datepicker>
                    </div>
                    <div className='col-lg-6 col-md-6 col-6'>
                        <DateTimePickerView label="Due Date" id="Due-Date" required={true} value={dueDate} onChange={(event) => handleDateChange(event, setDueDate)} use12HourFormat={false}></DateTimePickerView>
                    </div>
                    <div className='col-lg-6 col-md-6 col-6' onClick={() => setAdditionalInformation(!showAdditionalInformation)}>
                        <Button name="Additional Information" variant="text"></Button>
                    </div>
                    <div className='col-lg-6 col-md-6 col-6'>
                        <CheckBox label="Auto-Complete On Open" checked={expireOnOpen} onChange={(event) => handleChecboxChange(event, setExpireOnOpen)}></CheckBox>
                    </div>
                    {showAdditionalInformation ?
                        <>
                            {mode !== ADD &&
                                <>
                                    <div className='col-lg-6 col-md-6 col-6'>
                                        <DateTimePickerView label="Added" id="Added" readOnly={true} value={addedTime} ></DateTimePickerView>
                                    </div>
                                    <div className='col-lg-6 col-md-6 col-6'>
                                        <TextInput label="Added by User" variant="standard" readOnly={true} value={addedUserID}></TextInput>
                                    </div>
                                    <div className='col-lg-6 col-md-6 col-6'>
                                        <DateTimePickerView label="Completed" id="Completed" readOnly={true} value={completedDate}></DateTimePickerView>
                                    </div>
                                    <div className='col-lg-6 col-md-6 col-6'>
                                        <TextInput label="Completed by User" variant="standard" readOnly={true} value={completedUserID}></TextInput>
                                    </div>
                                    <div className='col-lg-6 col-md-6 col-6'>
                                        <DateTimePickerView label="Deleted" id="Deleted" readOnly={true} value={deletedTime} ></DateTimePickerView>
                                    </div>
                                    <div className='col-lg-6 col-md-6 col-6'>
                                        <TextInput label="Deleted by User" variant="standard" readOnly={true} value={deletedUserID}></TextInput>
                                    </div>
                                </>
                            }
                            <div className='col-lg-6 col-md-6 col-6'>
                                <CheckBox label="Add to Calender" checked={isAutoReminder} onChange={(event) => handleChecboxChange(event, setIsAutoReminder)}></CheckBox>
                            </div>
                            <div className='col-lg-6 col-md-6 col-6'>
                                <Dropdown label="Shared Calender" id="Shared-Calender" customPropertyName="parmChar" displayPropertyName="parmChar" options={sharedCalendarOption} multiple={true} value={sharedCalendars} onChange={handleSharedCalendarDropDown}></Dropdown>
                            </div>
                            <div className='col-lg-12'>
                                <TextArea label="Appointment Notes" id="Appointement-Notes" row="3" value={appointmentNotes} onChange={(event) => handleInputChange(event, setAppointmentNotes)}></TextArea>
                            </div>
                        </> : null}
                </div>
            </Modal>
        </ContainerWrapper>
    )
}
const ContainerWrapper = styled.div`
    ${ModalAnimation}
  
`;