const date = [{
    id: '1',
    Event: 'Defedant may challenged the schedule off Loss',
    Check: "true",
    Completed:"true",
    Date: "6/7/2023",
    CalendarAppointementNotes: "",
    SharedCalander: ""

},
{
    id: '2',
    Event: 'Pre-Trail Checklist',
    Completed:"false",
    Check: "false",
    Date: "",
    CalendarAppointementNotes: "",
    SharedCalander: ""

},
{
    id: '3',
    Event: 'Hearing Fee To be paid By',
    Completed:"false",
    Check: "false",
    Date: "",
    CalendarAppointementNotes: "",
    SharedCalander: ""

},
{
    id: '4',
    Event: 'Trail Bundle Due By',
    Completed:"false",
    Check: "true",
    Date: "",
    CalendarAppointementNotes: "",
    SharedCalander: ""

},
{
    id: '5',
    Event: 'Disclosure of Documents By List',
    Completed:"false",
    Check: "true",
    Date: "",
    CalendarAppointementNotes: "",
    SharedCalander: ""

},
{
    id: '6',
    Event: 'Standard Disclosure By Documents',
    Check: "true",
    Completed:"true",
    Date: "6/7/2023",
    CalendarAppointementNotes: "",
    SharedCalander: "",
    cloned :""

},
{
    id: '7',
    Event: 'Exchange Of Witness Statement',
    Completed:"false",
    Check: "true",
    Date: "",
    CalendarAppointementNotes: "",
    SharedCalander: ""

},
{
    id: '8',
    Event: 'Raise Written Questions to the Expert By',
    Completed:"false",
    Check: "true",
    Date: "",
    CalendarAppointementNotes: "",
    SharedCalander: ""

},
{
    id: '9',
    Event: 'Responce to Written Questions to the Expert By',
    Completed:"false",
    Check: "true",
    Date: "",
    CalendarAppointementNotes: "",
    SharedCalander: ""

},
{
    id: '10',
    Event: 'Hearing Fee to be Paid By',
    Completed:"false",
    Check: "true",
    Date: "",
    CalendarAppointementNotes: "",
    SharedCalander: ""

},
{
    id: '11',
    Event: 'Trail Bundle Due By',
    Completed:"false",
    Check: "true",
    Date: "",
    CalendarAppointementNotes: "",
    SharedCalander: ""

},
]
let maxID = date[date.length - 1].id;

export default {
  getMaxID() {
    maxID += 1;
    return maxID;
  },
  getEmployees() {
    return date;
  },

 
};
