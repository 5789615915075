import React, { useState, useCallback, useEffect } from 'react';
import date from '../../json/case-date';
import "./case-date.css"
import TagBox from 'devextreme-react/tag-box';
import PlusButton from 'devextreme-react/button';
import Modal from '../../modals/custom-modal';
import Button from '../../controls/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { faFile } from '@fortawesome/free-solid-svg-icons';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { setToLS } from '../../../utils/helpers/storage';
import { getFromLS } from '../../../utils/helpers/storage';
import { useScreenSize } from '../../../hooks/useScreenSize';
import { Column, Paging, Pager, SearchPanel, FilterRow, HeaderFilter, Editing, Lookup, ColumnChooser, StateStoring, Position } from 'devextreme-react/data-grid';
import CustomGrid from '../../controls/grid';
import { Calendar } from 'devextreme-react';
import { tabSuffix, desktopSuffix } from '../../../utils/constants/storageKeys';
import { Mobile, Desktop, Tab } from '../../../utils/constants/devices';
import { locale } from 'devextreme/localization';
const products = ['Court', 'Court Aviation'];
const allowedPageSizes = [12, 25, 50, 100];
const customersData = ['Court', 'Court Aviation'];
function CaseDatesGrid({ storeKey, caseDateData }) {
  const [value, setValue] = useState(date.getEmployees());
  const [event, setEvent] = useState(null);
  const [showdate, setDate] = useState(null);
  const [appointmentNotes, setAppointmentNotes] = useState(null);
  const [sharedCalander, setSharedCalander] = useState(null);
  const [showViewDate, setViewDate] = useState(false);
  const [defaultSelectedTaskAssignee, setDefaultSelectedTaskAssignee] = useState(customersData[0]);
  const device = useScreenSize();
  let storageKey;
  let mobile = false;
  if (device === Tab) {
    storageKey = storeKey + tabSuffix
    mobile = false;
  }
  else if (device === Desktop) {
    storageKey = storeKey + desktopSuffix
    mobile = false;
  }
  else if (device === Mobile) {
    mobile = true;
  }
  useEffect(() => {
    locale("en-GB");

  }, []);
  function DataRow(e) {
    return (
      <React.Fragment>
        <tr className="main-row">
          <td>{AddButton(e)}</td>
          <td>{e.data.Date}</td>
          <td>{e.data.SharedCalander}</td>
          <td>{e.data.Name}</td>
        </tr>
        <tr className="notes-row">
          <td colSpan="4
          "><div>{EventCheck(e)}</div></td>
        </tr>
      </React.Fragment>
    );
  }
  const onRowDblClick = (e) => {
    // Extract data from the row clicked
    const rowData = e.data;
    // Set the state using the extracted data
    setEvent(rowData.Event)
    setDate(rowData.Date)
    setAppointmentNotes(rowData.CalendarAppointementNote)
    setSharedCalander(rowData.SharedCalendar)
    // Set viewDate to true to show the date details
    setViewDate(true);
  };
  const cloneIconClick = (rowData) => {
    // Check if row is already cloned

    if (rowData.cloned) {
      return;
    }

    // Create a new item with some properties from the clicked row
    const newItem = {
      id: date.getMaxID(),
      Event: rowData.Event,
      Date: null,
      CalendarAppointementNote: '',
      SharedCalendar: '',
      Name: '',
      cloned: true // Mark the cloned item as "cloned"
    };
    // Create a new array with the new item appended to it
    const newData = [...value];
    newData.splice(newData.length, 0, newItem);
    // Update the state with the new data
    setValue(newData);
    // Mark the original row as "cloned" to avoid multiple cloning
    rowData.cloned = "true";
  };
  // Data array with label-value pairs
  const data = [
    { label: 'Event:', value: event },
    { label: 'Date:', value: showdate },
    { label: 'Appointement Notes:', value: appointmentNotes },
    { label: 'Shared Calender:', value: sharedCalander },
  ];
  // Component for the TagBox with dataSource and onValueChanged handler
  const SharedCalenderTagBox = (props) => {
    const handleTagBoxChange = (e) => {
      props.data.setValue(e.value);
    };
    return (
      <>
        <div>
          <TagBox dataSource={products} onValueChanged={handleTagBoxChange} />
        </div>
      </>
    );
  };
  // Component for rendering the Event with a font-awesome icon if checked
  const EventCheck = (props) => {
    const eventData = props.data;
    const eventCheck = eventData.isAutoReminder;
    if (eventCheck) {
      return (
        <>
          <FontAwesomeIcon icon={faFile} /> &nbsp;<span className="name">{eventData.task}</span>
        </>
      );
    }
    else {
      return <span className="name">{eventData.task}</span>;
    }
  };
  // Component for rendering the Date with a font-awesome icon if completed
  const DateCheck = (props) => {
    const dateData = props.data;
    const dateCheck = dateData.completedDate;
    if (dateCheck !== null) {

      return (
        <>
          <FontAwesomeIcon icon={faCircleCheck} />&nbsp;<span className="name">{dateData.dueDate}</span>
        </>
      );
    } else {
      return <span className="name">{dateData.dueDate}</span>;
    }
  };
  // Component for rendering the AddButton (PlusButton) if date is completed
  const AddButton = (props) => {
    const { data } = props;
    const buttonData = props.data;
    const dateCheck = buttonData.Completed;
    if (dateCheck === "true") {
      return (
        <PlusButton hint="Clone" icon="add" onClick={() => cloneIconClick(data)} />
      );
    }
  };
  // Custom hook to load state from local storage
  const loadState = useCallback(() => {
    return getFromLS(storageKey);
  }, []);
  // Custom hook to save state to local storage
  const saveState = useCallback((state) => {
    setToLS(storageKey, state);
  }, []);
  return (
    <div>
      {showViewDate && <Modal icon={<FontAwesomeIcon icon={faEye} />} hide={() => setViewDate(false)} title="View Case Date" className="modal-dialog modal-dialog-centered" footer={
        <>
          <Button size='medium' variant='outlined' name='Close' onClick={() => setViewDate(false)}></Button>
          <Button type='submit' size='medium' variant='contained' name='Edit Date'  ></Button>
        </>
      }>
        <div>
          {data.map((item, index) => (
            <div className="data-field" key={index}>
              <h6 className="heading">{item.label}</h6>
              <p className="paragraph">{item.value}</p>
            </div>
          ))}
        </div>
      </Modal>}
      <CustomGrid data={caseDateData} onRowDblClick={mobile ? onRowDblClick : null} mobileView={mobile ? DataRow : null} id="gridContainer">
        <SearchPanel visible={true} />
        <HeaderFilter visible={true} />
        <StateStoring enabled={!mobile} type="custom" customLoad={loadState} customSave={saveState} />
        <Editing mode="cell" allowAdding={true} allowUpdating={true} />
        <FilterRow visible={!mobile} />
        <Column dataField="+" type="buttons" width={50} cellRender={AddButton} />
        <Column dataField="task" caption="Event" dataType="string" cellRender={EventCheck} visible={!mobile} />
        <Column
          dataField="dueDate"
          caption="Date"
          dataType="date"
        />
        <Column dataField="CalendarAppointementNote" dataType="string" visible={!mobile} />
        <Column dataField="SharedCalendar" editCellComponent={SharedCalenderTagBox} />
        <Column dataField="Name" caption="Task Assignee" defaultSelectedFilterOperation={defaultSelectedTaskAssignee} >
          <Lookup dataSource={customersData} />
        </Column>
        <ColumnChooser enabled={true}>
          <Position
            my="right top"
            at="right bottom"
            of=".dx-datagrid-column-chooser-button"
          />
        </ColumnChooser>
        <Paging defaultPageSize={12} />
        <Pager showPageSizeSelector={true} allowedPageSizes={allowedPageSizes} />
      </CustomGrid>
    </div>
  );
}
export default CaseDatesGrid;
