const letters =[
    {
        ID:1,
        Type:"defendant",
        ContactID:"xyz",
        ContactName:"abc",
        Refference:"ABC"
    },
    {
        ID:1,
        Type:"Client",
        ContactID:"xyz",
        ContactName:"abc",
        Refference:"ABC"
    },
    {
        ID:1,
        Type:"Case",
        ContactID:"xyz",
        ContactName:"abc",
        Refference:"ABC"
    }
]
export default letters;
