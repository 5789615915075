const USER_CASE_LIST_TITLE = "your cases";
const RECENT_CASES_TITLE = "your recent"
const TASK_WEEK_TITLE = "Your Tasks Overdue / due soon";
const TASK_LATER_TITLE = "Your Tasks due later";
const LIMITAION_TITLE = "Limitation Reminders";
const PORTAL_DATE_LIMIT_TITLE = "Claims Portal Date Limits";
const NOTIFICATION_TITILE = "Notifications";
const SHARED_COURT_TITLE = "Court - Aviation";
const SHARED_JUDGE_TITLE = "Judge";
const SEARCH_RESULT_TITLE = "search result";
export { USER_CASE_LIST_TITLE, RECENT_CASES_TITLE, TASK_WEEK_TITLE, TASK_LATER_TITLE, LIMITAION_TITLE, PORTAL_DATE_LIMIT_TITLE, NOTIFICATION_TITILE, SHARED_COURT_TITLE, SHARED_JUDGE_TITLE ,SEARCH_RESULT_TITLE}