import React, { useState, useEffect } from "react";
import Layout from "../../components/shared/layout";
import { createGlobalStyle } from 'styled-components';
import { useAuth } from "../../hooks/useAuth";
import CaseDetailAlert from "../../components/modals/warning-modal/handle-warning";
import { ADD_TASK_ID, ADD_CASE_ID } from "../../utils/constants/dashboard-component-id";
import { MyProSidebarProvider } from "../../components/navigation/side-navbar/sidebar-context";
import HomeTopBar from "../../components/home/home-top-bar";
import { getDasboardMenu } from "../../services/setting-service";
import ToastAlert from "../../components/modals/alert-modals/toast-alert";
import { useDispatch } from "react-redux";
import { openModal } from "../../components/features/modal-slice";
import { ADD_TASK, ADD_CASE } from "../../utils/constants/modal-name";
import { ADD } from '../../utils/constants/mode'
import HandleModal from "../../components/modals/handle-modal";
const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Roboto', sans-serif;
    max-width: 100%;
    background-color:${({ theme }) => theme.palette.primary.backgroundColor};
  }
`;
const HomeView = ({ children }) => {
  const [dynamicMenuItems, setDynamicMenuItems] = useState([]);
  const { user } = useAuth();
  const userID = user.id;
  const dispatch = useDispatch();
  useEffect(() => {
    document.title = `cManager | ${userID}`;
    fetchData();
  }, []);
  const fetchData = async () => {
    try {
      const dashboardMenu = await getDasboardMenu();
      setDynamicMenuItems(dashboardMenu.data.results);
    } catch (error) {
      console.error(error);
    }
  };
  const openModalByName = (modalName) => {
    dispatch(openModal({ modalName, mode: ADD, data: null }));
  };
  const handleMenuItemClick = (subMenuItem) => {
    const { id } = subMenuItem;
    switch (id) {
      case ADD_TASK_ID:
        openModalByName(ADD_TASK);
        break;
      case ADD_CASE_ID:
        openModalByName(ADD_CASE);
        break;
      default:
        break;
    }
  };
  return (
    <div>
      <ToastAlert />
      <CaseDetailAlert />
      <GlobalStyle />
      <Layout>
        <MyProSidebarProvider onClickMenuItem={handleMenuItemClick} paramDynamicMenuItems={dynamicMenuItems} dashboardView={true}>
          <HomeTopBar />
          {children}
        </MyProSidebarProvider>
        <HandleModal></HandleModal>
      </Layout>
    </div>
  );
};
export default HomeView;
