import React from 'react'
import styled from 'styled-components';
import TextInput from '../../../controls/textinput';
import Dropdown from '../../../controls/drop-down';
import Button from '../../../controls/button';
export default function Address({apiHouseID,apiStreet1,apiStreet2,apiDistrict,apiTown,apiPostCode}) {
    return (
        <div>
            <DivWrapper >
                <h6>Address</h6>
                <hr></hr>
                <div className="row g-3">
                    <div className='col-lg-12'>
                        <TextInput label="House ID" id="houseid" variant="standard" value={apiHouseID}></TextInput>
                    </div>
                    <div className='col-lg-6 col-md-6 col-6'>
                        <TextInput label="Street 1" id="street1" variant="standard" value={apiStreet1}></TextInput>
                    </div>
                    <div className='col-lg-6 col-md-6 col-6'>
                        <TextInput label="Street 2" id="street2" variant="standard" value={apiStreet2}></TextInput>
                    </div>
                    <div className='col-lg-12'>
                        <TextInput label="District" id="district" variant="standard" value={apiDistrict}></TextInput>
                    </div>
                    <div className='col-lg-6 col-md-6 col-6'>
                        <TextInput label="Town" id="town" variant="standard" value={apiTown}></TextInput>
                    </div>
                    <div className='col-lg-6 col-md-6 col-6'>
                        <Dropdown></Dropdown>
                    </div>
                    <div className='col-lg-6 col-md-6 col-6'>
                        <TextInput label="Post Code" id="postcode" variant="standard" value={apiPostCode}></TextInput>
                    </div>
                    <div className='col-lg-6 col-md-6 col-6'>
                        <Dropdown></Dropdown>
                    </div>
                    <div className='col-lg-12'>
                        <Button type='submit' size='medium' variant='contained' name='Lookup Address' fullWidth={true}></Button>
                    </div>
                </div>
            </DivWrapper>
        </div>
    )
}
const DivWrapper = styled.div`
  margin: 10px ;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px 20px 10px 20px;
    `;
