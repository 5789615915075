import { httpClient } from "./httpclient";

const url = "v1/setting";

const getSolicitorSetting = async (subdomain) => {
  // var endpoint = url + "/" + subdomain;
var endpoint = url ;
  return await httpClient.get(endpoint);
};
const getParameter = async (parmeterCode) => {
  try {
      const endpoint = `${url}/parameters/${parmeterCode}`;
      return await httpClient.get(endpoint);
  } catch (error) {
      throw new Error(error.response.status);
  }
};
const getDashBoardList = async (listType) => {
  try {
      const endpoint = `${url}/dashboardlist?listType=${listType}`;
      return await httpClient.get(endpoint);
  } catch (error) {
      throw new Error(error.response.status);
  }
};

const getSystemSetting = async () => {
  try {
      const endpoint = `${url}/systemsettings`;
      return await httpClient.get(endpoint);
  } catch (error) {
      throw new Error(error.response.status);
  }
};
const getDasboardMenu = async () => {
  try {
      const endpoint = `${url}/dashboardmenu`;
      return await httpClient.get(endpoint);
  } catch (error) {
      throw new Error(error.response.status);
  }
};
export { getSolicitorSetting,getParameter,getSystemSetting,getDashBoardList,getDasboardMenu };
