import React, { useState, useEffect, useRef } from 'react'
import styled, { keyframes } from 'styled-components';
import TextInput from '../controls/textinput';
import Datepicker from '../controls/datepicker';
import Dropdown from '../controls/drop-down';
import Button from '../controls/button';
import SearchIcon from '@mui/icons-material/Search';
import { useTheme } from 'styled-components';
import { dynamicKeyField } from '../../services/dynamic-data-service';
import { caseSearch } from '../../services/case-service';
import { CASES_SEARCH_ID } from '../../utils/constants/dashboard-component-id';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setToSS } from '../../utils/helpers/storage';

import { componentToRender } from '../features/dasboard-component-slice';
export default function AdvanceSearchForm({ hide }) {
    const theme = useTheme();
    const today = new Date();
    const navigate = useNavigate();
    const sixYearsAgo = new Date();
    sixYearsAgo.setFullYear(today.getFullYear() - 6);
    const [caseID, setCaseID] = useState("");
    const [secondaryID, setSecondaryID] = useState('');
    const [claimsPortalID, setClaimsPortalID] = useState('');
    const [uFN, setUFN] = useState('');
    const [clientName, setClientName] = useState('');
    const [foreName, setForename] = useState('');
    const [surName, setSurname] = useState('');
    const [clientAddress, setClientAddress] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [emailAddress, setEmailAddress] = useState('');
    const [startDate, setStartDate] = useState(sixYearsAgo); // Use null for Datepicker initial state
    const [endDate, setEndDate] = useState(today); // Use null for Datepicker initial state
    const [otherPartyName, setOtherPartyName] = useState('');
    const [otherPartyRef, setOtherPartyRef] = useState('');
    const [keyField1, setKeyField1] = useState('');
    const [keyValue1, setKeyValue1] = useState('');
    const [keyField2, setKeyField2] = useState('');
    const [keyValue2, setKeyValue2] = useState('');
    const [keyFieldOption, setkKeyFieldOption] = useState([]);
    const dispatch = useDispatch();
    const inputStyles = {
        '& .MuiInput-underline::before, & .MuiInput-underline::after, & .MuiInputLabel-root, & .MuiSvgIcon-root,& .MuiSelect-standard': {
            borderColor: theme.palette.nav.textIcon,
            color: theme.palette.nav.textIcon, // Change label text color
        },
        input: { color: theme.palette.nav.textIcon }
    };
    const handleInputChange = (event, setStateFunction) => {
        const { value } = event.target;
        setStateFunction(value);
    };
    const handleClientInputChange = (event) => {
        const { value } = event.target;
        setClientName(value);
        if (value.includes(' ')) {
            const [forename, surname] = value.split(' ');
            setForename(forename);
            setSurname(surname);
        } else {
            setSurname(value);
        }
    };
    const handleDropDownChange = (data, setStateFunction) => {
        const value = data.id;
        setStateFunction(value);
    };
    useEffect(() => {
        CallDynamicKeyFieldListApi();
    }, []);
    const onSearchClick = () => {
        const formattedStartDate = formatDate(startDate);
        const formattedEndDate = formatDate(endDate);

        if (caseID) {
            const newTabUrl = `/casedetail/${caseID}`;
            window.open(newTabUrl, '_blank');
        }
        else {
            CallCaseSearchApi(formattedStartDate, formattedEndDate);
        }
    };
    const CallCaseSearchApi = async (formattedStartDate, formattedEndDate) => {
        try {
            const casesearch = await caseSearch(foreName, surName, clientAddress, phoneNumber, emailAddress, otherPartyName, otherPartyRef, keyField1, keyValue1, keyField2, keyValue2, formattedStartDate, formattedEndDate);
            dispatch(componentToRender({ componentID: CASES_SEARCH_ID, title: "Search Results", data: casesearch.data.results }));
            setToSS("searchData", casesearch.data.results);
            if (casesearch.data.returnCode == 1) {
                navigate(`/home/searchresult`);
                hide();

            }
        }
        catch (error) {
            console.error(error);
        }
    };
    const CallDynamicKeyFieldListApi = async () => {
        try {
            const keyFieldList = await dynamicKeyField();
            setkKeyFieldOption(keyFieldList.data.results);
        }
        catch (error) {
            console.error(error);
        }
    };
    const handleDateChange = (event, setStateFunction) => {
        const parsedDate = new Date(event); // Adjust the locale as needed
        setStateFunction(parsedDate);
    };
    const handleClickAway = (event) => {
        if (event.target.id !== 'FilterSearchButton') {
            hide();
        }
    };
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            onSearchClick();
        }
    };
    const formatDate = (date) => {
        const selectedDate = new Date(date);
        const year = selectedDate.getFullYear();
        const month = (selectedDate.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
        const day = selectedDate.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
    };
    return (
        <>
            <ClickAwayListener onClickAway={(event) => handleClickAway(event)}>
                <ContainerWrapper>
                    <Heading><i className="bi bi-funnel"></i> Search</Heading>
                    <hr></hr>
                    <div className='row g-3' onKeyDown={handleKeyDown}>
                        <Column className='col-lg-3 col-md-6 col-6 '>
                            <TextInput label="Case ID" id="caseID" variant="standard" value={caseID} onChange={(event) => handleInputChange(event, setCaseID)} sx={inputStyles}></TextInput>
                        </Column>
                        <Column className='col-lg-3 col-md-6 col-6'>
                            <TextInput label="Secondary ID" id="secondaryID" value={secondaryID} variant="standard" onChange={(event) => handleInputChange(event, setSecondaryID)} sx={inputStyles}></TextInput>
                        </Column>
                        <Column className='col-lg-3 col-md-6 col-6'>
                            <TextInput label="Claims Portal ID" id="claimsPortalID" value={claimsPortalID} variant="standard" onChange={(event) => handleInputChange(event, setClaimsPortalID)} sx={inputStyles}></TextInput>
                        </Column>
                        <Column className='col-lg-3 col-md-6 col-6'>
                            <TextInput label="UFN" id="uFN" variant="standard" value={uFN} onChange={(event) => handleInputChange(event, setUFN)} sx={inputStyles}></TextInput>
                        </Column>
                        <div className='col-lg-3 col-md-6 col-6'>
                            <TextInput label="Client Name" id="clientName" value={clientName} variant="standard" onChange={handleClientInputChange} sx={inputStyles}></TextInput>
                        </div>
                        <div className='col-lg-3 col-md-6 col-6'>
                            <TextInput label="Client Address" id="clientAddress" value={clientAddress} variant="standard" onChange={(event) => handleInputChange(event, setClientAddress)} sx={inputStyles}></TextInput>
                        </div>
                        <div className='col-lg-3 col-md-6 col-6'>
                            <TextInput label="Phone Number" id="phoneNumber" value={phoneNumber} variant="standard" onChange={(event) => handleInputChange(event, setPhoneNumber)} sx={inputStyles}></TextInput>
                        </div>
                        <div className='col-lg-3 col-md-6 col-6'>
                            <TextInput label="Email Address" id="emailAddress" value={emailAddress} variant="standard" onChange={(event) => handleInputChange(event, setEmailAddress)} sx={inputStyles}></TextInput>
                        </div>
                        <div className='col-lg-3 col-md-6 col-6'>
                            <TextInput label="Other Party Name" id="otherPartyName" value={otherPartyName} variant="standard" onChange={(event) => handleInputChange(event, setOtherPartyName)} sx={inputStyles}></TextInput>
                        </div>
                        <div className='col-lg-3 col-md-6 col-6'>
                            <TextInput label="Other Party Ref" id="otherPartyRef" value={otherPartyRef} variant="standard" onChange={(event) => handleInputChange(event, setOtherPartyRef)} sx={inputStyles}></TextInput>
                        </div>
                        <div className='col-lg-3 col-md-6 col-6'>
                            <Dropdown label="Key Field" id="keyField1" options={keyFieldOption} customPropertyName="id" displayPropertyName="fieldLabel" value={keyField1} onChange={(event) => handleDropDownChange(event, setKeyField1)} sx={inputStyles}></Dropdown>
                        </div>
                        <div className='col-lg-3 col-md-6 col-6'>
                            <TextInput label="Key Value" id="keyValue1" variant="standard" value={keyValue1} onChange={(event) => handleInputChange(event, setKeyValue1)} sx={inputStyles}></TextInput>
                        </div>
                        <div className='col-lg-3 col-md-6 col-6'>
                            <Dropdown label="Key Field" id="keyField2" options={keyFieldOption} customPropertyName="id" displayPropertyName="fieldLabel" value={keyField2} onChange={(event) => handleDropDownChange(event, setKeyField2)} sx={inputStyles}></Dropdown>
                        </div>
                        <div className='col-lg-3 col-md-6 col-6 '>
                            <TextInput label="Key Value" id="keyValue2" variant="standard" value={keyValue2} onChange={(event) => handleInputChange(event, setKeyValue2)} sx={inputStyles}></TextInput>
                        </div>
                        <div className='col-lg-3 col-md-6 col-6'>
                            <Datepicker label="Start Date" id="startDate" value={startDate} sx={inputStyles} onChange={(event) => handleDateChange(event, setStartDate)}></Datepicker>
                        </div>
                        <div className='col-lg-3 col-md-6 col-6'>
                            <Datepicker label="End Date" id="endDate" value={endDate} sx={inputStyles} onChange={(event) => handleDateChange(event, setEndDate)}></Datepicker>
                        </div>
                        <span><hr></hr></span>
                        <Actions>
                            <CancelButton>
                                <Button variant="outlined" name="Cancel" icon="bi bi-x-circle-fill" fullWidth={true} onClick={hide} />
                            </CancelButton>
                            <SearchButton>
                                <Button variant="contained" name="Search" icon={<SearchIcon />} onClick={onSearchClick} fullWidth={true} />
                            </SearchButton>
                        </Actions>
                    </div>

                </ContainerWrapper>
            </ClickAwayListener>
        </>
    )
}
const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;
const ContainerWrapper = styled.div`
position: fixed;
    z-index: 3; 
    background :  ${({ theme }) => theme.palette.nav.background};
   color: ${({ theme }) => theme.palette.nav.textIcon};
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    animation: ${fadeIn} 0.3s ease-in-out;
`;
const SearchButton = styled.div`
    @media (max-width: 1000px) {
        width: 100%;
    }
`;
const CancelButton = styled.div`
    position: relative;
    margin-right: 20px;
    @media (max-width: 1000px) {
        width: 100%;
    }
`;
const Column = styled.div`
 color: ${({ theme }) => theme.palette.nav.textIcon};
`;
const Heading = styled.h1`
 color: ${({ theme }) => theme.palette.nav.textIcon};
 @media (max-width: 1000px) {
   text-align:center;
}
`;
const Actions = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    
`;
