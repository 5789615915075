import { useState, useEffect } from 'react';
import { Mobile, Desktop ,Tab } from '../utils/constants/devices';
import { mobileMaxWidth , tabMaxWidth , tabMinWidth } from '../utils/constants/deviceWidths';
export function useScreenSize() {
  const [device, setDevice] = useState(getDeviceName());

  function getDeviceName() {
    const width = window.innerWidth;
    if (width <= mobileMaxWidth) {
      return Mobile;
    } else if (width > tabMinWidth && width < tabMaxWidth) {
      return Tab
    } else {
      return Desktop
    }
  }

  useEffect(() => {
    const handleScreenResize = () => {
      const deviceName = getDeviceName();
      setDevice(deviceName);
    };

    window.addEventListener('resize', handleScreenResize);

    return () => {
      window.removeEventListener('resize', handleScreenResize);
    };
  }, []);

  return device;
}
