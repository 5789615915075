import React, { useEffect, useCallback } from 'react'
import CustomGrid from '../../controls/grid';
import { Scrolling, RemoteOperations, Column, Grouping, GroupPanel, Summary, GroupItem, SearchPanel, Format, ColumnChooser, StateStoring, FilterRow, FilterPanel, HeaderFilter ,Position } from 'devextreme-react/data-grid';
import { useAuth } from '../../../hooks/useAuth';
import { activeList } from '../../../services/case-service';
import { useState } from 'react';
import { CaseDetailTab } from '../../case-detail/case-detail-tab';
import CaseDetailAlert from '../../modals/warning-modal/handle-warning';
import { getFromLS, setToLS } from '../../../utils/helpers/storage';
import { CheckBox } from "devextreme-react";
import { useScreenSize } from '../../../hooks/useScreenSize';
import { tabSuffix, desktopSuffix } from '../../../utils/constants/storageKeys';
import { Mobile, Desktop, Tab } from '../../../utils/constants/devices';
import { locale } from 'devextreme/localization';
import "./active-list.css"
export default function ActiveListGrid({ storeKey, children }) {
    const [activeListData, setActiveListData] = useState([]);
    const [caseDetailAlert, setCaseDetailAlert] = useState(false);
    const [result, setResult] = useState([]);
    const { user } = useAuth();
    const userID = user.id;
    const device = useScreenSize();
    let storageKey;
    let mobile = false;
    if (device === Tab) {
        storageKey = storeKey + tabSuffix
        mobile = false;
    }
    else if (device === Desktop) {
        storageKey = storeKey + desktopSuffix
        mobile = false;
    }
    else if (device === Mobile) {
        mobile = true;
    }

    useEffect(() => {
        locale('en-GB');
        CallApi();
    }, [storeKey]);
    const CallApi = async () => {
        try {
            const activelist = await activeList(userID);
            setActiveListData(activelist.data.results);
        }
        catch (error) {
            console.error(error);
        }
    };
    const handleRowDblClick = async (e) => {
        const caseID = e.data.id;
        if (caseID) {
            const result = await CaseDetailTab(caseID);
            if (!result.success) {
                setResult(result);
                setCaseDetailAlert(true);
            }
        }
    };
    const strikeCheckboxCell = (props) => {
        return (
            <div className="checkbox-cell">
                <CheckBox
                    value={props.data.hireCar}
                />
            </div>
        );
    };
    const loadState = useCallback(() => {
        return getFromLS(storageKey);
    }, []);

    const saveState = useCallback((state) => {
        setToLS(storageKey, state);
    }, []);
    return (
        <div>
            {caseDetailAlert && <CaseDetailAlert hide={() => { setCaseDetailAlert(!caseDetailAlert) }} data={result}></CaseDetailAlert>}
            <CustomGrid data={activeListData} id="activeListGridContainer" keyExpr="id" onRowDblClick={handleRowDblClick} wordWrapEnabled={false}  >
                <RemoteOperations summary={true} />
                <Scrolling mode="virtual" />
                <SearchPanel visible={true} ></SearchPanel>
                <Grouping autoExpandAll={true} />
                <HeaderFilter visible={true} />
                <GroupPanel visible={true} />
                <FilterPanel visible={true} />
                <StateStoring enabled={!mobile} type="custom" customLoad={loadState} customSave={saveState} />
                {children}
                <Column dataField="branch" caption="Branch" dataType="string" />
                <Column dataField="id" caption="ID" dataType="number" />
                <Column dataField="secondaryID" caption="Secondary ID" dataType="string" />
                <Column dataField="forename" caption="Forename" dataType="string" />
                <Column dataField="surname" caption="Surname" dataType="string" />
                <Column dataField="hireCar" caption="Hire Car" cellRender={strikeCheckboxCell} />
                <Column dataField="hireOngoing" caption="Hire Ongoing" cellRender={strikeCheckboxCell} />
                <Column dataField="liabilityAdmitted" caption="Liability Admitted" dataType="string" />
                <Column dataField="billingStage" caption="Billing Stage" dataType="string" />
                <Column dataField="caseSource" caption="Case Source" dataType="string" />
                <Column dataField="userField1" caption="User Field 1" dataType="string" />
                <Column dataField="userField2" caption="User Field 2" dataType="string" />
                <Column dataField="incident" caption="Incident Date" dataType="date" />
                <Column dataField="open" caption="Open Date" dataType="date" />
                <Column dataField="processVersion" caption="Process Version " dataType="number" ><Format type="fixedPoint" precision={1} /></Column>
                <Column dataField="ufn" caption="UFN" dataType="string" />
                <Column dataField="lastUpdatedTime" caption="Updated" dataType="date" />
                <Column dataField="description" caption="Status" dataType="string" groupIndex={0} />
                <Column dataField="caseSubType" caption="Case Sub Type" dataType="string" groupIndex={1} />
                <Column dataField="branchID" caption="Branch ID" dataType="number" visible={false} />
                <Column dataField="caseType" caption="Case Type" dataType="string" visible={false} />
                <Column dataField="feeEarnerID" caption="FE" dataType="string" visible={false} />
                <Column dataField="processInd" caption="Process Ind" dataType="string" visible={false} />
                <Column dataField="sequence" caption="Sequence" dataType="string" visible={false} />
                <Column dataField="supervisorID" caption="Supervisor ID" dataType="string" visible={false} />
                <Summary>
                    <GroupItem column="id" summaryType="count" displayFormat={'{0}'} />
                </Summary>
                <ColumnChooser enabled={true}>
                    <Position
                        my="right top"
                        at="right bottom"
                        of=".dx-datagrid-column-chooser-button"
                    />
                </ColumnChooser>

            </CustomGrid>
        </div>
    )
}
