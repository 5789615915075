import React, { useState, useEffect } from "react";
import Note from "./note-area";
import CreateArea from "./create-area";
import styled from "styled-components";

function HandleNote({ caseNote }) {
  const [notes, setNotes] = useState("");

  useEffect(() => {
    setNotes(caseNote);
  }, [caseNote]);

  function addNote(newNote) {
    setNotes(prevNotes => `${newNote}\n${prevNotes}`);
  }
  return (
    <>
      <CreateArea onAdd={addNote} />
      <Note note={notes} />
    </>
  );
}
export default HandleNote;
