import React from "react";
import ReCAPTCHA from "react-google-recaptcha";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import styled from "styled-components";
import { createTheme } from "@mui/material";

const Container = styled.form`
  width: 100%;
`;

const theme = createTheme();

const ForgotPasword = ({
  userId,
  onBackToLogin,
  onUserIDChange,
  onForgetPassword,
}) => {
  return (
    <Container onSubmit={onForgetPassword}>
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="username"
        label="Username"
        name="username"
        autoFocus
        value={userId}
        onChange={(e) => onUserIDChange(e.target.value)}
      />

      <Button
        fullWidth
        variant="contained"
        color="primary"
        type="submit"
        style={{ margin: theme.spacing(3, 0, 2) }}
      >
        Request New Password
      </Button>
      <Button
        fullWidth
        variant="outlined"
        color="primary"
        type="button"
        onClick={onBackToLogin}
        style={{ margin: theme.spacing(3, 0, 2) }}
      >
        Back To Login
      </Button>
    </Container>
  );
};
export default ForgotPasword;
