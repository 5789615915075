import { useState, useEffect } from 'react';
import { getParameter } from '../services/setting-service';
import { CASE_TYPES, CASE_SUB_TYPES } from '../utils/constants/parmeterCode';
export function useCaseParameters() {
    const [caseTypes, setcaseType] = useState([]);
    const [caseSubTypes, setcaseSubType] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const caseType = await getParameter(CASE_TYPES);
                setcaseType(caseType.data.results);

                const caseSubType = await getParameter(CASE_SUB_TYPES);
                setcaseSubType(caseSubType.data.results);
            } catch (error) {
                // Handle errors
            }
        };
        fetchData();
    }, []);

    return { caseTypes, caseSubTypes};
}
