import React,{useState} from 'react'
import LookUpList from '../../controls/lookuplist';
import { Column } from 'devextreme-react/data-grid';
import Button from '../../controls/button';
import styled from 'styled-components';
import MaintainPersonView from '../../../views/case-detail/maintain-person-view';
export default function PersonGrid({ data ,entityName}) {
    const [showMaintainPersonView, setMaintainPersonView] = useState(false);
    const [isDropDownOpen, setIsDropDownOpen] = useState(false); // State for dropdown open/close
    const [isNewPerson, setIsNewPerson] = useState(false);
    const mergeForenameAndSurname = (rowData) => {
        return rowData.forename + ' ' + rowData.surname;
    };
    const handleAddNewClick = () => {
        setIsNewPerson(true);
        setIsDropDownOpen(!isDropDownOpen); // Toggle dropdown open/close
        setMaintainPersonView(!showMaintainPersonView)
       
      };
    return (
        <div>
        {showMaintainPersonView &&<MaintainPersonView isNewPerson={isNewPerson} entityName={entityName} hide={() => { setMaintainPersonView(!showMaintainPersonView) }}></MaintainPersonView>}
            <LookUpList data={data} footer={
                <>
                    <ButtonWrapper>
                        <Button type='submit' size='medium' variant='contained' name='Add New' onClick={handleAddNewClick}></Button>
                        <Button size='medium' variant='outlined' name='Clear' ></Button>
                    </ButtonWrapper>
                </>
            }>
                <Column dataField="houseID" caption="House ID" dataType="string" />
                <Column dataField="street1" caption="Street 1" dataType="string" />
                <Column dataField="town" caption="Town" dataType="string" />
                <Column dataField="county" caption="Region" dataType="string" />
                <Column dataField="country" caption="Country" dataType="string" />
                <Column dataField="emailAddress" caption="Email Address" dataType="string" />
                <Column dataField="name" caption="Name" calculateCellValue={mergeForenameAndSurname} dataType="string" />
                <Column dataField="addressBlock" caption="Address Block" dataType="string" visible={false} />
                <Column dataField="dept" caption="Depth" dataType="string" visible={false} />
                <Column dataField="district" caption="District" dataType="string" visible={false} />
                <Column dataField="fax" caption="Fax" dataType="string" visible={false} />
                <Column dataField="id" caption="ID" dataType="string" visible={false} />
                <Column dataField="lastUpdatedTime" caption="Last Updated Time" dataType="string" visible={false} />
                <Column dataField="lastUpdatedUser" caption="Last Updated User" dataType="string" visible={false} />
                <Column dataField="notes" caption="Notes" dataType="string" visible={false} />
                <Column dataField="secureID" caption="Secure ID" dataType="string" visible={false} />
                <Column dataField="status" caption="Status" dataType="string" visible={false} />
                <Column dataField="street2" caption="Street 2" dataType="string" visible={false} />
                <Column dataField="postcode" caption="Postcode" dataType="string" visible={false} />
                <Column dataField="workPhone" caption="Work Phone" dataType="string" visible={false} />
                <Column dataField="dateofBirth" caption="Date of Birth" dataType="string" visible={false} />
                <Column dataField="gender" caption="Gender" dataType="string" visible={false} />
                <Column dataField="title" caption="Title" dataType="string" visible={false} />
                <Column dataField="forename" caption="Forename" dataType="string" visible={false} />
                <Column dataField="surname" caption="Surname" dataType="string" visible={false} />
                <Column dataField="middleName" caption="Middle Name" dataType="string" visible={false} />
                <Column dataField="suffix" caption="Suffix" dataType="string" visible={false} />
                <Column dataField="typeOfPerson" caption="Type of Person" dataType="string" visible={false} />
                <Column dataField="workExtensionNumber" caption="Work Extension Number" dataType="string" visible={false} />
                <Column dataField="homePhone" caption="Home Phone" dataType="string" visible={false} />
                <Column dataField="mobile" caption="Mobile" dataType="string" visible={false} />
                <Column dataField="preferredContactMethod" caption="Preferred Contact Method" dataType="string" visible={false} />
                <Column dataField="preferredContactTime" caption="Preferred Contact Time" dataType="string" visible={false} />
            </LookUpList>
        </div>
    )
}
const ButtonWrapper = styled.div`
  display: flex;
    justify-content: flex-End; /* Align buttons to the left */
    gap: 10px; /* Adjust the space between buttons */
`;
