import React, { useEffect, useState } from 'react'
import { Column, Toolbar, Paging, Item, SearchPanel } from 'devextreme-react/data-grid';
import CustomGrid from '../../controls/grid';
import { locale } from 'devextreme/localization';
import CaseDetailAlert from '../../modals/warning-modal/handle-warning';
import { CaseDetailTab } from '../../case-detail/case-detail-tab';
import "./recent-list.css";
export default function CaseRecentGrid({ recentListData,headerTitle }) {
  const [caseDetailAlert, setCaseDetailAlert] = useState(false);
  const [result, setResult] = useState([]);
  useEffect(() => {
    locale('en-GB');
  }, []);
  const onRowDblClick = async (e) => {
    const rowData = e.data;
    const result = await CaseDetailTab(rowData.caseID);
    if (!result.success) {
      setResult(result);
      setCaseDetailAlert(true);
    };
  }
  return (
    <div>
      {caseDetailAlert && <CaseDetailAlert hide={() => { setCaseDetailAlert(!caseDetailAlert) }} data={result}></CaseDetailAlert>}
      <CustomGrid data={recentListData} onRowDblClick={onRowDblClick} id="caseRecentGridContainer" keyExpr="caseID" columnAutoWidth={true} wordWrapEnabled={true}>
        <Paging enabled={false} />
        <SearchPanel visible placeholder='Task Search' />
        <Toolbar>
          <Item location='before'>
            <div className='grid-header'>{headerTitle.toUpperCase()}</div>
          </Item>
          <Item location='before' name='groupPanel' locateInMenu='auto' />
          <Item name='searchPanel' locateInMenu='auto' />
        </Toolbar>
        <Column dataField="caseID" caption="Case" dataType="number" />
        <Column dataField="clientName" caption="Name" dataType="string" />
        <Column dataField="timeAccessed" caption='Time Accessed' dataType="datetime" />
      </CustomGrid>
    </div>
  )
}
