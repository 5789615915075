import React, { useEffect} from 'react';
import { useStopwatch } from 'react-timer-hook';
import { useDispatch, useSelector } from 'react-redux';
import { setClicked } from '../features/clickStatus-slice';
import { updateUnitCost } from "../features/unitCost-calculation-slice"
import { getStartTime } from '../features/timer-slice';
import { resetTimer } from '../features/timer-slice';
export default function Stopwatch({ chargingRate, minutesPerUnit }) {
  const { isClicked } = useSelector((state) => state.clickStatus);
  const { resetTime } = useSelector((state) => state.timer);
  const dispatch = useDispatch();
  const {
    totalSeconds,
    seconds,
    minutes,
    hours,
    reset,
  } = useStopwatch({ autoStart: true });
  useEffect(() => {
    if (isClicked) {
      dispatch(updateUnitCost({ totalSeconds, minutesPerUnit, chargingRate }));
      dispatch(setClicked({ isClicked: false }));
    }
    else if (resetTime) {
      reset();
      dispatch(getStartTime({ startTime: new Date().toISOString() }))
      dispatch(resetTimer({ resetTime: false }))
    }
  }, [isClicked, resetTime]);
  return (
    <div>
      <span>{hours}</span>:<span>{minutes}</span>:<span>{seconds}</span>
    </div>
  );
}

