// Redux slice for managing the state of a click status, indicating whether a specific action or component has been clicked

import { createSlice } from '@reduxjs/toolkit';

// Initial state for the click status slice
const initialState = {
    isClicked: false, // Flag to track the click status
};

// Redux slice for click status state management
export const clickStatusSlice = createSlice({
    name: 'clickStatus',
    initialState,
    reducers: {
        // Action to set the click status based on the provided boolean value
        setClicked: (state, action) => {
            const { isClicked } = action.payload;
            state.isClicked = isClicked;
        },
    },
});

// Exporting the action creators
export const { setClicked } = clickStatusSlice.actions;

// Exporting the reducer
export default clickStatusSlice.reducer;
