import React from 'react'
import styled from 'styled-components';
import TextInput from '../../controls/textinput';
import Dropdown from '../../controls/drop-down';
export default function OtherDetail({organisationData}) {
    const alternativeID = organisationData.alternativeID;
    const secureID=organisationData.secureID;
    const groupCode = organisationData.groupCode;
    const dxNumber=organisationData.dxNumber;
    const dxExchange=organisationData.dxExchange;
    return (
        <div>
            <DivWrapper >
                <h6>Other Details</h6>
                <hr></hr>
                <div className="row g-3">
                    <div className='col-lg-12'>
                        <Dropdown label="Status" id="status" ></Dropdown>
                    </div>
                    <div className='col-lg-6 col-md-6 col-6'>
                        <Dropdown label="Sub Type" id="subtype"></Dropdown>
                    </div>
                    <div className='col-lg-12'>
                        <TextInput label="ID 2" id="id2" variant="standard" value={alternativeID}></TextInput>
                    </div>
                    <div className='col-lg-12'>
                        <TextInput label="Group Code" id="groupCode" variant="standard" value={groupCode}></TextInput>
                    </div>
                    <div className='col-lg-6 col-md-6 col-6'>
                        <TextInput label="DX Number" id="dxNumber" variant="standard" value={dxNumber}></TextInput>
                    </div>
                    <div className='col-lg-6 col-md-6 col-6'>
                        <TextInput label="DX Exchange" id="dxexchange" variant="standard" value={dxExchange}></TextInput>
                    </div>
                    <div className='col-lg-12'>
                        <TextInput label="Secure ID" id="secureID" variant="standard" value={secureID}></TextInput>
                    </div>
                </div>
            </DivWrapper>
        </div>
    )
}
const DivWrapper = styled.div`
  margin: 10px ;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px 20px 10px 20px;
    `;
