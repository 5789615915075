import React from 'react'
import styled from 'styled-components';
import TextInput from '../../../controls/textinput';
export default function ContactDetail() {
    return (
        <div>
            <DivWrapper >
                <h6>Contact Details</h6>
                <hr></hr>
                <div className="row g-3">
                    <div className='col-lg-6 col-md-6 col-6'>
                        <TextInput label="Home Tel" id="hometel" variant="standard"></TextInput>
                    </div>
                    <div className='col-lg-6 col-md-6 col-6'>
                        <TextInput label="Work Tel" id="worktel" variant="standard"></TextInput>
                    </div>
                    <div className='col-lg-6 col-md-6 col-6'>
                        <TextInput label="Mobile" id="mobile" variant="standard"></TextInput>
                    </div>
                    <div className='col-lg-6 col-md-6 col-6'>
                        <TextInput label="Fax" id="fax" variant="standard"></TextInput>
                    </div>
                    <div className='col-lg-12'>
                        <TextInput label="Email" id="email" variant="standard"></TextInput>
                    </div>
                </div>
            </DivWrapper>
        </div>
    )
}
const DivWrapper = styled.div`
  margin: 10px ;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px 20px 10px 20px;
    `;
