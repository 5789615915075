import React from 'react'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
export default function Dropdown({ id, label, options, disabled, onChange, value, mode, customPropertyName, displayPropertyName, dropDownType, displayPropertyName2, readOnly, multiple, sx }) {
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        disablePortal: true,
        PaperProps: {
            style: {
                
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };
    const handleChange = (event) => {
        if (multiple) {
            const processedValue = event.target.value === 'string' ? value.split(',') : event.target.value;
            onChange(processedValue);
        }
        else {
            const selectedOption = options.find(option => option[customPropertyName] === event.target.value);
            onChange(selectedOption); // Pass the entire selected option object to the parent componen
        }
    };
    return (
        <div>
            <FormControl variant="standard" fullWidth sx={sx} >
                <InputLabel>{label}</InputLabel>
                {disabled ?
                    <Select
                        id={id}
                        label={label}
                        variant="standard"
                        size="small"
                        onChange={handleChange}
                        value={value || ''}
                        disabled
                        MenuProps={MenuProps}
                    >
                    </Select>
                    : <Select
                        id={id}
                        label={label}
                        variant="standard"
                        size="small"
                        onChange={handleChange}
                        value={value || ''}
                        MenuProps={MenuProps}
                        readOnly={readOnly}
                        multiple={multiple}
                    >
                        {options?.map(option => (
                            <MenuItem key={option[customPropertyName]} value={option[customPropertyName]} >
                                {dropDownType === "user"
                                    ? `${option[displayPropertyName]} ${option[displayPropertyName2]}`
                                    : option[displayPropertyName]
                                }

                            </MenuItem>
                        ))}
                    </Select>

                }
            </FormControl>
        </div>
    )
}
