import { useEffect, useState } from "react";
import { ThemeProvider } from "styled-components";
import { getSolicitorSetting } from "./services/setting-service";
import Loader from "./components/shared/loader";
import styled from "styled-components";
import { Route, Routes, Outlet } from "react-router-dom";
import HomeView from "./views/home/home-view";
import AuthProvider from "./components/auth-provider/auth-provider";
import ProtectedRoute from "./components/auth-provider/protected-route";
import { useSubDomain } from "./hooks/useSubDomain";
import CaseDetailLayout from "./components/shared/case-detail-layout";
import CaseDetailContextProvider from "./components/shared/case-detail-context";
import ActiveListView from "./views/case-detail/active-list-view";
import RecentListView from "./views/case-detail/recent-list-view";
import HomeTaskView from "./views/home/home-task-view";
import {
  USER_CASE_LIST_TITLE,
  RECENT_CASES_TITLE,
  TASK_WEEK_TITLE,
  TASK_LATER_TITLE,
  LIMITAION_TITLE,
  PORTAL_DATE_LIMIT_TITLE,
  NOTIFICATION_TITILE,
  SHARED_COURT_TITLE,
  SHARED_JUDGE_TITLE,
  SEARCH_RESULT_TITLE,
} from "./utils/constants/header-title";
import {
  TASK_WEEK_ID,
  TASK_LATER_ID,
  LIMITATION_ID,
  CLAIM_PORTAL_ID,
  NOTIFICATION_ID,
  CASES_SEARCH_ID,
  TASK_COURT_AVIATION_ID,
  TASK_JUDGE_ID,
} from "./utils/constants/dashboard-component-id";
import {
  limitation,
  portalDateLimit,
  notification,
  sharedCalendarTask,
} from "./services/task-service";
import CasesGridView from "./views/home/cases-grid-view";
import LoginView from "./views/login-view/login-view";
function App() {
  const [setting, setSetting] = useState(null);
  useEffect(() => {
    // using the subdomain get the client settings.
    async function GetClientSetting() {
      const { domain } = useSubDomain();

      // fetch the solictor settings, etc theme & login content)
      var solictorSettings = await getSolicitorSetting(domain);
      setSetting(solictorSettings.data.result);
    }

    if (!setting) {
      GetClientSetting();
    }
  });

  // temporary theme, this will be stored in the db eventually.
  const knightsBridge = {
    palette: {
      primary: {
        main: "#2d2d70",
        gradient1: "#2D2D70",
        gradient2: "#8181a9",
        contrastTextColour: "#FFFFFF",
        backgroundColor: "#FAFAFB",
      },
      secondary: {
        main: "#FFFFFF",
        contrastTextColour: "#2d2d70",
      },
      tertiary: {
        main: "#c0c0c0",
        contrastTextColour: "#000000",
      },
      nav: {
        main: "#1F2A40",
        contrastTextColour: "#000000",
        onHoverBackground: "#141b2d",
        onHoverTextIcon: "#6870fa",
        onActiveTextIcon: "#4cceac",
        textIcon: "#ffffff",
        background: "#101624",
        imageBackground: "#141b2d",
        borderColor: "#D0D4CA",
      },
      subMenu: {
        main: "#323232",
        contrastTextColour: "#000000",
        onHoverBackground: "#0D7377",
        onHoverTextIcon: "#14FFEC",
        textIcon: "#ffffff",
      },
      caseNote: {
        main: "#ffffff",
        button: "#f5ba13",
      },
      topBar: {
        main: "#141b2d",
        icon: "#ffffff",
      },
      headerTitle: {
        subTitleText: "#70d8bd",
      },
    },
  };

  const defaultTheme = {
    palette: {
      primary: {
        main: "#FFFFFF",
        gradient1: "#2D2D70",
        gradient2: "#8181a9",
        contrastTextColour: "#2d2d70",
      },
      secondary: {
        main: "#2d2d70",
        contrastTextColour: "#FFFFFF",
      },
    },
  };

  if (!setting) {
    return (
      <ThemeProvider theme={defaultTheme}>
        <LoadingBackground>
          <Loader showLoader={true} />
        </LoadingBackground>
      </ThemeProvider>
    );
  }
  return (
    <>
      <AuthProvider>
        <ThemeProvider theme={knightsBridge}>
          <CaseDetailContextProvider>
            <Routes>
              <Route path="login" element={<LoginView content={setting} />} />
              <Route
                path="/home"
                element={
                  <HomeView>
                    <ProtectedRoute>
                      <Outlet />
                    </ProtectedRoute>
                  </HomeView>
                }
              >
                <Route
                  index
                  element={
                    <HomeTaskView
                      thisWeek={true}
                      componentID={TASK_WEEK_ID}
                      headerTitle={TASK_WEEK_TITLE}
                    />
                  }
                />
                <Route
                  path="usercaselist"
                  element={
                    <ActiveListView headerTitle={USER_CASE_LIST_TITLE} />
                  }
                />
                <Route
                  path="recentcases"
                  element={<RecentListView headerTitle={RECENT_CASES_TITLE} />}
                />
                <Route
                  path="tasksweek"
                  element={
                    <HomeTaskView
                      thisWeek={true}
                      componentID={TASK_WEEK_ID}
                      headerTitle={TASK_WEEK_TITLE}
                    />
                  }
                />
                <Route
                  path="taskslater"
                  element={
                    <HomeTaskView
                      thisWeek={false}
                      componentID={TASK_LATER_ID}
                      headerTitle={TASK_LATER_TITLE}
                    />
                  }
                />
                <Route
                  path="limitations"
                  element={
                    <HomeTaskView
                      apiFunction={limitation}
                      componentID={LIMITATION_ID}
                      headerTitle={LIMITAION_TITLE}
                    />
                  }
                />
                <Route
                  path="portaldatelimits"
                  element={
                    <HomeTaskView
                      apiFunction={portalDateLimit}
                      componentID={CLAIM_PORTAL_ID}
                      headerTitle={PORTAL_DATE_LIMIT_TITLE}
                    />
                  }
                />
                <Route
                  path="notifications"
                  element={
                    <HomeTaskView
                      apiFunction={notification}
                      componentID={NOTIFICATION_ID}
                      headerTitle={NOTIFICATION_TITILE}
                    />
                  }
                />
                <Route
                  path="shared|court"
                  element={
                    <HomeTaskView
                      sharedCalendar="Court - Aviation"
                      apiFunction={sharedCalendarTask}
                      componentID={TASK_COURT_AVIATION_ID}
                      headerTitle={SHARED_COURT_TITLE}
                    />
                  }
                />
                <Route
                  path="shared|judge"
                  element={
                    <HomeTaskView
                      sharedCalendar="Judge"
                      apiFunction={sharedCalendarTask}
                      componentID={TASK_JUDGE_ID}
                      headerTitle={SHARED_JUDGE_TITLE}
                    />
                  }
                />
                <Route
                  path="searchresult"
                  element={
                    <CasesGridView
                      componentID={CASES_SEARCH_ID}
                      headerTitle={SEARCH_RESULT_TITLE}
                    ></CasesGridView>
                  }
                />
              </Route>
              <Route path="*" element={<LoginView content={setting} />} />
              <Route
                path="/casedetail/:caseID"
                element={
                  <CaseDetailLayout>
                    <ProtectedRoute>
                      <Outlet /> {/* This will render child routes */}
                    </ProtectedRoute>
                  </CaseDetailLayout>
                }
              ></Route>
            </Routes>
          </CaseDetailContextProvider>
        </ThemeProvider>
      </AuthProvider>
    </>
  );
}
export default App;

const LoadingBackground = styled.div`
  background: ${({ theme }) => theme.palette.primary.main};
`;
