import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import styled from 'styled-components';
export default function CardView({children}) {
  return (
    <StyledCard variant="outlined">
      <CardContent>
       {children}
      </CardContent>
    </StyledCard>
  );
}
const StyledCard = styled(Card)`
  background: #000000;
  color: white;
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);

 
`;
