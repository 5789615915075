import React from 'react'
import ClientDetailForm from '../../components/case-detail/client-detail-form'
import Tab from '../../components/controls/tab'
import StatusForm from '../../components/case-detail/status-form'
import MoreInfo from '../../components/case-detail/more-info-form'
import NonSTDRates from '../../components/case-detail/non-std-rates-grid'
import styled from 'styled-components'
export default function CaseStatusView() {
    return (
<NavWrapper>
            <ClientDetailForm></ClientDetailForm>
            <Tab tabContent={<><div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab"><StatusForm></StatusForm></div>
                <div className="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab"><MoreInfo></MoreInfo></div>
                <div className="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">.....</div>
                <div className="tab-pane fade" id="nav-nonstdrates" role="tabpanel" aria-labelledby="nav-contact-tab"><NonSTDRates></NonSTDRates></div> </>}>
                <button className="nav-link active" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">Status</button>
                <button className="nav-link" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">More Info</button>
                <button className="nav-link" id="nav-contact-tab" data-bs-toggle="tab" data-bs-target="#nav-contact" type="button" role="tab" aria-controls="nav-contact" aria-selected="false">Linked Cases</button>
                <button className="nav-link" id="nav-contact-tab" data-bs-toggle="tab" data-bs-target="#nav-nonstdrates" type="button" role="tab" aria-controls="nav-contact" aria-selected="false">Non-Std-Rates</button>
            </Tab>
</NavWrapper>
    )
}
const NavWrapper = styled.div`
margin:10px 10px 10px 10px;
border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px;

`;