import { configureStore } from "@reduxjs/toolkit";
import modalReducer from "../components/features/modal-slice"
import alertReducer from "../components/features/alert-slice"
import dasboardComponentReducer from "../components/features/dasboard-component-slice"
import unitCostReducer from "../components/features/unitCost-calculation-slice"
import timerReducer from "../components/features/timer-slice"
import clickStatusReducer from "../components/features/clickStatus-slice"
import warningReducer from "../components/features/warning-modal-slice"
export const Store = configureStore({
    reducer: {
        modal: modalReducer,
        alert: alertReducer,
        warning: warningReducer,
        dasboardComponent: dasboardComponentReducer,
        unitCost: unitCostReducer,
        timer: timerReducer,
        clickStatus: clickStatusReducer
    }
})