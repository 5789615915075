import React, { useEffect } from 'react';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import 'dayjs/locale/en-gb';
export default function Datepicker({ id, label, value, format, locale ,disabled,onChange,sx}) {
    let parsedValue = null;

    useEffect(() => {
        // Set dayjs locale based on the received locale code
        if (locale) {
            dayjs.locale(locale);
        }
    }, [locale]);

    if (value) {
        parsedValue = dayjs(value); // Convert the string to a Dayjs object if value is not null
    }

    return (
        <div>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                <DatePicker
                    id={id}
                    label={label}
                    value={parsedValue}
                    slotProps={{ textField: { variant: 'standard', fullWidth: true, size: 'small' } }}
                    format={format}
                    disabled={disabled}
                    onChange={onChange}
                    sx={sx}
                />
            </LocalizationProvider>
        </div>
    );
}
