import React, { useState, useEffect ,useRef} from 'react';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Stack from '@mui/material/Stack';
import styled, { keyframes } from 'styled-components';
import Avatar from '@mui/material/Avatar';
import LockResetIcon from '@mui/icons-material/LockReset';
import Logout from '@mui/icons-material/Logout';
import Divider from '@mui/material/Divider';
import ListItemIcon from '@mui/material/ListItemIcon';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import ChangePassword from '../modals/change-password';
import { logout } from '../../services/user-service';
import { useAuth } from '../../hooks/useAuth';
import { useTheme } from 'styled-components';
import AvatarIcon from "../../assets/logos/avatar.jpg";
export default function UserProfile({ anchorRef, open, setOpen }) {
  const [showChangePassword, setChangePassword] = useState(false)
  const { onLogout } = useAuth();
  const { user } = useAuth();
  const userID = user.id;
  const theme = useTheme();
  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };
  const onLogoutClick = async () => {
    await logout(user.id);
    onLogout();
  };
  const inputStyles = {
    color: theme.palette.nav.textIcon,
    background: theme.palette.nav.main,
    '&.MuiSvgIcon-root': {
      color: theme.palette.nav.textIcon,
    },
    '&.MuiDivider-root': {
      background: theme.palette.nav.textIcon,
    },
  }
  return (
    <>
      <Stack direction="row" spacing={2}>
        <StyledPopper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="bottom-start"
          transition

        >
          {({ TransitionProps, placement }) => (
            <StyledGrow
              {...TransitionProps}
              placement={placement}
            >
              <StyledPaper sx={inputStyles} >
                <ClickAwayListener onClickAway={handleClose}>
                  <StyledMenuList
                    id="composition-menu"
                    aria-labelledby="composition-button"
                  >
                    <StyledMenuItem onClick={handleClose} >
                    <AvatarImage className="avatar-image" alt="profile user" src={AvatarIcon}/>{userID}
                    </StyledMenuItem>
                    <Divider sx={inputStyles} />
                    <StyledMenuItem onClick={handleClose}>
                      <ListItemIcon>
                        <PlayCircleOutlineIcon fontSize="small" sx={inputStyles} />
                      </ListItemIcon>
                      Start Shift
                    </StyledMenuItem>
                    <StyledMenuItem onClick={handleClose}>
                      <ListItemIcon>
                        <PlayCircleOutlineIcon fontSize="small" sx={inputStyles} />
                      </ListItemIcon>
                      End Shift
                    </StyledMenuItem>
                    <StyledMenuItem onClick={() => setChangePassword(!showChangePassword)}>
                      <ListItemIcon>
                        <LockResetIcon fontSize="small" sx={inputStyles} />
                      </ListItemIcon>
                      ChangePassword
                    </StyledMenuItem>
                    <StyledMenuItem onClick={onLogoutClick}>
                      <ListItemIcon>
                        <Logout fontSize="small" sx={inputStyles} />
                      </ListItemIcon>
                      Logout
                    </StyledMenuItem>
                  </StyledMenuList>
                </ClickAwayListener>
              </StyledPaper>
            </StyledGrow>
          )}
        </StyledPopper>
      </Stack>
      {showChangePassword ? <ChangePassword hide={() => setChangePassword(false)}></ChangePassword> : null}
    </>
  );
}

const fadeIn = keyframes`
from {
  opacity: 0;
}
to {
  opacity: 1;
}
`;

const StyledGrow = styled(Grow)(({ placement }) => ({
  transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom',

}));
const StyledPopper = styled(Popper)`
  /* Add your custom styles for the Popper component here */
  z-index: 10;
  animation: ${fadeIn} 0.3s ease-in-out;
`;
const StyledPaper = styled(Paper)`
  border-radius: 8px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.32);
  overflow: visible;
  margin-top: 1.5px;
  position: relative;
  z-index: 1;
  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 14px;
    width: 10px;
    height: 10px;
    transform: translateY(-50%) rotate(45deg);
    z-index: 0;
    background: ${({ theme }) => theme.palette.nav.main};
  }
`;
const StyledMenuList = styled(MenuList)`
  padding: 8px 0;
`;
const StyledMenuItem = styled(MenuItem)`
  display: flex;
  align-items: center;
  padding: 8px 16px;
  font-size: 14px;
  font-weight: 500;

  &:hover {
    background: ${({ theme }) => theme.palette.nav.main};
    color: ${({ theme }) => theme.palette.nav.onHoverTextIcon};
  }
`;
const AvatarImage = styled.img`
  width: 36px;
  height: 36px;
  margin-left: -0.5px;
  margin-right: 8px;
  cursor: pointer;
  border-radius: 50%;
  background: ${({ theme }) => theme.palette.nav.imageBackground};
  &:hover {
    transform: scale(1.1); // Adjust the scale factor based on your preference
  }
`;