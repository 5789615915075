import React, { useState, useEffect } from 'react'
import CasesGrid from '../../components/data-grid/cases-data-grid/cases-grid'
import { useSelector } from 'react-redux';
import { HomeSearchResult } from '../../utils/constants/application-areas';
import { useAuth } from '../../hooks/useAuth';
import CardView from '../../components/controls/card-view';
import Box from '@mui/material/Box';
import styled from 'styled-components';
import { getFromSS } from '../../utils/helpers/storage';
export default function CasesGridView({headerTitle , componentID}) {
    const { user } = useAuth();
    const userID = user.id;
    const tableKey = userID + HomeSearchResult;
    const [searchDataString, setSearchDataString] = useState([]);
    const {data } = useSelector((state) => state.dasboardComponent);
    useEffect(() => {
        // Retrieve data from sessionStorage
        const searchDataString = getFromSS('searchData');
        const searchData = searchDataString;
        setSearchDataString(searchData);
    }, [data]);
    return (
        <ComponentWrapper>
            <CardView>
                <CasesGrid searchData={searchDataString}  storeKey={`${tableKey}_${componentID}`} headerTitle={headerTitle} />
            </CardView>
        </ComponentWrapper>

    )
}
const ComponentWrapper = styled(Box)`
 padding:5px;
`;