import { httpClient } from "./httpclient";
import { DYNAMIC_ENTITYORG, DYNAMIC_ENTITYPERSON } from "../utils/constants/entity-types";
const url = "v1/dynamicentity";

const dynamicEntity = async (caseType, caseSubType, caseID, profileID) => {
    try {
        const endpoint = `${url}/case/${caseType}/${caseSubType}/${caseID}/${profileID}`;
        return await httpClient.get(endpoint);
    } catch (error) {
        throw new Error(error.response.status);
    }
};
const dynamicField = async (caseType, caseSubType, entityName, entityID, caseID, detailIndex, orgID, entityType, casePersonID) => {

    try {
        let endpoint;
        if (entityType === DYNAMIC_ENTITYORG) {
            endpoint = `${url}/fields/${caseType}/${caseSubType}/${entityName}/${entityID}/${caseID}/${detailIndex}?orgid=${orgID}`;
        }
        else if (entityType === DYNAMIC_ENTITYPERSON) {
            endpoint = `${url}/fields/${caseType}/${caseSubType}/${entityName}/${entityID}/${caseID}/${detailIndex}?personid=${casePersonID}`;
        }
        else {
            endpoint = `${url}/fields/${caseType}/${caseSubType}/${entityName}/${entityID}/${caseID}/${detailIndex}`;
        }
        return await httpClient.get(endpoint);
    } catch (error) {
        throw new Error(error.response.status);
    }
};
const dynamicKeyField = async () => {
    try {
        const endpoint = `${url}/keyfields`;
        return await httpClient.get(endpoint);
    } catch (error) {
        throw new Error(error.response.status);
    }
};
const nextDetailIndex = async (caseID, entityID, entityType) => {
    try {
        const endpoint = `${url}/nextdetailindex/${entityID}/${caseID}/${entityType}`;
        return await httpClient.get(endpoint);
    } catch (error) {
        throw new Error(error.response.status);
    }
};
export { dynamicEntity, dynamicField, nextDetailIndex,dynamicKeyField };
