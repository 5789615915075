import React from 'react'
import TextInput from '../controls/textinput'
import Dropdown from '../controls/drop-down'
export default function MoreInfo() {
    return (
        <div className='container-fluid'>
            <div className='row g-4'>
                <div className='col-lg-6 col-6'>
                    <TextInput label="Case Type" id="caseType" variant="standard"></TextInput>
                </div>
                <div className='col-lg-6 col-6'>
                    <Dropdown label="Sub Type" id="subType" ></Dropdown>
                </div>
                <div className='col-lg-6 col-6'>
                    <Dropdown label="Fee Earner" id="feeEarner" ></Dropdown>
                </div>
                <div className='col-lg-6 col-6'>
                    <Dropdown label="Supervisor" id="supervisor" ></Dropdown>
                </div>
                <div className='col-lg-4 col-6'>
                    <TextInput label="Secondary ID" id="secondaryID" variant="standard"></TextInput>
                </div>
                <div className='col-lg-4 col-6'>
                    <TextInput label="Case Value" id="caseValue" variant="standard"></TextInput>
                </div>
                <div className='col-lg-4 col-6'>
                    <Dropdown label="Case Source" id="caseSource" ></Dropdown>
                </div>
                <div className='col-lg-4 col-6'>
                    <TextInput label="Case Recovered" id="caseRecovered" variant="standard"></TextInput>
                </div>
                <div className='col-lg-4 col-6'>
                    <TextInput label="Damages Recovered" id="damagesRecovered" variant="standard"></TextInput>
                </div>
                <div className='col-lg-4 col-6'>
                    <Dropdown label="Branch" id="branch" ></Dropdown>
                </div>
            </div>
        </div>
    )
}
