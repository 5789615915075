import React, { useEffect, useState } from 'react'
import TextArea from '../../../controls/textarea';
import TextInput from '../../../controls/textinput';
import styled from 'styled-components';
import PersonGrid from '../../../data-grid/person-grid/person-grid';
import { personSearch } from '../../../../services/entity-service';
import FileOpenSharpIcon from '@mui/icons-material/FileOpenSharp';
import AddCircleSharpIcon from '@mui/icons-material/AddCircleSharp';
import DeleteSharpIcon from '@mui/icons-material/DeleteSharp';
import IconButton from '../../../controls/icon-button';
import MaintainPersonView from '../../../../views/case-detail/maintain-person-view';
export default function EntityTypeT({ entityName, selectFromList }) {
  const [apiData, setApiData] = useState([]);
  const [showMaintainPerson, setShowMaintainPerson] = useState(false);
  const CallApi = async () => {
    try {
      const response = await personSearch(entityName, false, "", "", selectFromList);
      setApiData(response.data.results)
    } catch (error) {
     console.error(error);
    }
  };
  useEffect(() => {

    CallApi(); // Call the Test function to fetch and store the data

  }, []);
  const handleFileClick = () => {
    setShowMaintainPerson(!showMaintainPerson);
  }
  return (
    <>
      {showMaintainPerson && <MaintainPersonView hide={() => { setShowMaintainPerson(!showMaintainPerson) }}></MaintainPersonView>}
      <DivWrapper >
        <div className="row g-3">

          <div className='col-lg-9 col-md-9 col-9'>
          <PersonGrid data={apiData} entityName={entityName}></PersonGrid>
          </div>
          <div className='col-lg-1 col-md-1 col-1'>
            <IconButton label="File" id="file" color="white" size="small" onClick={handleFileClick}>
              <FileOpenSharpIcon fontSize="large" />
            </IconButton>
          </div>
          <div className='col-lg-1 col-md-1 col-1'>
            <IconButton label="Delete" id="delete" color="danger" size="small" >
              <DeleteSharpIcon fontSize="large" />
            </IconButton>
          </div>
          <div className='col-lg-1 col-md-1 col-1'>
            <IconButton label="Add" id="add" color="success" size="small" >
              <AddCircleSharpIcon fontSize="large" />
            </IconButton>
          </div>
          <div className='col-lg-12'>
            <TextArea label="Details" id="details" row="3" ></TextArea>
          </div>

          <div className='col-lg-12'>
            <TextInput label="Case Reference" id="case-refference" variant="standard"></TextInput>
          </div>
        </div>
      </DivWrapper>
    </>

  )
}
const DivWrapper = styled.div`
        margin: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px 10px 10px 10px;

    `;