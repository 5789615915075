import React,{useState,useEffect} from 'react'
import CaseHistoryGrid from '../../components/data-grid/case-history-data-grid/case-history-grid'
import RichEditDocument from '../../components/controls/rich-edit'
import { useAuth } from '../../hooks/useAuth'
import { mainCaseHistory } from '../../utils/constants/application-areas'
import { caseHistory } from '../../services/history-service'

export default function CaseHistorView({caseID}) {
  const [caseHistoryData, setCaseHistoryData] = useState("");
  const { user } = useAuth();
  const userID = user.id;
  const key =userID + mainCaseHistory;
  useEffect(() => {
    // Call the userTask API when selectedView or within Days changes
    CallCaseHistoryApi();
  }, []);
  const CallCaseHistoryApi = async () => {
    try {
      const casehistory = await caseHistory(caseID);
      setCaseHistoryData(casehistory.data.results)
    }
    catch (error) {
     console.error(error);
    }
  };
  return (
    <div className='container-fluid'>
      <div className='row'>
        <div className='col-11'>
           <CaseHistoryGrid historyData={caseHistoryData} storeKey={key}></CaseHistoryGrid> 
        </div>
        <div className='col-1'>
           <RichEditDocument></RichEditDocument>
        </div>
      </div>
    </div>
  )
}
