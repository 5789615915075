import React, { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import Fab from "@mui/material/Fab";
import Zoom from "@mui/material/Zoom";
import TextArea from "../../controls/textarea";
import styled from "styled-components";
import { addCaseNotes } from "../../../services/case-service";
import { useParams } from "react-router-dom";
import { useAuth } from "../../../hooks/useAuth";
function CreateArea({ onAdd }) {
  const [isExpanded, setExpanded] = useState(false);
  const { caseID } = useParams();
  const { user } = useAuth();
  var data = formatDateTime(new Date()) + " (" + user.id + ")";
  const [note, setNote] = useState({
    content: "",
  });
  function formatDateTime(date) {
    const dateOptions = { day: '2-digit', month: 'short', year: '2-digit' };
    const timeOptions = { hour: '2-digit', minute: '2-digit', hour12: false };
    const formattedDate = date.toLocaleDateString('en-GB', dateOptions).replace(/\s/g, '');
    const formattedTime = date.toLocaleTimeString('en-GB', timeOptions);
    return `${formattedDate} ${formattedTime}`;
  }
  function handleChange(event) {
    const { name, value } = event.target;

    setNote((prevNote) => {
      return { ...prevNote, [name]: value };
    });
  }
  function submitNote() {
    CallAddCaseNoteApi(note.content);
  }
  const CallAddCaseNoteApi = async (notes) => {
    try {
      const response = await addCaseNotes(caseID, notes);
      if (response.data.returnCode === 1) {
        onAdd(data + " " + note.content);
        setNote("");
      }
    } catch (error) {
      console.error(error); // Log any errors
    }
  };
  function expand() {
    setExpanded(true);
  }

  return (
    <div>
      <Form className="create-note">
        <TextWrapper>
          <TextArea
            name="content"
            label="Make a Note"
            id="Task"
            onChange={handleChange}
            onClick={expand}
            value={note.content}
            row={isExpanded ? 3 : 1}
          ></TextArea>
        </TextWrapper>
        <Zoom in={isExpanded}>
          <ButtonFab onClick={submitNote}>
            <AddIcon />
          </ButtonFab>
        </Zoom>
      </Form>
    </div>
  );
}
const TextWrapper = styled.div`
  background: ${({ theme }) => theme.palette.caseNote.main};
  border-radius: 7px;
  padding: 10px;
  box-shadow: 0 2px 5px #ccc;

`;
const Form = styled.form`
  height: 127px;
  width: 100%;
  border: none;
  padding: 4px;
  outline: none;
  font-size: 1.2em;
  resize: none;
 
`;
const ButtonFab = styled(Fab)`
 position:fixed;
bottom:68px;
left:90%;
cursor:pointer;
.css-ff8l2t-MuiButtonBase-root-MuiFab-root{}
background-color: #f5ba13;
}
@media (max-width: 1000px) {
    left:80%;
        }
      
     `;
export default CreateArea;
