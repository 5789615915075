import { useState, useEffect } from 'react';
import { getParameter } from '../services/setting-service';
import { taskAssigneeList } from '../services/task-service';
import DropDownFilter from '../components/controls/dropdownfilter';
import { TASK_STATUS_LIST, TASK_PRIORITY, STANDARD_TASK, SHARED_CALANDAR } from '../utils/constants/parmeterCode';
export function useTaskParameters(caseType) {
  const [taskStatusOption, setTaskStatusOption] = useState([]);
  const [priorityOption, setpriorityOption] = useState([]);
  const [taskAssigneeOption, setTaskAssigneeOption] = useState([]);
  const [standardTaskOption, setStandardTaskOption] = useState([]);
  const [sharedCalendarOption, setSharedCalendarOption] = useState([]);
  const filterData = (standardTask) => {
    return DropDownFilter(standardTask, caseType);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const taskStatusList = await getParameter(TASK_STATUS_LIST);
        setTaskStatusOption(taskStatusList.data.results);

        const priorityList = await getParameter(TASK_PRIORITY);
        setpriorityOption(priorityList.data.results);

        const sharedCalendar = await getParameter(SHARED_CALANDAR);
        setSharedCalendarOption(sharedCalendar.data.results);

        const standardTask = await getParameter(STANDARD_TASK);
        const filteredData = filterData(standardTask.data.results);
        setStandardTaskOption(filteredData);
      } catch (error) {
        // Handle errors
      }
    };

    fetchData();
  }, [caseType]);

  useEffect(() => {
    const fetchAssigneeList = async () => {
      try {
        const assigneeList = await taskAssigneeList(caseType);
        setTaskAssigneeOption(assigneeList.data.results);
      } catch (error) {
        // Handle errors
      }
    };

    fetchAssigneeList();
  }, [caseType]);

  return { taskStatusOption, priorityOption, taskAssigneeOption, standardTaskOption, sharedCalendarOption };
}
