import React from 'react'
import Modal from './custom-modal'
import TextInput from '../controls/textinput'
import Button from '../controls/button'
export default function LinkedCase({close}) {
  return (
    <div>
      <Modal className="modal-dialog modal-dialog-centered" hide={close} title="Linked Case"  footer={
                <>
                    <Button size='medium' variant='outlined' name='Close' onClick={close}></Button>
                    <Button type='submit' size='medium' variant='contained' name='Ok'></Button>
                </>
            }>
      <TextInput label="Linked Case ID" id="linkedCaseID" variant="standard"></TextInput>
      </Modal>
    </div>
  )
}
