import React from 'react';
import TaskForm from '../../components/modals/task-form-modal';
import { useSelector } from 'react-redux';
import { useAuth } from '../../hooks/useAuth';
import { useTaskParameters } from '../../hooks/useTaskParameter';
import { useCaseParameters } from '../../hooks/useCaseParameter';
import { useHistoryParameter } from '../../hooks/useHistoryParameter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faPenToSquare, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { useParams } from 'react-router-dom';
import { ADD_TASK, EDIT_TASK, ADD_CASE, EDIT_HISTORY, DELETE_HISTORY, ADD_HISTORY, SAVE_HISTORY } from '../../utils/constants/modal-name';
import { MANUAL, AUTOMATIC } from '../../utils/constants/type-of-entry';
import { UPDATE, ADD, DELETE } from '../../utils/constants/mode';
import AddCases from '../../components/modals/add-case-modal';
import HistoryForm from '../../components/modals/history-form-modal';
export default function HandleModal({caseType}) {
    const { modalName, mode, data } = useSelector((state) => state.modal);
    const { totalSeconds, noOfUnit, cost } = useSelector((state) => state.unitCost);
    const { startTime } = useSelector((state) => state.timer);
    let modalContent;
    const { user } = useAuth();
    const { caseID } = useParams();
    const userID = user.id;
    const defaultCaseType = user.defaultCaseType;;
    const {
        taskStatusOption,
        priorityOption,
        taskAssigneeOption,
        standardTaskOption,
        sharedCalendarOption
    } = useTaskParameters(caseType);
    const {
        caseTypes,
        caseSubTypes,
    } = useCaseParameters();
    const {
        billingCategory1Option,
        billingCategory2Option,
        billingTypeOfWorkOption,
        userListOption,
    } = useHistoryParameter();
    const getModalIcon = () => {
        if (mode === ADD) {
            return <FontAwesomeIcon icon={faPlusCircle} />;
        }
        else if (mode === UPDATE) {
            return <FontAwesomeIcon icon={faPenToSquare} />;
        }
        else if (mode === DELETE) {
            return <FontAwesomeIcon icon={faTrashAlt} />;
        }
    };
    const getTaskStatusOption = () => {
        if (mode === ADD) {
            return taskStatusOption.filter(status => status.parameterID !== 'C');
        }
        else  {
            return taskStatusOption;
        }
    };
    const getTypeOfEntry = () => {
        let typeofEntry;
        if (modalName === ADD_HISTORY) {
            typeofEntry = MANUAL;
        } else if (modalName === SAVE_HISTORY) {
            typeofEntry = AUTOMATIC;
        }
        return typeofEntry; // Return the value outside the if-else block
    };
    switch (modalName) {
        case ADD_TASK:
        case EDIT_TASK:
            modalContent = (<TaskForm icon={getModalIcon()} editTaskData={data} taskStatusOption={getTaskStatusOption()} priorityOption={priorityOption} taskAssigneeOption={taskAssigneeOption} standardTaskOption={standardTaskOption} sharedCalendarOption={sharedCalendarOption} name={modalName} mode={mode} paramUserID={userID} />);
            break;
        case ADD_CASE:
            modalContent = (<AddCases paramCaseType={caseTypes} paramCaseSubType={caseSubTypes} defaultCaseType={defaultCaseType}></AddCases>);
            break;
        case ADD_HISTORY:
        case EDIT_HISTORY:
        case DELETE_HISTORY:
        case SAVE_HISTORY:
            modalContent = (<HistoryForm icon={getModalIcon()} editHistoryData={data} title={modalName} mode={mode} billingCategory1Option={billingCategory1Option} billingCategory2Option={billingCategory2Option} billingTypeOfWorkOption={billingTypeOfWorkOption} userListOption={userListOption} paramStartTime={startTime} paramUserID={userID} paramCaseID={caseID} paramNoOfUnit={noOfUnit} paramCost={cost} paramTotalSeconds={totalSeconds} paramTypeOfEntry={getTypeOfEntry()} ></HistoryForm>);
            break;
        default:
            modalContent = null;
    }

    return <div>{modalContent}</div>;
}
