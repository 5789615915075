import React, { useContext } from 'react'
import Toast from '../../controls/toast'
import { useSelector } from 'react-redux';
export default function ToastAlert() {
  const { openAlert, severity, alertMessage } = useSelector((state) => state.alert);
  return (
    <div>
      <Toast openAlert={openAlert} severity={severity} message={alertMessage}></Toast>
    </div>
  )
}
